import styled, { createGlobalStyle } from 'styled-components';
import { VFlex } from './components/Flex';

const GlobalCSS = createGlobalStyle`
.simple-btn {
  opacity: 1;
  outline: 0;
  transition: background-color 0.2s, opacity 0.2s;
  
  &:not(.disabled) {
    cursor: pointer;
  }
  &:hover:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 0.65;
  }

  &:active:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: 0.85;
  }
  &a:hover {
    color: unset;
  }
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
a:hover {
  color: #dd99ff !important;
}

.dropdown-btn {
  background-color: #050505;
  outline: 0;
  transition: background-color 0.2s, opacity 0.2s;
  
  &:not(.disabled) {
    cursor: pointer;
  }
  &:hover:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    background-color: #1A1E1F;
  }

  &:active:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    background-color: #1A1E1F;
  }
  &a:hover {
    color: unset;
  }
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.full-width {
  width: 90vw;
  max-width: 1200px;
}

`;

export const SSideContainer = styled(VFlex)`
padding: 24px 16px;
gap: 10px;
border-radius: 8px 0px 0px 8px;
background: var(--dark-black, #050505);
`;


export default GlobalCSS;
