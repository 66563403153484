import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { Body, BodyHeader, SubTitle } from '../Typography';
import i18nStore from '../../localizations';
import { BorderButton } from '../MainButton';
import InAppStickerPlatformsComponent from './InAppStickerPlatformsComponent';
import { store } from '../../mobx/store';

interface InAppStickersComponentProps {
}

const InAppStickersComponent = observer((props:InAppStickersComponentProps) => {
  const l = i18nStore.dict.mediaKitPage;
  const textComponent = (
    <VFlex>
      <SubTitle>{l.inAppStickersTitle}</SubTitle>
      <Body style={{marginTop: 12}}>{l.inAppStickersDescription}</Body>
    </VFlex>
  );
  const imgComponent = (
    <img
      style={{maxWidth: 440, width: '90%'}}
      src="/images/v3/in-app-stickers.png"
      alt=""
    />
  );
  const btnComponent = (
    <a
      href="https://linktr.ee/9catsticker"
      target="_blank"
      rel="noreferrer"
    >
      <BorderButton style={{margin: '36px 0px'}}>
        {l.getSticker}
      </BorderButton>
    </a>
  );
  const weSupportComponent = (
    <ResponsiveFlex style={{gap: 12}}>
      <BodyHeader>{l.weSupports}</BodyHeader>
      <InAppStickerPlatformsComponent style={{width: null, justifyContent: 'flex-start', gap: 12}} />
    </ResponsiveFlex>
  );
  return (
    <VFlex style={{width: '100vw', backgroundColor: '#050505', marginTop: 80}}>
      <div id="inAppStickers" style={{transform: 'translateY(-100px)'}} />
      {(() => {
        if (!store.isDesktop) {
          return (
            <VFlex style={{alignSelf: 'stretch', margin: '80px 20px', gap: 20, alignItems: 'center'}}>
              {textComponent}
              {imgComponent}
              {btnComponent}
              {weSupportComponent}
            </VFlex>
          );
        }
        return (
          <Flex style={{alignSelf: 'center', gap: 64, marginTop: 80}} className="full-width">
            {imgComponent}
            <VFlex>
              {textComponent}
              {btnComponent}
              {weSupportComponent}
            </VFlex>
          </Flex>
        );
      })()}
    </VFlex>
  );
});

export default InAppStickersComponent;
