import React from 'react';
import styled from 'styled-components';
import { MediaQueryMobileOnly, getLtMediaQuery } from '../utils/constants';

type Props = {

}

export const ResponsiveFlex = styled.div`
display: flex;
align-items: stretch;

${props => (props.c ? getLtMediaQuery(props.c) : MediaQueryMobileOnly)} {
  align-items: center;
  flex-direction: column;
}
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const VFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-contents: center;
  align-items: center;
`;
