import { constant } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import Wobble from 'react-reveal/Wobble';
import {now} from 'mobx-utils';
import RSSParser from 'rss-parser';
import moment from 'moment';

import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { useIsDesktop } from '../../utils/utils';
import { store } from '../../mobx/store';
import { COLORS, GRID_4_1_CLASS_NAME, GRID_4_CLASS_NAME, MediaQueryMobileOnly, NETWORK_CONFIG } from '../../utils/constants';
import { MainTitle } from '../Typography';
import i18nStore from '../../localizations';
import { ContainerWithPadding, H } from '../Container';
import ExternalLinkIcon from '../ExternalLinkIcon';
import { MainButton } from '../MainButton';

const Feed = styled(VFlex)`
position: relative;
`;

const FeedTitle = styled.h1`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 33px;
letter-spacing: 0.05em;

/* lightgrey */

color: #F5F5F5;
`;

const DateText = styled.div`
/* Body */

font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
/* or 200% */

letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

const ReadMoreText = styled.div`
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 128.571% */
letter-spacing: 2px;
`;

const FollowMediumBtn = styled(Flex)`
width: 301px;
height: 55px;
background-color: #D585FF;
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
justify-content: center;
/* dark-black */

color: #050505;
margin-top: 32px;
`;

const STitle = styled.div`
color: var(--lightgrey, #F5F5F5);
/* sharp-blue */
text-shadow: 4px 4px 0px #65BDE6;
font-family: Racing Sans One;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 6px;
${MediaQueryMobileOnly} {
  font-size: 30px;
}
`;

type Props = {
}

const parser = new RSSParser();

export const LatestNews = observer((props:Props) => {
  const isDesktop = useIsDesktop();
  const [feeds, setFeeds] = useState([]);
  const l = i18nStore.dict.latestNews;
  const lv4 = i18nStore.dict.mainPageV4;
  useEffect(() => {
    (async () => {
      try {
        const feed = await fetch(`${NETWORK_CONFIG.baseURL}/medium_rss`);
        const t = await feed.text();
        const parsed = await parser.parseString(t);
        const items = parsed.items.slice(0, 4).map((item) => {
          const splitted = item['content:encoded'].split('"');
          let img = '';
          for (let i = 0; i < splitted.length; i++) {
            const s = splitted[i];
            if (s.startsWith('https://') && (s.endsWith('.jpg') || s.endsWith('.png') || s.endsWith('.jpeg'))) {
              img = s;
              break;
            }
          }
          return {
            title: item.title,
            img,
            link: item.link,
            ts: new Date(item.isoDate),
          };
        });
        setFeeds(items);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <VFlex
      style={{
        alignItems: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      <img
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: 0.2,
        }}
        src="/images/v4/development_updates_bg.png"
        alt=""
      />
      <ContainerWithPadding>
        <ResponsiveFlex
          style={{
            marginTop: 75,
            justifyContent: 'space-between',
            gap: 24,
          }}
        >
          <STitle>
            {l.title}
          </STitle>
          {
            store.isDesktop && (
              <>
                <MainButton
                  style={{
                    backgroundColor: COLORS.darkBlack,
                    color: COLORS.primary,
                    gap: 12,
                    width: 160,
                  }}
                >
                  <ExternalLinkIcon />
                  {lv4.viewAll}
                </MainButton>
              </>
            )
          }
        </ResponsiveFlex>
        <div
          style={{marginTop: 30, width: '100%', columnGap: '16px'}}
          className={GRID_4_1_CLASS_NAME}
        >
          {
            feeds.map((feed) => {
              return (
                <a
                  href={feed.link}
                  target="_blank"
                  className="simple-btn"
                  rel="noreferrer"
                  style={{width: '100%' }}
                >
                  <Feed
                    key={feed.title}
                    style={{
                      backgroundColor: COLORS.backgroundShadow,
                      minHeight: 345,
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: 'auto',
                        aspectRatio: '16/9',
                        objectFit: feed.img ? 'cover' : 'contain',
                        padding: !feed.img && 20,
                      }}
                      src={feed.img || '/images/portal/9CATSaga_1300x900.png'}
                      alt=""
                    />
                    <VFlex
                      style={{zIndex: 1, flex: 1, margin: 12}}
                    >
                      <FeedTitle style={{maxHeight: 66, overflow: 'hidden'}}>
                        {feed.title}
                      </FeedTitle>
                      <div style={{flex: 1}} />
                      <a
                        href={feed.link}
                        target="_blank"
                        className="simple-btn"
                        rel="noreferrer"
                      >
                        <Flex style={{alignItems: 'center', gap: 4}}>
                          <ReadMoreText
                            style={{transform: 'translateY(1px)'}}
                          >
                            {l.readMoreBtn}
                          </ReadMoreText>
                          <ExternalLinkIcon />
                        </Flex>
                      </a>
                    </VFlex>
                  </Feed>
                </a>
              );
            })
          }
        </div>
        {
          !store.isDesktop && (
            <>
              <MainButton
                style={{
                  backgroundColor: COLORS.darkBlack,
                  color: COLORS.primary,
                  gap: 12,
                  alignSelf: 'stretch',
                  width: 'unset',
                }}
              >
                <ExternalLinkIcon />
                {lv4.viewAll}
              </MainButton>
            </>
          )
        }
      </ContainerWithPadding>
      <H h={100} />
    </VFlex>
  );
});
