import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { store } from '../mobx/store';
import { Paragraph, SubTitle } from './Typography';
import { Section } from './Section';
import { VFlex } from './Flex';

interface SectionDataComponentProps {
  data: any,
  l: any,
}

const SectionDataComponent = observer((props:SectionDataComponentProps) => {
  const {data, l } = props;
  const imageMargin = (() => {
    if (!store.isDesktop) {
      return 0;
    }
    return store.isShortenMode ? 15 : 70;
  })();
  const textComponent = (
    <VFlex
      style={{
        width: '100%',
        alignSelf: 'center',
        marginLeft: imageMargin,
        marginRight: 0,
        marginBottom: 30,
      }}
    >
      <SubTitle style={data.titleStyle}>
        {l[data.title]}
      </SubTitle>
      <Paragraph>
        {l[data.content]}
      </Paragraph>
      {data.extraTextComponent}
    </VFlex>
  );
  const imageComponent = (
    <div
      style={{
        maxWidth: store.isDesktop ? 476 : null,
        width: store.isDesktop ? '100%' : null,
        margin: store.isDesktop ? null : '0px 0px',
        alignSelf: store.isDesktop ? null : 'stretch',
      }}
    >
      {data.imageComponent}
    </div>
  );
  return (
    <Section
      style={{
        width: null,
        alignSelf: 'stretch',
        margin: store.isDesktop ? '0px 100px 40px 100px'
          : '0px 24px 40px 24px',
      }}
      isAnimate={false}
      isVRightFirst={data.swap}
      widthRatio={1}
      leftComponent={data.swap ? textComponent : imageComponent}
      rightComponent={data.swap ? imageComponent : textComponent}
    />
  );
});

export default SectionDataComponent;
