import React, {Component, useEffect, useState} from 'react';
import {observer} from 'mobx-react';

import Dialog from './Dialog';
import { store } from '../../mobx/store';
import { Flex, VFlex } from '../Flex';
import { post } from '../../api/api';
import i18nStore from '../../localizations';

interface TermsAndConditionDialogProps {
}

const TermsAndConditionDialog = observer((props:TermsAndConditionDialogProps) => {
  return (
    <Dialog
      dialogID="terms-and-condition-dialog"
      title=""
      storeGetter={() => store.isShowTermsAndConditionDialog}
      onShow={() => {
      }}
      onClose={() => {
        store.isShowTermsAndConditionDialog = null;
      }}
      containerStyle={{
        maxWidth: '700px',
        width: '80vw',
      }}
    >
      <VFlex
        style={{
          gap: 10,
          maxHeight: '70vh',
          overflowY: 'scroll',
          padding: '12px 20px',
          border: '1px solid #464848',
          fontSize: 14,
          lineHeight: '17px',
        }}
        onScroll={(evt) => {
          evt.stopPropagation();
        }}
      >
        {
          (() => {
            const c:String = i18nStore.dict.yumcha.termsAndConditionContent;
            const splitted = c.split('\n');
            return splitted.map(v => (<div>{v}</div>));
          })()
        }
      </VFlex>
    </Dialog>
  );
});

export default TermsAndConditionDialog;
