import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Marquee from 'react-fast-marquee';
import { Flex } from './Flex';

interface NCatMarqueeProps {
}

const NCatMarquee = observer((props:NCatMarqueeProps) => {
  return (
    <Flex
      style={{
        minWidth: '100vw',
        maxWidth: '100vw',
        height: '255px',
        backgroundColor: '#7DE3CF',
        alignItems: 'center',
      }}
    >
      <Marquee
        gradient={false}
        style={{
        }}
        speed={200}
      >
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
            return (
              <img
                style={{
                  width: 255,
                  height: 255,
                }}
                src={`/images/v3/cats/${i}.png`}
                alt=""
              />
            );
          })
        }
      </Marquee>
    </Flex>
  );
});

export default NCatMarquee;
