import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';

import { Flex, VFlex } from './Flex';
import { COLORS } from '../utils/constants';
import { MainTitle } from './Typography';
import { Section } from './Section';
import { store } from '../mobx/store';


const ArrowDown = styled.div`
border-left: 15px solid transparent;
border-right: 15px solid transparent;
border-top: 15px solid ${COLORS.blue2};
width: 0;
height: 0;
`;

export const BubbleTitle = observer(({text, icon, textStyle, imageStyle, containerStyle}) => {
  return (
    <VFlex
      style={{
        alignItems: 'center',
      }}
    >
      <VFlex
        style={{
          backgroundColor: COLORS.blue2,
          color: COLORS.grey3,
          width: store.isDesktop ? '477px' : 334,
          height: store.isDesktop ? '47px' : 40,
          fontWeight: 600,
          fontSize: store.isDesktop ? 24 : 16,
          justifyContent: 'center',
          borderRadius: 100,
          position: 'relative',
          textShadow: '-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF',
          paddingLeft: store.isDesktop ? 0 : 32,
          textAlign: 'center',
          ...containerStyle,
        }}
      >
        <div
          style={textStyle}
        >
          {text}
        </div>
        <img
          style={{
            position: 'absolute',
            left: 22,
            bottom: 11,
            width: 53,
            height: 'auto',
            ...imageStyle,
          }}
          src={icon}
          alt=""
        />
      </VFlex>
      <ArrowDown />
    </VFlex>
  );
});
