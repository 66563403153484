import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import BasePage from './BasePage';
import { InvestorAndPartner } from '../v3/InvestorAndPartner';

interface PartnerPageProps {
style: CSSProperties;
}

const PartnerPage = observer((props:PartnerPageProps) => {
  return (
    <BasePage
      h={36}
    >
      <InvestorAndPartner />
    </BasePage>
  );
});

export default PartnerPage;
