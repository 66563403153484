import moment from 'moment';

import styled, { keyframes } from 'styled-components';

export const fadeinout = keyframes`
0% { opacity: 0; }
50% { opacity: 1; }
100% { opacity: 0; }
`;

export const fadeinoutweak = keyframes`
0% { opacity: 0.3; }
50% { opacity: 1; }
100% { opacity: 0.3; }
`;

export const S_MS = 1000;
export const MINUTE_MS = 60 * 1000;
export const HOUR_MS = 60 * MINUTE_MS;
export const DAY_MS = 24 * HOUR_MS;

export const NetworkContextName = 'NETWORK';

export const NETWORK_ID = parseInt(process.env.REACT_APP_NETWORK, 10) || '4';

export const CONNECTED_BEFORE_KEY = 'amsdfbawmfeawefaeagewg';

// const BASE_URL = ;
// const BASE_URL = 'http://localhost:31381';

export const NETWORK_CONFIGS = {
  1: {
    baseURL: 'https://apiv2.9cat.io',
    rpc: 'https://rpc.ankr.com/eth',
    id: '0x1',
    chainName: 'ETH Mainnet',
    ncAddress: '0xf034a5c40975086c54f0af5d2fb1a86176831363',
    nMilkAddress: '0xd80722df8ebb56a808fc8bee28b9219f7e1bc5fd',
    opensea: 'https://opensea.io/collection/9catnft',
    openseaRoot: 'https://opensea.io',
    ethscan: 'https://etherscan.io/address/0xf034a5c40975086c54f0af5d2fb1a86176831363',
    ethscan9Milk: 'https://etherscan.io/address/0xd80722df8ebb56a808fc8bee28b9219f7e1bc5fd',
    ethscanRoot: 'https://etherscan.io',
    discord: 'https://discord.gg/9cat',
    twitter: 'https://twitter.com/9CatSaga',
    topDiscord: 'https://discord.gg/9cat',
    bottomDiscord: 'https://discord.gg/9cat',
    topTwitter: 'https://twitter.com/9CatSaga',
    bottomTwitter: 'https://twitter.com/9CatSaga',
    telegram: 'https://t.me/NineCatNFTNews',
    medium: 'https://medium.com/@9metaverse',
    instagram: 'https://www.instagram.com/9cat.io',
    lightpaper: 'https://metaverse-9.gitbook.io/9metaverse-lightpaper/',
    enlistDiscord: 'https://discord.gg/9cat',
    PRESALE_TS: 1664546400000,
    PUBLIC_SALE_TS: 1664546400000 + DAY_MS,
    nBeanAddress: '0x78EdAC087ce27ca32870c698492f93eC678Ee0C7',
  },
  3: {
    rpc: 'https://main-light.eth.linkpool.io/',
    id: '0x3',
  },
  4: {
    // baseURL: 'http://localhost:31380',
    baseURL: 'https://apiv2.9cat.io',
    rpc: 'https://rpc.ankr.com/eth_rinkeby',
    id: '0x4',
    chainName: 'Rinkeby Test Network',
    ncAddress: '0x7FBc7E6BFB9747d1014089b13aEAC2600DE6C264',
    nMilkAddress: '0x79514ABE64f8D3d869e6efD472209bFEa0D3865d',
    openseaRoot: 'https://testnets.opensea.io',
    opensea: 'https://testnets.opensea.io/collection/9cats',
    ethscanRoot: 'https://rinkeby.etherscan.io',
    ethscan: 'https://rinkeby.etherscan.io/address/0x7FBc7E6BFB9747d1014089b13aEAC2600DE6C264',
    ethscan9Milk: 'https://rinkeby.etherscan.io/address/0x79514ABE64f8D3d869e6efD472209bFEa0D3865d',
    discord: 'https://discord.gg/9cat',
    twitter: 'https://twitter.com/9CatSaga',
    topDiscord: 'https://discord.gg/9cat',
    bottomDiscord: 'https://discord.gg/9cat',
    topTwitter: 'https://twitter.com/9CatSaga',
    bottomTwitter: 'https://twitter.com/9CatSaga',
    telegram: 'https://t.me/NineCatNFTNews',
    medium: 'https://medium.com/@9metaverse',
    instagram: 'https://www.instagram.com/9cat.io',
    youtube: 'https://www.youtube.com/@9catsaga',
    threads: 'https://www.threads.net/@9cat.io',
    lightpaper: 'https://metaverse-9.gitbook.io/9metaverse-lightpaper/',
    publicSaleCutOffTS: 1,
    enlistDiscord: 'https://discord.gg/9cat',
    PRESALE_TS: 0,
    PUBLIC_SALE_TS: 0 + DAY_MS,
    // PRESALE_TS: Date.now(),
    // PUBLIC_SALE_TS: Date.now() + DAY_MS,
    nBeanAddress: '0x6cd7a6f10E97c8873Cc94c574Fc4D0473eD7D888',
  },
  5: {
    rpc: 'https://main-light.eth.linkpool.io/',
    id: '0x5',
  },
};

type ConfigType = typeof NETWORK_CONFIGS[4];

export const WIDTH_SHORTEN_THRESHOLD = 1064;
export const WIDTH_THRESHOLD = 741;
export const WIDTH_HEADER_THRESHOLD = 741;
export const MAX_DESKTOP_WIDTH = 1440;

export const NETWORK_CONFIG: ConfigType = NETWORK_CONFIGS[NETWORK_ID];

export const COLORS = {
  primary: '#D585FF',
  grey1: '#3A4142',
  grey2: '#2E3435',
  grey3: '#1A1E1F',
  backgroundShadow: '#1A1E1F',
  grey4: '#464848',
  purple1: '#D585FF',
  purple2: '#BF77E6',
  purple3: '#9F63BF',
  secondary: '#6FD1FF',
  blue1: '#6FD1FF',
  blue2: '#65BDE6',
  blue3: '#549DBF',
  green1: '#8BFCE5',
  green2: '#7DE3CF',
  green3: '#68BDAC',
  pink: '#FCC5B1',
  orange: '#FCD4A4',
  alert: '#DE3146',
  darkBlack: '#050505',
  lightGrey: '#F5F5F5',
};

export const getLtMediaQuery = size => `@media only screen and (max-width: ${size}px)`;

export const MediaQueryMobileOnly = getLtMediaQuery(WIDTH_THRESHOLD);
export const MediaQueryShortenOnly = getLtMediaQuery(WIDTH_SHORTEN_THRESHOLD + 1);

const presaleMoment = moment(NETWORK_CONFIG.PRESALE_START_TS);
const publicSaleMoment = moment(NETWORK_CONFIG.PUBLIC_SALE_TS);

export const PHASE = {
  BEFORE_ANYTHING: 'BEFORE_ANYTHING',
  PRESALE: 'PRESALE',
  INTER_PHASE: 'INTER_PHASE',
  PUBLIC: 'PUBLIC',
};

export const ROTATE_KEYFRAMES = keyframes`
0% { transform: rotate(0deg); }
50% { transform: rotate(180deg);  }
100% { transform: rotate(360deg);  }
`;

export const UtilitiesSectionsData = [
  {
    title: 'p1Title',
    content: 'p1Content',
    imageComponent: (
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        alt=""
        src="/images/v2/9milk.png"
      />
    ),
    isImageLeft: true,
  },
  {
    title: 'p2Title',
    content: 'p2Content',
    imageComponent: (
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        alt=""
        src="/images/v2/utilities-02.png"
      />
    ),
    isImageLeft: false,
  },
  {
    title: 'p3Title',
    content: 'p3Content',
    imageComponent: (
      <img
        style={{
          width: '100%',
          height: 'auto',
        }}
        alt=""
        src="/images/v2/merch.png"
      />
    ),
    isImageLeft: true,
  },
];

export const INVITATION_CODE_REGEX = /^[0-9A-Z]{4,10}$/;

export const GRID_CLASS_NAME = 'relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 xxl:grid-cols-7 xxxl:grid-cols-8 gap-y-6 gap-x-5 sm:gap-x-6 pb-4 lg:pb-8';

export const GRID_6_CLASS_NAME = 'relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-y-6 gap-x-5 sm:gap-x-6 pb-4 lg:pb-8';

export const GRID_7_CLASS_NAME = 'relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-7 gap-y-6 gap-x-5 sm:gap-x-6 pb-4 lg:pb-8';

export const GRID_3_CLASS_NAME = 'relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-y-1 gap-x-1 pb-4 lg:pb-8';

export const GRID_4_CLASS_NAME = 'relative grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-y-6 gap-x-5 sm:gap-x-6 pb-4 lg:pb-8';

export const GRID_4_1_CLASS_NAME = 'relative grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-y-6 gap-x-5 sm:gap-x-6 pb-4 lg:pb-8';

export const GRID_4_3_CLASS_NAME = 'relative grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-y-1 gap-x-1';

export const GRID_3_1_CLASS_NAME = 'relative grid md:grid-cols-3 lg:grid-cols-3 gap-y-1 gap-x-1 pb-4 lg:pb-8';

export const OS_LINK = {
  desktop: 'https://store.steampowered.com/app/2687040/9Cat_Saga/',
  android: 'https://play.google.com/store/apps/details?id=com.ninemetaverse.ninecatsaga',
  ios: 'https://apps.apple.com/hk/app/9cat-saga-co-op-adventure/id6463721872?l=en-GB',
};
