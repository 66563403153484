import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { range } from 'lodash';
import { Center, Flex, ResponsiveFlex, VFlex } from '../Flex';
import { BubbleTitle } from '../BubbleTitle';
import { MainTitle, S14DesktopBody, S18DesktopSubheader2, S24Racing, SDesktopBody, ShadowText } from '../Typography';
import { COLORS, MAX_DESKTOP_WIDTH, MediaQueryMobileOnly, WIDTH_THRESHOLD } from '../../utils/constants';
import { Container } from '../Container';
import { CatImage } from '../CatImage';
import { store } from '../../mobx/store';
import i18nStore from '../../localizations';


type TeamMemberProps = {
  name: String,
  src: String,
  href: String,
  intro: String,
  post: String,
  isSmall: Boolean,
  linkedin: String,
  groupNum: Number,
  idx: Number,
}

const teamDataGrp1: [TeamMemberProps] = [
  {
    name: 'Chief Business Officer',
    src: '/images/team/SleepyPM.jpg',
  },
  {
    title: 'CEO & Game Director',
    name: 'Wendy Lam',
    src: '/images/v4/team/wendy.png',
    exp: '7 years of software development experience',
  },
  {
    name: '9x9=99',
    src: '/images/team/9x9.png',
    // href: 'https://twitter.com/9catnft',
  },
];

const teamDataGrp2: [TeamMemberProps] = [
  {
    name: '9A',
    src: '/images/team/9A.jpeg',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9RushToSleep',
    src: '/images/team/9RushToSleep.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9.9',
    src: '/images/team/9dot9.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9Tuna',
    src: '/images/team/9Tuna.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9NO',
    src: '/images/team/9NO.png',
    // href: 'https://twitter.com/9catnft',
    post: 'Lead Game Developer, Game Designer',
  },
  // {
  //   name: '9Pack',
  //   src: '/images/team/9J.png',
  //   // href: 'https://twitter.com/9catnft',
  //   intro: 'Unity Game Developer of 9Metaverse. He works closely with the dev team to deliver various short cycles of mini-games for 9Cat as well as conducting game development work for 9Metaverse. He has great Mathematics background and enjoys making shaders to create different game effects. He is a fan of Japan(girls).',
  //   post: 'Unity Game Developer',
  // },
  {
    name: '9Up',
    src: '/images/team/9up.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9Chill',
    src: '/images/team/9chill.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: '9PCT',
    src: '/images/team/9PCT.png',
    // href: 'https://twitter.com/9catnft',
  },
  {
    name: 'Lime Kat',
    src: '/images/team/LimeKat.png',
    // href: 'https://twitter.com/9catnft',
  },
];

const LAYOUT2_HEIGHT = 120;

const TeamMemberA = styled.div`
position: relative;
outline: none;
display: flex;
flex-direction: column;
align-items: center;
margin: 32px 7px;
width: 248px;
min-width: 248px;

@media only screen and (max-width: ${WIDTH_THRESHOLD}px) {
  min-width: 0px;
  max-width: 300px;
  width: 40%;
}
@media only screen and (max-width: 460px) {
  width: 80%;
}
`;

const StyledTeamMemberName = styled.div`
/* Sub-Header */

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: 0.05em;

margin-top: 14px;
margin-bottom: 8px;

/* lightgrey */

color: #F5F5F5;
`;

const StyledTeamMemberDescription = styled.div`
/* Body */

font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
/* or 200% */

text-align: center;
letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

const TeamLayout2Container = styled.div`
max-height: ${LAYOUT2_HEIGHT}px;
display: flex;
flex-direction: column;
align-items: center;
background: #2E3435;
margin-bottom: 12px;
transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
-webkit-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
`;


const LAYOUT2_WIDTH_LIMIT = 660;


const CONFIGS = {
  r0: 3,
  r1: 9,
  r2: 5,
  r3: 2,
  r0_0: {
    src: '/images/v4/team/9a.png',
  },
  r0_1: {
    src: '/images/v4/team/9lives.png',
  },
  r0_2: {
    src: '/images/v4/team/9dragon.png',
  },
  r1_0: {
    src: '/images/v4/team/9cm.png',
  },
  r1_1: {
    src: '/images/v4/team/9no.png',
  },
  r1_2: {
    src: '/images/v4/team/9up.png',
  },
  r1_3: {
    src: '/images/v4/team/9chill.png',
  },
  r1_4: {
    src: '/images/v4/team/9hitcombo.png',
  },
  r1_5: {
    src: '/images/v4/team/9devanson.png',
  },
  r1_6: {
    src: '/images/v4/team/limekat.png',
  },
  r1_7: {
    src: '/images/v4/team/9machi.png',
  },
  r1_8: {
    src: '/images/v4/team/9ping.png',
  },
  r2_0: {
    src: '/images/v4/team/9x9.png',
  },
  r2_1: {
    src: '/images/v4/team/9tuna.png',
  },
  r2_2: {
    src: '/images/v4/team/missing.png',
  },
  r2_3: {
    src: '/images/v4/team/9rushtosleep.png',
  },
  r2_4: {
    src: '/images/v4/team/9urr.png',
  },
  r3_0: {
    src: '/images/v4/team/9monkee.png',
  },
  r3_1: {
    src: '/images/v4/team/9tear.png',
  },
};

const TeammateItem = observer(({ r, idx }) => {
  const config = CONFIGS[`r${r}_${idx}`];
  const l = i18nStore.dict.team;
  const k = `r${r}_${idx}`;
  return (
    <VFlex
      style={{ alignItems: 'center', gap: 13 }}
    >
      {
        config?.src && (
          <img
            style={{ width: 120, height: 120, objectFit: 'contain' }}
            src={config?.src}
            alt=""
          />
        )
      }
      <SDesktopBody
        style={{
          color: COLORS.primary,
          textAlign: 'center',
        }}
      >
        {l[`${k}Title`]}
      </SDesktopBody>
      <S18DesktopSubheader2 style={{}}>
        {l[`${k}Name`]}
      </S18DesktopSubheader2>
      <S14DesktopBody style={{ maxWidth: 200 }}>
        {l[`${k}Exp`]}
      </S14DesktopBody>
    </VFlex>
  );
});

export const TeamV3 = observer(() => {
  const isLayout1 = store.screenSize.width > LAYOUT2_WIDTH_LIMIT;
  const l = i18nStore.dict.team;
  return (
    <VFlex
      style={{
        position: 'relative',
        marginBottom: 100,
      }}
    >
      <VFlex
        style={{ position: 'relative', alignItems: 'center', width: '100%' }}
      >
        <MainTitle
          style={{ textAlign: 'center', marginTop: 0, fontFamily: 'Racing Sans One' }}
        >
          {i18nStore.dict.teamV3.sectionTitle}
        </MainTitle>
        <VFlex style={{ alignItems: 'center' }}>
          <div />
          {
            range(0, 4).map((i) => {
              return (
                <VFlex style={{ marginTop: 50 }}>
                  {
                    l[`r${i}Title`] && (
                      <S24Racing style={{ width: '90%', textAlign: 'center', alignSelf: 'center' }}>
                        {l[`r${i}Title`]}
                      </S24Racing>
                    )
                  }
                  <Flex
                    style={{ justifyContent: 'center', flexWrap: 'wrap', gap: 32, marginTop: 24, alignItems: 'flex-start', maxWidth: 1056 }}
                  >
                    {
                      range(0, CONFIGS[`r${i}`]).map((ii) => {
                        return (
                          <TeammateItem
                            r={i}
                            idx={ii}
                          />
                        );
                      })
                    }
                  </Flex>
                </VFlex>
              );
            })
          }
        </VFlex>
      </VFlex>
    </VFlex>
  );
});
