import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { Spinner } from 'react-bootstrap';

interface ButtonSpinnerProps {
  style: any;
}

const ButtonSpinner = observer((props:ButtonSpinnerProps) => {
  const {style, ...p} = props;
  return (
    <Spinner
      animation="border"
      variant="light"
      style={{borderColor: '#fdb720', borderRightColor: 'transparent', ...style}}
      {...p}
      // size="sm"
    />
  );
});

export default ButtonSpinner;
