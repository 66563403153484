import React from 'react';
import ReactDOM from 'react-dom';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import App from './App';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA5iI_jufU_V0DI0-LrIwyXAws8KOHm67o',
  authDomain: 'cat-6cf34.firebaseapp.com',
  projectId: 'cat-6cf34',
  storageBucket: 'cat-6cf34.appspot.com',
  messagingSenderId: '947305261179',
  appId: '1:947305261179:web:79a62b5e842498c12cdade',
  measurementId: 'G-Z7PYJLG96X',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('react-root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
