import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import Fade from 'react-reveal';
import { VFlex } from '../Flex';
import { COLORS } from '../../utils/constants';
import { store } from '../../mobx/store';

interface FloatingButtonProps {
}

const Container = styled(VFlex)`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 99;
`;
const Button = styled(VFlex)`
  width: 64px;
  height: 64px;
  justify-content: center;
  background-color: ${COLORS.blue2};
  border-radius: 32px;
`;

const FloatingButton = observer((props:FloatingButtonProps) => {
  // if (store.scrollYOffset < 800) {
  //   return null;
  // }
  return (
    <Container
      className="simple-btn"
    >
      <Fade
        when={store.scrollYOffset > 800}
        duration={200}
      >
        <Button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'auto' });
          }}
        >
          <img
            style={{
              width: 'auto',
              height: '30px',
              transform: 'rotate(180deg)',
            }}
            src="/images/v3/arrow-down.svg"
            alt=""
          />
        </Button>
      </Fade>
    </Container>
  );
});

export default FloatingButton;
