import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { Web3Provider } from '@ethersproject/providers';
import { injectedConnector, useConnect } from '../hooks/hooks';
import { useNCContract } from '../hooks/useContract';
import { store } from '../mobx/store';
import { COLORS, NETWORK_CONFIG } from '../utils/constants';
import { getDisplayPriceString, numberInGroups, useIsDesktop } from '../utils/utils';
import { CatImage } from './CatImage';
import { Flex, VFlex } from './Flex';
import { MainButton } from './MainButton';

type Props = {
}

type MintRowProps = {
  maxAvailable: number,
  onMintPress: (c:number) => void,
}

const MintRow = (props:MintRowProps) => {
  const {onMintPress, maxAvailable} = props;
  const numArr = [];
  for (let i = 1; i <= maxAvailable; i++) {
    numArr.push(i);
  }
  const [mintCountOption, setMintCountOption] = useState(1);
  return (
    <Flex
      style={{alignItems: 'center', height: 70}}
    >
      <div
        style={{height: 57}}
      >
        <label
          className="select"
          htmlFor="slct"
          style={{
            lineHeight: '28px',
            marginTop: '6px',
            height: 45,
          }}
        >
          <select
            id="slct"
            required
            value={mintCountOption}
            onChange={((e) => {
              setMintCountOption(e.target.value);
            })}
          >
            <option value="" disabled>Select ...</option>
            {
              numArr.map((v) => {
                return (
                  <option key={`${v}`} value={v}>{`${v}`}</option>
                );
              })
            }
          </select>
          <svg>
            <use xlinkHref="#select-arrow-down" />
          </svg>
        </label>
        <svg className="sprites">
          <symbol id="select-arrow-down" viewBox="0 0 10 6">
            <polyline points="1 1 5 5 9 1" />
          </symbol>
        </svg>
      </div>
      <div style={{ width: 15 }} />
      <button
        type="button"
        style={{
          height: 45,
        }}
        onClick={async () => {
          onMintPress && onMintPress(mintCountOption);
        }}
      >
        Mint
      </button>
    </Flex>
  );
};

const SALE_STATUS_LOADING = 'loading';
const SALE_STATUS_PREPARING = 'preparing';
const SALE_STATUS_PRESALE = 'presale';
const SALE_STATUS_PUBLIC = 'public';
const SALE_STATUS_SOLD_OUT = 'soldout';

const MAX_CATS = 9999;
const MAX_PER_MINT = 5;
const PRESALE_MAX_MINT = 3;
const MAX_CATS_MINT = 50;

const MintAreaContainer = styled.div`
margin: 30px 0px 0px 0px;
`;


const ProgressBarOuter = styled.div`
max-width: 536px;
width: 90%;
height: 30px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 28px;
opacity: 1;
padding: 5px;
margin-top: 15px;
`;

const ProgressBarInner = styled.div`
width: 50%;
height: 100%;
background: #DD99FD 0% 0% no-repeat padding-box;
border-radius: 28px;
opacity: 1;
`;

const getMaxAvailable = (isPublicSale, presaleMaxMint, tokenBalance, maxPerMint, maxNC, totalSupply, maxNCMint) => {
  if (!isPublicSale) {
    return Math.min(presaleMaxMint, presaleMaxMint - tokenBalance);
  }
  return Math.min(maxPerMint, maxNC - totalSupply, maxNCMint - tokenBalance);
};


export const MintArea = observer((props:Props) => {
  const renderContent = () => {
    return (
      <VFlex
        style={{
          height: 659,
          width: '100vw',
          minWidth: '1440px',
          alignSelf: 'center',
        }}
      >
        <img
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 'auto',
          }}
          src="/images/v2/hero-image-mint-left.png"
          alt=""
        />
        <img
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: 'auto',
          }}
          src="/images/v2/hero-image-mint-right.png"
          alt=""
        />
      </VFlex>
    );
  };

  return (
    <VFlex
      style={{
        backgroundColor: COLORS.grey2,
      }}
    >
      {renderContent()}
    </VFlex>
  );
});
