import { action, computed, configure, makeAutoObservable, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-unresolved
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types';

import { get, post } from '../api/api';
import {Profile} from '../types';
import { NETWORK_CONFIG, NETWORK_ID, WIDTH_HEADER_THRESHOLD, WIDTH_SHORTEN_THRESHOLD, WIDTH_THRESHOLD } from '../utils/constants';
import { getPhase, hexEncode } from '../utils/utils';
import {ToastType} from '../components/toast/types';
import i18nStore from '../localizations';
import { NotConnected9BeanContract } from '../hooks/useContract';
import { WHITELISTED } from '../db/db';

configure({
  enforceActions: 'never',
});

class Store {
  web3Ctx: Web3ReactContextInterface<any> = null

  NBeanContract = null

  isIncorrectNetwork = false;

  isShowOverlay = false;

  isPresaleEndedTSReached = true;

  isPresaleTSReached = true;

  isPresaleStartedInContract = true;

  isPublicSaleTSReached = true;

  isShowTermsAndConditionDialog = false;

  userSignature: { dt: string, signature: string } = null

  wlStatus = undefined

  salePhase: 'unknown' | 'public' | 'presale' = 'unknown'

  scrollYOffset = 0

  toasts: any[] = []

  profile:Profile = null

  isSkipInvitationCode = true

  isScrollingUp = true // to show header initially

  scrollbarWidth = 0;

  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }

  setScreenSize(s) {
    this.screenSize = s;
  }

  setScrollYOffset(s) {
    const isScrollingUp = s < this.scrollYOffset;
    if (isScrollingUp !== this.isScrollingUp) {
      this.isScrollingUp = isScrollingUp;
    }
    this.scrollYOffset = s;
  }

  get isShortenMode() {
    return this.screenSize.width < WIDTH_SHORTEN_THRESHOLD;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  get isHeaderDesktop() {
    return this.screenSize.width > 1000;
  }

  get headerHeight() {
    return this.isDesktop ? 88 : 60;
  }

  hasValidSignature = (acc) => {
    console.log(this.userSignature);
    if (!this.userSignature) {
      const item = localStorage.getItem(`cache:signature:${acc}`);
      if (item) {
        this.userSignature = JSON.parse(item);
      }
    }
    if (!this.userSignature) return false;
    const ts = parseInt(this.userSignature.dt.split('\n').slice(-1)[0], 10);
    return Date.now() - ts < 3600000;
  }

  signByMetamask = async (library, acc, extraMessage = 'Connecting to 9Cat...') => {
    if (this.hasValidSignature(acc)) return this.userSignature;
    const dt = `${extraMessage ? `${extraMessage}\n\nTimestamp:\n` : ''}${Date.now()}`;
    const msg = hexEncode(dt);
    const t = await library.provider.request({
      method: 'personal_sign',
      params: [msg, acc],
    });
    this.userSignature = {
      dt,
      signature: t,
    };
    localStorage.setItem(`cache:signature:${acc}`, JSON.stringify(this.userSignature));
    return this.userSignature;
  }

  fetchWLKey = async (address: string) => {
    try {
      const res = await post({
        uri: '/wl/eligible-key',
        body: {
          'wallet-address': address,
        },
      });
      this.wlStatus = res.body;
    } catch (error) {
      this.wlStatus = false;
    }
  };

  fetchProfile = async (address: string) => {
    try {
      console.log('fetch profile');
      const res = await get({
        uri: `/profile/${address}`,
      });
      console.log(res);
      this.profile = res.body;
    } catch (error) {
      console.log(error);
      this.profile = null;
    }
  }

  fetchConfirmReferrer = async (invitedByInvitationCode, signature, message, address) => {
    try {
      console.log('fetch confirm referrer');
      const res = await post({
        uri: '/invitation/invited-by',
        body: {
          invitedByInvitationCode,
          signature,
          message,
          address,
        },
      });
      if (this.profile) {
        this.profile.details.invitedBy = address;
        this.profile.details.referrerInvitationCode = invitedByInvitationCode;
      } else {
        this.profile = {
          address,
          details: {
            referrerInvitationCode: invitedByInvitationCode,
          },
        };
      }
    } catch (error) {
      console.log(error);
      throw error;
      // this.profile = null;
    }
  }

  showToast(toastBody: ToastType) {
    if (!toastBody.id) {
      toastBody.id = `${Date.now()}`;
    }
    this.toasts = [toastBody, ...this.toasts];
  }

  hideToast(id) {
    this.toasts = this.toasts.filter(t => t.id !== id);
  }

  showSigningToast() {
    const id = `showSigningToast-${Date.now()}`;
    this.showToast({
      id,
      title: i18nStore.dict.toastMessages.signing,
      type: 'loading',
      ttl: 1000000,
    });
    return id;
  }

  showTXConfirmingToast() {
    const id = `showTXConfirmingToast-${Date.now()}`;
    this.showToast({
      id,
      title: i18nStore.dict.toastMessages.confirming,
      type: 'loading',
      ttl: 1000000,
    });
    return id;
  }

  showTXProcessingToast(txid:string) {
    const id = `showTXProcessingToast-${Date.now()}`;
    this.showToast({
      id,
      title: i18nStore.dict.toastMessages.txProcessing,
      type: 'loading',
      link: {
        url: `${NETWORK_CONFIG.ethscanRoot}/tx/${txid}`,
        text: 'VIEW ETHERSCAN',
      },
      ttl: 1000000,
    });
    return id;
  }

  showTXFailedToast(txid:string) {
    this.showToast({
      title: i18nStore.dict.toastMessages.txFailed,
      type: 'danger',
      link: {
        url: `${NETWORK_CONFIG.ethscanRoot}/tx/${txid}`,
        text: i18nStore.dict.toastMessages.viewEtherscan,
      },
    });
  }

  showTXSuccessToast(txid:string) {
    this.showToast({
      title: i18nStore.dict.toastMessages.txSuccess,
      type: 'success',
      link: {
        url: `${NETWORK_CONFIG.ethscanRoot}/tx/${txid}`,
        text: i18nStore.dict.toastMessages.viewEtherscan,
      },
    });
  }

  showMintSuccessToast(acc:string) {
    this.showToast({
      title: i18nStore.dict.toastMessages.mintSuccess,
      type: 'success',
      link: {
        url: `${NETWORK_CONFIG.openseaRoot}/${acc}`,
        text: i18nStore.dict.toastMessages.mintSuccessOpenSea,
      },
    });
  }

  showErrorToast(error: any) {
    if (!error) return;
    let errMessage = i18nStore.dict.errors[error.message] || error.message;
    if (error.data) {
      this.showToast({
        title: `${i18nStore.dict.toastMessages.Error} ${error.code}`,
        description: `${errMessage} | ${error.data.message} (code: ${error.data.code})`,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.statusCode) {
      this.showToast({
        title: `${i18nStore.dict.toastMessages.Error} ${error.statusCode} - ${error.error}`,
        description: errMessage,
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code === 4001) {
      this.showToast({
        title: i18nStore.dict.toastMessages.userDeniedTX,
        description: '',
        type: 'danger',
        ttl: 2000,
      });
    } else if (error.code) {
      this.showToast({
        title: `Error ${error.code}`,
        description: errMessage,
        type: 'danger',
        ttl: 2000,
      });
    } else {
      this.showToast({
        title: `${error}`,
        type: 'danger',
        ttl: 2000,
      });
    }
  }

  get isWLKeyReady() {
    return typeof this.wlStatus === 'object';
  }

  get presaleEligiblility() {
    return this.profile && this.profile.details && (this.profile.details.invitationCode || this.profile.details.invitedBy);
  }

  async contractCallWrapper(cc, ncc, method, params = [], settings = {}) {
    let v;
    if (cc) {
      v = await cc[method](...params);
      if (!settings.raw && v.toNumber) {
        v = v.toNumber();
      }
    } else {
      v = await ncc.methods[method](...params).call();
    }
    if (settings.parseInt) {
      v = parseInt(v, 10);
    }
    return v;
  }

  async nBeanContractCall(method, params = [], settings = {}) {
    return this.contractCallWrapper(this.NBeanContract, NotConnected9BeanContract, method, params, settings);
  }

  async txFlow(cb, cb2) {
    let confirmToastHandle;
    try {
      confirmToastHandle = this.showTXConfirmingToast();
      const res = await cb();
      this.hideToast(confirmToastHandle);
      confirmToastHandle = this.showTXProcessingToast(res.hash);
      if (cb2) cb2();
      await res.wait();
      this.hideToast(confirmToastHandle);
      this.showTXSuccessToast(res.hash);
    } catch (error) {
      this.hideToast(confirmToastHandle);
      this.showErrorToast(error);
      throw error;
    }
  }

  logout() {
    localStorage.clear();
    location.reload();
  }

  get whitelisted() {
    return WHITELISTED[this.web3Ctx.account.toLowerCase()];
  }

  constructor() {
    makeAutoObservable(this);
    this.salePhase = Date.now() > NETWORK_CONFIG.publicSaleCutOffTS ? 'presale' : 'public';
    if (NETWORK_ID === 1) {
      const updater = async () => {
        const mp = await get({
          uri: '/mint_phase',
        });
        this.salePhase = mp.body.isPublic ? 'public' : 'presale';
      };
      (async () => {
        updater();
        const delta = NETWORK_CONFIG.publicSaleCutOffTS - Date.now();
        if (delta > 0) {
          setTimeout(() => {
            updater();
          }, delta);
        }
      })();
    }
  }
}

export const store = new Store();
