import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

import { now } from 'mobx-utils';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import GlobalCSS from '../../GlobalStyle';
import { store } from '../../mobx/store';
import { useIsDesktop, getDisplayPriceString, numberInGroups, getPhase, formatBN, getIsSelling, signMessage, parseQuery } from '../../utils/utils';
import { Flex, VFlex } from '../Flex';
import { Header } from '../Header';
import { MintArea } from '../MintArea';
import { WalletLoader } from '../WalletLoader';
import { injectedConnector, useConnect } from '../../hooks/hooks';
import { useNCContract } from '../../hooks/useContract';
import { COLORS, INVITATION_CODE_REGEX, MediaQueryMobileOnly, NETWORK_CONFIG, PHASE, getLtMediaQuery } from '../../utils/constants';
import { Body, MintTitleText, S14Normal, SubText } from '../Typography';
import { MintingComponent } from './MintingComponent';
import { get } from '../../api/api';
import i18nStore from '../../localizations';
import FooterV3 from '../v3/FooterV3';
import { Container, H } from '../Container';
import SideSocialBtnsBar from '../SideSocialBtnsBar';

const getMaxAvailable = (isPublicSale, presaleMaxMint, tokenBalance, maxPerMint, maxNC, totalSupply, maxNCMint) => {
  // if (!isPublicSale) {
  //   return Math.max(Math.min(presaleMaxMint, presaleMaxMint - tokenBalance), 0);
  // }
  return Math.max(Math.min(maxPerMint, maxNC - totalSupply, maxNCMint - tokenBalance));
};

const STitle = styled.div`
color: var(--lightgrey, #F5F5F5);
/* sharp-blue */
text-shadow: 4px 4px 0px #65BDE6;
font-family: Racing Sans One;
font-size: 120px;
font-style: normal;
font-weight: 400;
line-height: 100px; /* 83.333% */
letter-spacing: 12px;
${MediaQueryMobileOnly} {
  font-size: 80px;
}
`;

export const MintPage = observer(() => {
  const isDesktop = useIsDesktop();
  const context = useWeb3React();
  const [_refresh, refresh] = useState(0);
  const [mintCountOption, setMintCountOption] = useState(1);
  const [mintPrice, setMintPrice] = useState(BigNumber.from('100000000000000000'));
  const [presaleMintPrice, setPresaleMintPrice] = useState(BigNumber.from('100000000000000000'));
  const [message, setMessage] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [hasExistingCode, setHasExistingCode] = useState(undefined);
  const [presaleMaxMint, setPresaleMaxMint] = useState(3);
  const [maxPerMint, setMaxPerMint] = useState(9);
  const [maxNC, setMaxNC] = useState(9999);
  const [maxNCMint, setMaxNCMint] = useState(9);
  const isThisSessionMinted = useRef(false);

  const [isReady, setIsReady] = useState();

  const NCContract = useNCContract();

  const numArr = [];
  const maxAvailable = getMaxAvailable(store.salePhase === 'public', presaleMaxMint, tokenBalance, maxPerMint, maxNC, totalSupply, maxNCMint);
  const location = useLocation();
  const l = i18nStore.dict.mintPage;

  useEffect(() => {
    const q = parseQuery(location.search);
    if (typeof q.code === 'string' && INVITATION_CODE_REGEX.exec(q.code)) {
      (async () => {
        try {
          await get({
            uri: `/profile/invitation-code-check/${q.code}`,
          });
          setHasExistingCode(true);
        } catch (error) {
          setHasExistingCode(false);
        }
      })();
    }
  }, [location]);

  const query = parseQuery(location.search);
  const hasValidCode = typeof query.code === 'string' && INVITATION_CODE_REGEX.exec(query.code);

  const currentPhase = PHASE.PUBLIC;

  for (let i = 1; i <= maxAvailable; i++) {
    numArr.push(i);
  }

  const {
    account: acc,
    library,
  } = context;
  const prevAcc = useRef(acc);

  useEffect(() => {
    if (prevAcc.current !== acc) {
      console.log('switched acc');
      prevAcc.current = acc;
    }
    if (acc && NCContract) {
      (async () => {
        const results = await Promise.all([
          NCContract.PUBLIC_SALE_PRICE(),
          NCContract.MAX_9CAT(),
          NCContract.MAX_PER_MINT(),
          NCContract.PRESALE_MAX_MINT(),
          NCContract.MAX_9CAT_MINT(),
          NCContract.amountClaimedBy(acc),
          NCContract.totalSupply(),
          NCContract.PRESALE_PRICE(),
        ]);
        setMintPrice(results[0]);
        setPresaleMintPrice(results[7]);
        setMaxNC(results[1].toNumber());
        setMaxPerMint(results[2].toNumber());
        setPresaleMaxMint(results[3].toNumber());
        setMaxNCMint(results[4].toNumber());
        setTokenBalance(results[5].toNumber());
        setTotalSupply(results[6].toNumber());
        requestAnimationFrame(() => {
          setIsReady(true);
        });
      })();
    } else {
      setIsReady(true);
    }
  }, [acc, NCContract, _refresh]);


  const isMinting = useRef(false);

  // const p = store.salePhase === 'public' ? mintPrice : presaleMintPrice;
  const p = mintPrice;

  const onMint = useCallback(async (evt) => {
    evt.preventDefault();
    if (isMinting.current) return;
    let confirmToastHandle = null;
    let processingToastHandle = null;
    try {
      isMinting.current = false;
      // if (
      //   hasValidCode
      //   && (
      //     (store.profile
      //     && !store.profile.details.invitedBy
      //     && store.profile.details.invitationCode !== query.code)
      //     || (
      //       store.profile === null
      //     )
      //   )
      // ) {
      //   const id = `showSigningToast-${Date.now()}`;
      //   store.showToast({
      //     id,
      //     title: l.confirmInviteCode,
      //     type: 'loading',
      //     ttl: 1000000,
      //   });
      //   try {
      //     const msg = `${i18nStore.confirmInviteCodeMsg} ${query.code}`;
      //     const signature = await signMessage(library, msg, acc);
      //     await store.fetchConfirmReferrer(
      //       query.code,
      //       signature,
      //       msg,
      //       acc,
      //     );
      //     store.hideToast(id);
      //   } catch (e) {
      //     store.hideToast(id);
      //     throw e;
      //   }
      // }
      const payment = p.mul(mintCountOption);
      try {
        // await store.fetchWLKey(acc);
        let gasLimit;
        try {
          // if (store.salePhase === 'public') {
          gasLimit = await NCContract.estimateGas.mint(mintCountOption, {
            value: payment,
          });
          // } else {
          //   gasLimit = await NCContract.estimateGas.mintPresale(mintCountOption, store.wlStatus.b, store.wlStatus.s, {
          //     value: payment,
          //   });
          // }
        } catch (error2) {
          if (error2.code === 'INSUFFICIENT_FUNDS') {
            throw new Error(l.insufficientFunds);
          }
          throw new Error(error2.message);
        }
        const _totalSupply = (await NCContract.totalSupply()).toNumber();
        setTotalSupply(_totalSupply);
        const b = (await NCContract.amountClaimedBy(acc)).toNumber();
        setTokenBalance(b);
        const ma = getMaxAvailable(store.salePhase === 'public', presaleMaxMint, b, maxPerMint, maxNC, _totalSupply, maxNCMint);
        if (parseInt(mintCountOption, 10) > ma) {
          alert(l.moreThanMaxMint);
          return;
        }

        setMessage(l.confirmTx);
        let res1;
        confirmToastHandle = store.showTXConfirmingToast();
        // if (store.salePhase === 'public') {
        res1 = await NCContract.mint(mintCountOption, {
          value: payment,
          gasLimit: Math.ceil(gasLimit.toNumber() * 1.5),
        });
        // } else {
        //   res1 = await NCContract.mintPresale(mintCountOption, store.wlStatus.b, store.wlStatus.s, {
        //     value: payment,
        //     gasLimit: Math.ceil(gasLimit.toNumber() * 1.5),
        //   });
        // }
        store.hideToast(confirmToastHandle);
        console.log({res1});
        processingToastHandle = store.showTXProcessingToast(res1.hash);
        try {
          await res1.wait();
          store.hideToast(processingToastHandle);
          store.showTXSuccessToast(res1.hash);
          store.showMintSuccessToast(acc);
          isThisSessionMinted.current = true;
        } catch (error) {
          store.showTXFailedToast(res1.hash);
          return;
        }
      } catch (error) {
        if (confirmToastHandle) {
          store.hideToast(confirmToastHandle);
        }
        if (processingToastHandle) {
          store.hideToast(processingToastHandle);
        }
        console.error(error);
        if (error.code !== 4001) {
          throw new Error(`${l.txFailed}${error.message}`);
        }
      }
      refresh(Date.now());
      isMinting.current = false;
    } catch (error) {
      store.showErrorToast(error);
    }
    isMinting.current = false;
  }, [acc, context.library, NCContract, presaleMaxMint, maxNC, maxNCMint, maxPerMint, mintCountOption, mintPrice, p, presaleMintPrice, currentPhase]);

  const renderContent = () => {
    // if (!acc) {
    //   return (
    //     <VFlex
    //       style={{
    //         alignItems: 'center',
    //         flex: 1,
    //       }}
    //     >
    //       <div style={{height: 20 + store.headerHeight}} />
    //       <ConnectWalletBtn />
    //       <IncorrectNetworkComponent />
    //     </VFlex>
    //   );
    // }
    // if (!isReady || store.salePhase === 'unknown' || store.profile === undefined) {
    //   return (
    //     <SpinnerContainer
    //       style={{height: '70vh', justifyContent: 'center'}}
    //     >
    //       <Spinner
    //         animation="border"
    //         variant="light"
    //       />
    //     </SpinnerContainer>
    //   );
    // }
    // if (maxAvailable === 0) {
    //   if (isThisSessionMinted.current) {
    //     return (
    //       <CurrentSessionMintAll />
    //     );
    //   }
    //   return (
    //     <MintedAll />
    //   );
    // }
    // if (
    //   store.salePhase === 'public'
    //   || store.presaleEligiblility || hasExistingCode || store.isSkipInvitationCode
    // ) {
    //   return (
    //     <MintingComponent
    //       key={maxAvailable}
    //       setMintCountOption={setMintCountOption}
    //       mintCountOption={mintCountOption}
    //       maxAvailable={maxAvailable}
    //       onMint={onMint}
    //       price={p}
    //     />
    //   );
    // }
    // return (
    //   <InvitationCodeComponent />
    // );
    return (
      <VFlex
        style={{
          marginTop: (store.isDesktop ? 128 : 60) + store.headerHeight,
          marginLeft: store.isDesktop && 36,
          textAlign: !store.isDesktop && 'center',
          alignItems: !store.isDesktop && 'center',
        }}
      >
        <STitle
          style={{maxWidth: 422}}
        >
          {l.mintCatTitleV4}
        </STitle>
        <H h={18} />
        <S14Normal
          style={{maxWidth: 341}}
        >
          {l.mintDescriptionV4}
        </S14Normal>
        <H h={30} />
        <MintingComponent
          key={maxAvailable}
          setMintCountOption={setMintCountOption}
          mintCountOption={mintCountOption}
          maxAvailable={maxAvailable}
          onMint={onMint}
          price={p}
        />
      </VFlex>
    );
  };

  return (
    <VFlex
      style={{
        width: '100vw',
        minHeight: '100vh',
        position: 'relative',
        alignItems: 'center',
        backgroundColor: '#000',
      }}
    >
      <GlobalCSS />
      <WalletLoader />
      <Header
        style={{
          backgroundColor: '#00000000',
        }}
      />
      <Container
        style={{height: 1061, maxWidth: 1100, width: '100vw'}}
      >
        <Flex
          style={{
            width: '100%',
            alignItems: 'center',
            position: 'relative',
            justifyContent: !store.isDesktop && 'center',
            width: '100%',
          }}
        >
          <video
            style={{
              height: 1061,
              width: '100%',
              objectFit: 'cover',
              position: 'absolute',
              right: store.isDesktop && -90,
              opacity: !store.isDesktop && 0.5,
              top: 0,
            }}
            src="/videos/mint_bg.mp4"
            autoPlay
            loop
            muted
            playsInline
          />
          {renderContent()}
        </Flex>
      </Container>
      <FooterV3 />
      <SideSocialBtnsBar
        style={{
          top: '20vh',
          right: store.scrollbarWidth + 20,
        }}
      />
    </VFlex>
  );
});
