export const YUM_CHA_TERMS_AND_CONDITION_EN = `Terms and Conditions :

1. “9Cat x YumCha Special Edition 9Bean NFT” (game character) and “Yum Cha 9Bun NFT” (in-game consumable) Redemption scratch cards have limited supply and are available while stocks last.

2. There is an expiration date for the code on the NFT redemption card and each code can only be used once. The code will be voided after the expiration date and there is no return or exchange for any expired redemption card.

3. The participant who has received “9Cat x YumCha Special Edition 9Bean NFT” and“Yum Cha 9Bun NFT” Redemption scratch card during the promotion period (December 1, 2022 to February 28, 2023) must visit the designated website before the expiration date and follow the instructions to use the 16-digit number on the card to redeem one NFT.

4. “9Cat x YumCha Special Edition 9Bean NFT” Redemption scratch card is only available for qualified spending on Saturdays, Sundays and Mondays during the promotion period. Spending from Tuesday to Friday will not be included. Moreover, the redemption of “Yum Cha 9Bun NFT” are not subject to this restriction, it is available for redemption after fulfilling the conditions on any day during the promotion period.

5. “9Cat x YumCha Special Edition 9Bean NFT” and “Yum Cha 9Bun NFT” Redemption scratch cards must be given on the same day of qualified spending during checkout.

6. “9Cat x YumCha Special Edition 9Bean NFT” (game character) and “Yum Cha 9Bun NFT” (in-game consumable) can be utilised in the game when 9Bean-related features are ready. 9Cat Saga is currently in the Alpha stage and related game features are still under development. Hence, 9Cat does not make any guarantees about the official launch date and the date when NFT is able to be utilised in the game. Please refer to the official website “9Cat.io” for the latest development update.

7. The redemption procedure is as follows: Please log in to the website https://9Cat.io/YumCha, link the Metamask wallet for collecting NFT, and then enter the redemption code to mint. It might take a few minutes for the NFT to be minted and reflected on the linked Metamask wallet.

8. For any issues encountered during the participating of the promotional event, including the redemption of prizes, please contact 9Cat's Customer Service via Discord for assistance. https://discord.gg/9cat

9. “9Cat x YumCha Special Edition 9Bean NFT” is a game character in 9Cat Saga which is a limited edition NFTs with limited quantities. Please keep your redemption code safe and do not reveal it to others.
 
10. If any NFT redemption card is used for illegal activities or is being used in an improper way, 9Cat has the right to terminate the offenders’ right to use the redemption card / NFT.

11. If NFT is obtained by fraudulent behavior or misrepresentation, YumCha and 9Cat will reserve the right to cancel or revoke the issuance of NFT and the offender may be required to recover any damage incurred.

12. For any loss, error or damage of the NFT caused due to receiving, holding or using the NFTs given, resulting in invalid data and unable to receive, hold or utilize the NFT successfully, Yum Cha and 9Cat hold no legal responsibility. By taking part in this contest participants agree to take full responsibility for any potential losses, errors or damage caused.

13. The participants of the event will be deemed to agree and abide by the terms and conditions listed by Yumcha and 9Cat. Yumcha and 9Cat will not be responsible for any consequences of improper use of NFTs.

14. YumCha and 9Cat Team reserve the right to the final interpretation of the terms and conditions of this promotional activity. In case of any disputes, YumCha and 9Cat reserve the right of the final decision.`;

export const YUM_CHA_TERMS_AND_CONDITION_ZH = `
活動條款及細則

1. 遊戲角色「9Cat x YumCha Special Edition 9Bean NFT」(9Cat x 飲茶特別版9Bean NFT) 和 遊戲消耗品「Yum Cha 9Bun NFT」(飲茶睇住流奶 NFT) 刮刮卡數量有限，換完即止。

2. NFT兌換碼設有換領期限，逾期後不可換領，不設退換。每張換領卡只限換領一次。

3. 活動參加者於推廣期內 (於 2022 年 12月 1 日至 2023年2月 28日) 獲贈的「9Cat x YumCha Special Edition 9Bean NFT」和「Yum Cha 9Bun NFT」 必須於刮刮卡上所印刷的期限內到訪指定網站並依照指示使用卡上的 16位兌換號碼換領 NFT 乙個。

4. 「9Cat x YumCha Special Edition 9Bean NFT」 刮刮卡消費活動只限於星期六、日、一進行。星期二至五的消費不會計算於消費活動內。「Yum Cha 9Bun NFT」則不受此限，可於活動推廣期內任何日子滿足條件後均可換領。

5. NFT刮刮卡需於即日結帳時領取，不設補領。

6. 「9Cat x YumCha Special Edition 9Bean NFT」和「Yum Cha 9Bun NFT」可於日後在 9Cat Saga 遊戲內使用。現時 9Cat Saga 為測試階段, 相關功能仍在開發中，9Cat 對功能正式上線日期及NFT使用日期待定，用戶可留意9Cat.io網站公佈9Bean遊戲功能發佈日期。

7. 兌換方式如下: 請登錄網站 https://9Cat.io/YumCha，連結Metamask錢包後，再輸入兌換號碼進行換領，NFT將會於一段時間後被鑄造及傳送至所連結的Metamask錢包內。

8. 兌換時如有任何問題，請加入 https://discord.gg/9Cat 聯絡9Cat Discord Server客服查詢。

9.「9Cat x YumCha Special Edition 9Bean NFT」是9Cat Saga 內的遊戲角色，為限量版NFT，數量有限。請妥善保管換領號碼，請勿向他人透露你的兌換號碼。

10. 任何兌換卡如被用作從事違法活動，或以不正當方法使用，9Cat有權終止該兌換卡 / NFT的使用權。

11. 如以欺詐行為或失實陳述獲取NFT，YUMCHA 和 9Cat 保留取消或收回 NFT 及追討損失的權利。

12. 如參加者在領取、持有 或 使用 NFT或刮刮卡 過程中發生的任何技術事故而引致遺失、錯誤或損毀，導致資料無效之情況，YUMCHA 和 9Cat不負任何法律責任，參加者均不得異議。

13. 換領NFT者須同意遵守 YUMCHA 和 9Cat 所列有關活動之條款及細則。YUMCHA 和 9Cat 對所有因不當使用 NFT 之後果概不負責。

14. 此項活動及其規則的最終解釋權歸YUMCHA 和 9Cat 團隊所有，如有任何爭議，以YUMCHA 和9Cat的決議為準。
`;

export const YUM_CHA_TERMS_AND_CONDITION_JP = YUM_CHA_TERMS_AND_CONDITION_EN;
