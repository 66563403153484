import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { observer } from 'mobx-react';
import { useIsDesktop, useScrollbarWidth } from './utils/utils';
import { Flex, VFlex } from './components/Flex';
import './Main.css';
import { Header } from './components/Header';
import Providers from './components/Providers';
import { WalletLoader } from './components/WalletLoader';
import GlobalCSS from './GlobalStyle';
import { HamburgerOverlay } from './components/HamburgerOverlay';
import FAQV2 from './components/FAQV2';
import JoinDiscordBanner from './components/JoinDiscordBanner';
import { MintPage } from './components/mint/MintPage';
import ToastManager from './components/toast/ToastManager';
import { PrivacyPage } from './components/PrivacyPage';
import { BannerV3 } from './components/banner/BannerV3';
import { LatestNews } from './components/v3/LatestNews';
import { AsSeenOn } from './components/v3/AsSeenOn';
import FooterV3 from './components/v3/FooterV3';
import { store } from './mobx/store';
import FloatingButton from './components/v3/FloatingButton';
import RotationCat from './components/v3/RotationCat';
import NBeanMintPage from './components/9bean/NBeanMintPage';
import { useNBeanContract } from './hooks/useContract';
import YumChaPage from './components/yum-cha/YumChaPage';
import MediaKitPage from './components/pages/MediaKitPage';
import InfoSectionV4 from './components/InfoSectionV4';
import NewAdventure from './components/NewAdventure';
import NCatMarquee from './components/NCatMarquee';
import { NSeriesMarqueeText } from './components/v3/NSeriesMarqueeText';
import { COLORS } from './utils/constants';
import The9CatFamily from './components/The9CatFamily';
import PlayNowSection from './components/PlayNowSection';
import TeamPage from './components/pages/TeamPage';
import RoadmapPage from './components/pages/RoadmapPage';
import PartnerPage from './components/pages/PartnerPage';
import { MobileTCPage } from './components/pages/MobileTCPage';
import { MobilePrivacyPolicyPage } from './components/pages/MobilePrivacyPolicyPage';


const Main = observer(() => {
  const isDesktop = useIsDesktop();
  const containerRef = useRef();
  const h = useHistory();
  const scrollbarWidth = useScrollbarWidth();
  useEffect(() => {
    store.scrollbarWidth = scrollbarWidth;
  }, [scrollbarWidth]);
  return (
    <VFlex
      style={{ width: '100%', overflow: 'hidden', alignItems: 'center' }}
      ref={containerRef}
    >
      <WalletLoader />
      <Header />
      <FloatingButton />
      {/* <FloatingEventButton
        src="/images/v3/yum-cha-floating-btn.png"
        style={{bottom: 120, right: 0}}
        imgStyle={{width: store.isDesktop ? 200 : 150, height: 'auto'}}
        onClick={() => {
          h.push('/yumcha');
        }}
      /> */}
      <BannerV3 />
      <VFlex
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <InfoSectionV4 />
      </VFlex>
      <NewAdventure />
      <div style={{ transform: 'translateY(-100px)' }} id="latest-news" />
      <LatestNews />
      <NSeriesMarqueeText style={{ backgroundColor: COLORS.purple1 }} />
      <NCatMarquee />
      {
        isDesktop ? (
          <RotationCat
            style={{
              height: 0,
              alignSelf: 'flex-end',
              transform: 'translate(-60px,0px)',
              zIndex: 5,
            }}
          />
        ) : null
      }
      <The9CatFamily />
      {/* <NSeriesMarqueeText style={{}} /> */}
      {/* <NMetaverseIntro /> */}
      <PlayNowSection />
      <FooterV3 />
    </VFlex>
  );
});

const Initializer = observer(() => {
  const ctx = useWeb3React();
  useEffect(() => {
    store.web3Ctx = ctx;
  }, [ctx]);
  const NBeanContract = useNBeanContract();
  useEffect(() => {
    if (NBeanContract) {
      store.NBeanContract = NBeanContract;
    }
  }, [NBeanContract, ctx, ctx.account]);
  useEffect(() => {
    const listener = () => {
      requestAnimationFrame(() => {
        store.setScrollYOffset(window.pageYOffset);
      });
    };
    window.addEventListener('scroll', listener);
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('scroll', listener);
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return (
    <div style={{ display: 'none' }}>{ctx?.account}</div>
  );
});

const App = () => {
  return (
    <Providers>
      <BrowserRouter>
        <Initializer />
        <GlobalCSS />
        <Switch>
          <Route exact strict path="/mint" component={MintPage} />
          <Route exact strict path="/mint-9bean" component={NBeanMintPage} />
          <Route exact strict path="/yumcha" component={YumChaPage} />
          <Route exact strict path="/media-kit" component={MediaKitPage} />
          <Route exact strict path="/team" component={TeamPage} />
          <Route exact strict path="/roadmap" component={RoadmapPage} />
          <Route exact strict path="/partners" component={PartnerPage} />
          <Route exact strict path="/privacy" component={PrivacyPage} />
          <Route exact strict path="/legal/mobile_privacy_policy" component={MobilePrivacyPolicyPage} />
          <Route exact strict path="/legal/mobile_terms_conditions" component={MobileTCPage} />
          <Route component={Main} />
        </Switch>
        <HamburgerOverlay />
      </BrowserRouter>
      <ToastManager />
    </Providers>
  );
};

export default App;
