import { action, makeAutoObservable, observable, toJS } from 'mobx';

import _ from 'lodash';
import React from 'react';
import { YUM_CHA_TERMS_AND_CONDITION_EN, YUM_CHA_TERMS_AND_CONDITION_JP, YUM_CHA_TERMS_AND_CONDITION_ZH } from './utils/terms_and_condition';
import { IconMainButton, MainButton } from './components/MainButton';
import { COLORS, NETWORK_CONFIG } from './utils/constants';
import { Flex } from './components/Flex';
// eslint-disable-next-line import/no-cycle
import { store } from './mobx/store';

export const LANGUAGES = [
  'en', 'zh', 'jp',
];

export const localizations = {
  headers: {
    home: {
      en: 'Home',
      zh: 'Home',
      jp: 'Home',
    },
    partners: {
      en: 'Partners',
      zh: 'Partners',
      jp: 'Partners',
    },
    download: {
      en: 'Download',
      zh: '下載',
      jp: 'ダウンロード',
    },
    latestNews: {
      en: 'Latest News',
      zh: '最新消息',
      jp: '最新のニュース',
    },
    nmetaverse: {
      en: '9Metaverse',
      zh: '9Metaverse',
      jp: '9Metaverse',
    },
    roadmap: {
      en: 'Roadmap',
      zh: 'Roadmap',
      jp: 'ロードマップ',
    },
    team: {
      en: 'Team',
      zh: '團隊',
      jp: 'チーム',
    },
    faq: {
      en: 'FAQ',
      zh: '常見問題',
      jp: 'よくあるご質問（FAQ）',
    },
    lightPaper: {
      en: 'Light Paper',
      zh: '白皮書',
      jp: 'ライトペーパー',
    },
    merch: {
      en: 'Merch',
      zh: '商店',
      jp: 'グッズストア',
    },
    news: {
      en: 'News',
      zh: '最新消息',
      jp: 'ニュース',
    },
    game: {
      en: 'Game',
      zh: '遊戲',
      jp: 'ゲーム',
    },
    nfts: {
      en: 'NFTs',
      zh: 'NFTs',
      jp: 'NFT',
    },
    tokens: {
      en: 'Tokens',
      zh: 'Tokens',
      jp: 'トークン',
    },
    mediaKit: {
      en: 'Media',
      zh: 'Media',
      jp: 'Media',
    },
    updates: {
      en: 'UPDATES',
      zh: 'UPDATES',
      jp: 'UPDATES',
    },
    mint: {
      en: 'Mint',
      zh: 'Mint',
      jp: 'Mint',
    },
  },
  bannerV3: {
    description: {
      en: '9Cat Saga Adventure is the first play and own 2D shoot game utilizing the 9Cat NFT to explore the 9Metaverse, collect resources as well as occupy different planets to enjoy a joyful interdimensional traveling with their immortality. Get 9Cat now to play game!',
      zh: '9Cat Saga是一款邊玩邊賺的2D射擊遊戲，玩家可以使用9Cat NFT探索9Metaverse，收集資源，以及到不同的星球探險，享受快樂的跨次元旅行與他們永恆不朽的生命。現在就取得9Cat來玩遊戲吧!',
      jp: '9CatSagaは、9CatNFTを利用して9Metaverseを探索し、資源を集め、様々な惑星を占領することで、その不滅性とともに楽しい次元間移動を楽しむ最初のプレイです。 今すぐ9CatをゲームにGETしよう!',
    },
    contractAddress: {
      en: 'Contract Address',
      zh: '合約地址',
      jp: 'コントラクトアドレス',
    },
    mint9CATBtn: {
      en: 'Mint',
      zh: 'Mint',
      jp: 'Mint',
    },
    playGameBtn: {
      en: 'FREE DOWNLOAD',
      zh: '免費下載',
      jp: '無料ダウンロード',
    },
    lightPaperBtn: {
      en: 'LIGHTPAPER',
      zh: '白皮書',
      jp: 'ライトペーパー',
    },
    trialVersionAvailable: {
      en: 'Trial version is available now!',
      zh: '試玩版現已上線',
      jp: '',
    },
  },
  latestNews: {
    title: {
      en: 'DEVELOPMENT UPDATES',
      zh: '最新消息',
      jp: '最新のニュース',
    },
    readMoreBtn: {
      en: 'Read More',
      zh: '更多...',
      jp: 'もっと見る',
    },
    followUsOn: {
      en: 'Follow us on',
      zh: '關注我們:',
      jp: 'フォローしてください',
    },
  },
  nmetaverseIntro: {
    bubbleTextDesktop: {
      en: 'We are a group of talents',
      zh: '麻雀雖小，五臟俱全',
      jp: '私たちは才能の集団です',
    },
    bubbleTextMobile: {
      en: 'Probably the most interesting project',
      zh: '可能是最有趣的GAMEFI項目',
      jp: 'おそらく最も興味深いプロジェクトだろう',
    },
    nmetaverse: {
      en: '9METAVERSE',
      zh: '9METAVERSE',
      jp: '9METAVERSE',
    },
    nMetaverseDescription: {
      en: '9Metaverse is an ecosystem consisting of interdimensional traveling creatures, galaxies with all sorts of solar systems and enormous mysteries to be discovered. 9Cat is the main character of the 9Cat Saga Adventure, the first gaming adventure set in the 9Metaverse. In future chapters of the 9Cat Saga Adventure, subsequent characters will be introduced to the world that will join 9Cat on his adventures.',
      zh: '9Metaverse是一個由跨維度旅行生物、擁有各種星系和有待發現的巨大謎團組成的生態系統。 9Cat是9Cat Saga的主角，這是第一個以9Metaverse為背景的冒險遊戲。在9Cat Saga的未來章節中，後續的角色將被引入這個世界，他們將與9Cat一起進行冒險。',
      jp: '9メタバースは、あらゆる種類の太陽系と巨大な神秘が発見される次元間移動生物と銀河で構成された生態系で、9Metaverseを背景にした最初のゲーム冒険である9CatSagaの主人公だ。 「9猫サガアドベンチャー」の今後の章では、9猫と共に冒険を繰り広げる後続キャラクターが世界に紹介される予定だ。',
    },
    nfts: {
      en: 'NFTs',
      zh: 'NFTs',
      jp: 'NFT',
    },
    tokens: {
      en: 'TOKENS',
      zh: 'TOKENS',
      jp: 'トークン',
    },
    lightPaper: {
      en: 'LIGHTPAPER',
      zh: '白皮書',
      jp: 'ライトペーパー',
    },
  },
  nCatSagaAdventure: {
    title: {
      en: '9CAT SAGA',
      zh: '9CAT SAGA',
      jp: '9CAT SAGA',
    },
    description: {
      en: '9Cat Saga is the first play and own 2D shoot game utilizing the 9Cat NFT to explore the 9Metaverse, collect resources as well as occupy different planets to enjoy a joyful interdimensional traveling with their immortality.',
      zh: '9Cat Saga是一款真正的邊玩邊賺的2D射擊遊戲，利用9Cat NFT探索9Metaverse，收集資源，以及佔領不同的星球，享受快樂的跨次元旅行與他們的不朽。',
      jp: '9CatSagaは、9CatNFTを利用して9Metaverseを探索し、資源を集め、様々な惑星を占領することで、不死身で楽しい次元間移動を楽しむ最初のプレイです。',
    },
    moreDetails: {
      en: 'More Details',
      zh: '更多詳情',
      jp: '詳細情報',
    },
  },
  bannerTopPart: {
    get9CatBtn: {
      en: 'Get 9Cat',
      zh: '取得9Cat',
      jp: '取得 9Cat',
    },
    GET9CATTOPLAY: {
      en: 'GET 9CAT TO PLAY',
      zh: '取得9Cat來玩遊戲吧!',
      jp: '取得 9CAT プレイする',
    },
    whitelistDesciption: {
      en: 'You need one 9Cat to get whitelisted',
      zh: '你需要一個9Cat才能獲得白名單',
      jp: '1つ必要です 9Catホワイトリストに載る',
    },
    mintNowBtn: {
      en: 'MINT NOW',
      zh: '馬上鑄造',
      jp: '今すぐMint',
    },
    inviteTitle: {
      en: 'ALREADY OWN 9CAT?',
      zh: '已經擁有9Cat?',
      jp: 'すでに所有してい 9CAT?',
    },
    inviteDescription: {
      en: 'Get your friends to mint 9Cat and thus get access to the game',
      zh: '讓你的朋友鑄造 9Cat，從而獲得遊玩遊戲的資格',
      jp: '友達に9Catを造ってもらい、ゲームにアクセスしましょう',
    },
    inviteLinkBtn: {
      en: 'SEND INVITATION LINK',
      zh: '分享邀請連結',
      jp: '招待リンクを送信',
    },
  },
  banner: {
    p1Title: {
      en: '9Cat generates 9Milk Tokens',
      zh: '9Cat NFT會生產9Milk代幣',
      jp: '9Catが9ミルクトークンを生成します',
    },
    p1Content: {
      en: '9Milk token is the utility token for all the NFT collections in 9Metaverse. Every 9Cat finds nine 9Milk tokens a day in the 9Metaverse to sustain their interdimensional adventures. The generation of 9Milk Tokens will start when the presale of 9Cat has begun.',
      zh: '9Milk 代幣是 9Metaverse 中所有 NFT 收藏品的實用代幣。 每個 9Cat 每天都可以在 9Metaverse 中找到 9 個 9Milk 代幣，以維持他們的跨維度冒險之旅。 9Milk 代幣的生成將在 9Cat 的預售開始時開始。',
      jp: '9Milkトークンは、9MetaverseのすべてのNFTコレクションのユーティリティトークンです。 9Cat毎に9ミルクトークンが9Metaverseで1日9個見つかり、次元間の冒険を維持します。 9Catのプレステージが始まると、9Milk トークンの世代が始まります。',
    },
    p2Title: {
      en: 'Play-And-Own 9Cat Saga Adventure',
      zh: '邊玩邊賺的9Cat Saga冒險遊戲',
      jp: 'プレイ・トゥ・アーン 9Cat サガアドベンチャー',
    },
    p2Content: {
      en: 'Getting a 9Cat NFT will allow you to access the upcoming 9Cat Saga Adventure blockchain game. We are building a 2D top-down sandbox survival shooting game that delivers a GameFi ecosystem with your NFT and allows players to earn 9Photon tokens while enjoying the game. If you want to be cured by the cuteness overloaded 9Cat, join us NOW!',
      zh: '獲得9Cat NFT將允許你訪問即將到來的9Cat Saga區塊鏈遊戲。我們正在建立一個2D自上而下的沙盒生存射擊遊戲，用你的NFT提供一個GameFi生態系統，讓玩家在享受遊戲的同時賺取9Photon代幣。如果你想被可愛過頭的9Cat治愈的話，現在就加入我們吧!',
      jp: '9Cat NFTを取得すると、次の9Cat Sagaブロックチェーンゲームにアクセスできます。 私たちはあなたのNFTとゲームファイエコシステムを提供し、プレーヤーがゲームを楽しみながら9Photonトークンを獲得できる2Dトップダウンサンドボックスサバイバルシューティングゲームを構築しています。 可愛すぎる9Catで癒されたいなら、今すぐ参加してください!',
    },
    p3Title: {
      en: 'Access to the premium merch store',
      zh: '進入9Cat的專屬商店購買周邊',
      jp: 'プレミアムメルチストアへのアクセス',
    },
    p3Content: {
      en: 'Our team is sourcing manufacturers to make exclusive 9Cat products and release a premium merch store for all the 9Cat NFT owners. 9Cat is suitable for making plush toys, phone cases, backpacks, T-shirts, you name it! We will listen to the community and bring more 9Cat products to real life.',
      zh: '我們的團隊正在尋找製造商來製作獨家的9Cat產品，並為所有的9Cat NFT擁有者提供專屬商店來購買周邊。 9Cat適合製作毛絨玩具、手機殼、背包、T恤衫，你能想到的都可以。我們將聽取社群的意見，把更多的9Cat產品帶到現實生活中。',
      jp: '当社のチームは、9Cat NFTのすべての所有者に限定9Cat製品を提供し、プレミアムマーチストアをリリースするための調達業者です。 9Catはぬいぐるみ、電話ケース、バックパック、Tシャツを作るのに適しています。 私たちはコミュニティに耳を傾け、より多くの9Cat製品を現実の生活にもたらします。',
    },
  },
  tokenInfo: {
    title: {
      en: 'TOKENS',
      zh: 'TOKENS',
      jp: 'トークン',
    },
    description: {
      en: 'The 9Metaverse Ecosystem lays host to 3 main tokens - 9Meta, 9Photon and 9Milk. These tokens differ in their functionality, purpose and source of generation. The 9Meta token is the primary ecosystem-wide token obtainable via cryptocurrency exchanges and serves as a governance token for the 9Meta DAO.',
      zh: '9Metaverse 生態系統託管 3 個主要代幣 - 9Meta、9Photon 和 9Milk。 這些代幣的功能、目的和生成來源各不相同。 9Meta 代幣是可通過加密貨幣交易所獲得的主要生態系統範圍內的代幣，並用作 9Meta DAO 的治理代幣。',
      jp: '9Metaverseエコシステムでは、主に3つのトークン(9Meta、9Photon、9Milk) が利用されています。これらのトークンは、それぞれの機能、目的、生成源が異なります。 9Metaトークンは、暗号通貨取引で取得可能なエコシステムトークンであり、9Meta DAOのガバナンス・トークンとして使われています。',
    },
    tokenInfoItem1Title: {
      en: 'Utility of $9 MILK TOKEN',
      zh: '$9 MILK 代幣的功能',
      jp: '$9MILKトークンの実用性',
    },
    tokenInfoItem1I1: {
      en: 'Change Bio & Name',
      zh: '更改姓名及簡介',
      jp: 'プロフィールと名前を変更',
    },
    tokenInfoItem1I2: {
      en: 'Breeding',
      zh: '生育',
      jp: 'ブリーディングシステム',
    },
    tokenInfoItem1I3: {
      en: 'And more...',
      zh: '以及更多...',
      jp: 'その他...',
    },
    moreDetailsBtn: {
      en: 'More Details',
      zh: '更多詳情',
      jp: '詳細情報',
    },
  },
  roadMapV3: {
    title: {
      en: 'ROADMAP',
      zh: 'ROADMAP',
      jp: 'ロードマップ',
    },
    toTheMoonText: {
      en: '9Cat will be with you!',
      zh: '9Cat會一直陪著你!',
      jp: '9Catはいつもあなたと一緒にいます！',
    },
    roadMap23Q1_1: {
      en: '9Cat Quest System',
      zh: '9Cat 任務及故事系統',
      jp: '9Catクエストとストーリーシステム',
    },
    roadMap23Q1_2: {
      en: 'New Map - Mt. Sakura (World 2)',
      zh: '新地圖 - 櫻花山 (第二個星球)',
      jp: '新マップ - さくらの丘 (第 2 惑星)',
    },
    roadMap23Q1_3: {
      en: 'New Core mission - Unknown Machine Detected',
      zh: '新核心任務 - 發現不明採集機',
      jp: '新しいコアミッション - 未知の収穫機の発見',
    },
    roadMap23Q1_4: {
      en: 'Character Integration - 9Bean',
      zh: '9Bean 新增功能',
      jp: '9Bean の新機能',
    },
    roadMap23Q1_5: {
      en: 'Progression System Upgrade',
      zh: '改善升級系統',
      jp: 'アップグレードシステムの改善',
    },
    roadMap23Q2_1: {
      en: '9Cat Skill System',
      zh: '9Cat 技能系統',
      jp: '9Cat スキルシステム',
    },
    roadMap23Q2_2: {
      en: 'Planet NFT Presale & Public Sale',
      zh: 'Planet NFT 預售及公開發售',
      jp: 'Planet NFTのプレセールとパブリックセール',
    },
    roadMap23Q2_3: {
      en: 'New core mission - Escort',
      zh: '新核心任務 - 護送',
      jp: '新しいコアミッション - エスコート',
    },
    roadMap23Q2_4: {
      en: '9Cat Attribute System Related Upgrades',
      zh: '9Cat 屬性系統相關升級',
      jp: '9Cat 属性システム関連のアップグレード',
    },
    roadMap23Q2_5: {
      en: 'Multiplayer - Central lobby',
      zh: '新增多人遊戲中央大廳',
      jp: 'マルチプレイヤー中央ロビーを追加',
    },
    roadMap23Q3_1: {
      en: 'Planet Development and Guild',
      zh: '建立星球及公會',
      jp: '惑星とギルドを作成する',
    },
    roadMap23Q3_2: {
      en: 'New Core Mission - Point Defending',
      zh: '新核心任務 - 佔點防衛',
      jp: '新しいコアミッション - 占領と防衛',
    },
    roadMap23Q3_3: {
      en: 'New Seasonal Special Mode',
      zh: '新限時遊玩任務',
      jp: '新しい期間限定のプレイ ミッション デイ',
    },
    roadMap23Q3_4: {
      en: '9Cat Saga Mobile Version',
      zh: '9Cat Saga 手機版本',
      jp: '9Cat Saga モバイル版',
    },
    roadMap23Q3_5: {
      en: '9Marketplace Launch',
      zh: '新增遊戲道具交易市埸',
      jp: 'マーケットプレイスの立ち上げ',
    },
    roadMap23Q4_1: {
      en: '9Cat Breeding System',
      zh: '9Cat 生育系統',
      jp: '9Cat 生殖システム',
    },
    roadMap23Q4_2: {
      en: 'New Map - Steam Punk Industry (World 3)',
      zh: '新地圖 - 蒸氣朋克工廠 (第三個星球)',
      jp: '新マップ - スチームパンク工場 (第三惑星)',
    },
    roadMap23Q4_3: {
      en: '9Meta Listing',
      zh: '9Meta上市',
      jp: '9Metaのリスト',
    },
    roadMap23Q4_4: {
      en: 'New core mission - Outpost Detected',
      zh: '新核心任務 - 檢測到前哨站',
      jp: '新しいコアミッション - 前哨基地を発見',
    },
    roadMap23Q4_5: {
      en: 'New Seasonal Special Mode',
      zh: '新限時遊玩任務',
      jp: '新しい期間限定のプレイ ミッション デイ',
    },
  },
  teamV3: {
    teamDataGrp1Intro_9lives: {
      en: 'CEO of 9Cat Team. She is also a janitor who basically supports everything that requires extra attentions. She has been a software developer for over 5 years and own a startup company with 9cm. She is a cat lover with a 10 years old cat. She believes being a cat is better than being a human.',
      zh: '9Cat團隊的首席執行官 (CEO)。她也是一個管理員，基本上支持所有需要額外關注的事情。她已經做了5年多的軟件開發人員，並與9cm擁有一家創業公司。她是一個愛貓人士，有一隻10歲的貓。她認為做一隻貓比做人更好。',
      jp: '9Catチームの最高経営責任者(CEO) でもある彼女は、基本的に余分な注意を必要とするすべてのものをサポートしています。 彼女は5年以上ソフトウェア開発者であり、9cmのスタートアップ企業を所有しています。 10歳の猫を飼っている猫好きで、猫は人間よりも優れていると信じています。',
    },
    teamDataGrp1Post_9lives: {
      en: 'CEO',
      zh: '首席執行官',
      jp: 'CEO',
    },
    teamDataGrp1Intro_9cm: {
      en: 'CTO of 9Cat Team. He is the chief blockchain architect of 9Metaverse. He leads the team to build smart contracts as well as connect the blockchain and backend to make 9Cat immortal in the Ethereum network. He loves the wild and likes camping everywhere.',
      zh: '9Cat Team 的首席技術官 (CTO)。 他是9Metaverse的首席區塊鏈架構師。 他帶領團隊構建智能合約，並連接區塊鍊和後端，讓 9Cat 在以太坊網絡中永垂不朽。 他熱愛野外，喜歡到處露營。',
      jp: '9CatチームのCTO。彼は9Metaverseのブロックチェーンアーキテクトのチーフです。 彼はチームがスマートコントラクトを構築し、ブロックチェーンとバックエンドを接続してイーサリアムネットワークで9Cat不滅を作るようにリードしています。 彼は野生を愛し、どこでもキャンプが好き.',
    },
    teamDataGrp1Post_9cm: {
      en: 'CTO',
      zh: '首席技術官',
      jp: 'CTO',
    },
    teamDataGrp1Intro_9pm: {
      en: 'Project Manager & Head of Business Development of 9Cat Team. He works closely with 9Lives and 9cm from recruitments to day to day operation of all matters in the 9Metaverse. He also constantly works with the investors and looks for partners to expand the metaverse. He attracts cats and dogs quite a lot but he has an allergy. To avoid the allergy, he pets Axies and trains scholar to take care of them.',
      zh: '9Cat團隊的項目經理和業務發展主管。他與9Lives和9cm緊密合作，從招聘到日常運營9Metaverse的所有事務都有他的身影。他還經常與投資者合作，並尋找合作夥伴，以擴大Metaverse的規模。他很喜歡貓和狗，但他有過敏症。為了避免過敏，他養了Axies並訓練照顧它們。',
      jp: 'プロジェクトマネージャーおよび9Catチームの事業開発責任者。 彼は9Metaverseのすべての問題の募集から日々まで9liveと9cmの操作と密接に協力しています。 彼はまた、常に投資家と協力し、メタバースを拡大するためのパートナーを探します。 猫や犬を多く魅了していますが、アレルギーがあります。 アレルギーを避けるために、彼はAxiesとそれらの世話をするために学者を訓練します。',
    },
    teamDataGrp1Post_9pm: {
      en: 'Project Manager',
      zh: '項目經理',
      jp: 'プロジェクトマネージャー',
    },
    'teamDataGrp1Intro_9x9=99': {
      en: 'Art Director of 9Cat Team. She is a Virgo and a perfectionist. She received tranditional art training as well as a series rigorous practices as an animator. She argues with 9pm a lot as She needs time to deliver a perfect masterpiece while 9pm always gives her a very tight timeline. She is very bad at math and does not even memorize the multiplier table.',
      zh: '9Cat團隊藝術總監。 她是處女座和完美主義者。 作為動畫師的她，接受了傳統的藝術訓練以及一系列嚴格的實踐。 因為她需要時間來交付完美的傑作，而晚上 9 點總是她最忙的時候。 她數學很差，甚至連乘數表都不記得。',
      jp: '9Catチームのアートディレクターで、乙女座で完璧主義者。 彼女は、トランディショナルなアートトレーニングと、アニメーターとしてのシリーズ厳格な練習を受けました。 9pmはいつも彼女に非常にタイトなタイムラインを与えながら、完璧な傑作を提供するために時間が必要なので、彼女は9pmと多くの主張をしています。 彼女は数学が非常に苦手で、乗数表を覚えていません。',
    },
    'teamDataGrp1Post_9x9=99': {
      en: 'Art Director',
      zh: '團隊藝術總監',
      jp: '美術ディレクター',
    },
    teamDataGrp2Intro_9A: {
      en: 'The angel investor of 9Metaverse. He has been running various businesses in all dimensions and he finally arrived the metaverse and started his adventure to build a 9Metaverse together with 9Cat Team.',
      zh: '9Metaverse天使投資者。 他在各個維度經營著各種業務，終於來到了元宇宙，開始了與9Cat團隊一起建立9Metaverse的冒險。',
      jp: '9メタバースのエンジェル投資家。 彼はあらゆる側面でさまざまな事業を運営しており、最終的にメタバースに到着し、9Catチームと一緒に9Metaverseを構築するための冒険を始めました。',
    },
    teamDataGrp2Post_9A: {
      en: 'Angel Investor',
      zh: '天使投資者',
      jp: 'エンジェル投資家',
    },
    teamDataGrp2Intro_9RushToSleep: {
      en: 'Game Artist of 9Cat Team. His strengths include drawing background, objects, weapons, accessories as well as creating 3D objects. He is a pro-gamer Hearthstone and now slowly migrates to various blockchain games to enjoy the blockchain ecosystem in Splinterlands and Gods Unchained. Welcome introducing any new blockchain games to him!',
      zh: '9Cat團隊的遊戲藝術家。他的優勢包括繪製背景、物體、武器、配件以及創建3D物體。他是《爐石傳說》的職業玩家，現在慢慢遷移到各種區塊鏈遊戲，享受Splinterlands 和 Gods Unchained 的區塊鏈生態系統。歡迎向他介紹任何新的區塊鏈遊戲!',
      jp: '9Catチームのゲームアーティスト 彼の強みは、背景、オブジェクト、武器、アクセサリー、3Dオブジェクトの作成などです。 彼はプロゲーマーだんらんであり、今では徐々にさまざまなブロックチェーンゲームに移行して、スプリンターランドとチェーンなしの神々でブロックチェーンエコシステムを楽しみます。 新しいブロックチェーンゲームを紹介しています！',
    },
    teamDataGrp2Post_9RushToSleep: {
      en: 'Game Artist',
      zh: '遊戲藝術家',
      jp: 'ゲームアーティスト',
    },
    'teamDataGrp2Intro_9.9': {
      en: 'Creative Director of 9Metaverse. She has 5+ years of working experience in the game industry. She provides different creative directions to 9Cat and currently also leads the art direction of another title in 9Metaverse. Stay tuned and we will tell you more when the time comes. She is a fashion & Jewellery designer as well as a handcrafter in real life. She is also a digital collector and recently decided to be a crypto collector. Show her your collections!',
      zh: '9Metaverse的創意總監。她在遊戲行業有5年以上的工作經驗。她為9Cat提供不同的創意方向，目前還負責9Metaverse中另一個標題的藝術指導。敬請關注，我們會在時機成熟時告訴你更多。在現實生活中，她是一位時裝和珠寶設計師，也是一位手工製作者。她也是一個數碼收藏家，最近決定成為一個加密貨幣收藏家。向她展示你的收藏品!',
      jp: '9Metaverseのクリエイティブディレクター。ゲーム業界で5年以上の経験があります。 9Catに異なるクリエイティブな方向性を提供し、現在は9Metaverseで別のタイトルのアートディレクションをリードしています。 お待ちください、そして私たちは時が来たら、あなたにもっと教えます。 彼女はファッション&ジュエリーデザイナーだけでなく、実生活の中でハンドクラフスです。 彼女はデジタルコレクターでもあり、最近暗号コレクターになることを決定しました。彼女のコレクションを見せてください！',
    },
    'teamDataGrp2Post_9.9': {
      en: 'Creative Director',
      zh: '創意總監',
      jp: 'クリエイティブディレクター',
    },
    teamDataGrp2Intro_9Tuna: {
      en: 'Chief Experience Officer of 9Metaverse. She worked in various industries but always focused on UI and UX for almost a decade. Given the exposure in a broad range of dimensions, it equipped her to be open-minded and client-centric. She is in charge of all the UI and UX for all projects across the 9Metaverse. In her leisure time, she is trapped on Netflix and she loves to draw stuff that is relatable to her.',
      zh: '9Metaverse的首席體驗官。她曾在不同的行業工作過，但在近十年的時間裡一直專注於用戶界面和用戶體驗。鑑於在廣泛的層面上的接觸，這使她具備了開放的思維和以客戶為中心的能力。她負責9Metaverse所有項目的用戶界面和用戶體驗。在閒暇時間，她被困在Netflix上，她喜歡畫一些與她相關的東西。',
      jp: '9Metaverseの最高体験責任者。さまざまな業界で働いていましたが、ここ十年間UIとUXの改善に従事しました。幅広い業界の人と接して、彼女はオープンマインドを持って、常に顧客を中心に考えています。彼女は9MetaverseのすべてのプロジェクトのUIとUXデザインを担当しています。 余暇の時間には引きこもってネトフリを見ること、また、自分に関係のあるものを描くことが好きです。',
    },
    teamDataGrp2Post_9Tuna: {
      en: 'Chief Experience Officer',
      zh: '首席體驗官',
      jp: '最高体験責任者',
    },
    teamDataGrp2Intro_9NO: {
      en: 'Lead Game Developer and Game Designer of 9Metaverse. He led a lot of game development processes using the scrum approach. He is very flexible to lead different team sizes to address the needs of the development cycle. He has developed games on VR and mobile platforms. He hangs out in STEAM quite a lot and enjoys playing Back 4 Blood and Final Fantasy XIV recently.',
      zh: '9Metaverse的首席遊戲開發者和遊戲設計師。他領導了很多使用scrum方法的遊戲開發過程。他非常靈活地領導不同規模的團隊以解決開發週期的需要。他曾在VR和手機平台上開發遊戲。他經常在STEAM裡閒逛，最近喜歡玩《Back 4 Blood》和《最終幻想XIV》。',
      jp: 'ゲーム開発者と9Metaverseのゲームデザイナーをリードし、スクラムアプローチを用いて多くのゲーム開発プロセスを指揮。 彼は、開発サイクルのニーズに対応するためにさまざまなチームサイズをリードする非常に柔軟です。 彼はVRとモバイルプラットフォームでゲームを開発しています。 彼は蒸気でかなりたくさん出かけて、最近プレイを楽しんでいます 4 ブラッドとファイナルファンタジーXIV.',
    },
    teamDataGrp2Post_9NO: {
      en: 'Lead Game Developer, Game Designer',
      zh: '首席遊戲開發者，遊戲設計師',
      jp: 'リードゲーム開発者、ゲームデザイナー',
    },
    teamDataGrp2Intro_9Up: {
      en: 'Unity Game Developer of 9Metaverse.',
      zh: '9Metaverse 的 Unity 遊戲開發者。',
      jp: '9MetaverseのUnityゲーム開発者。',
    },
    teamDataGrp2Post_9Up: {
      en: 'Unity Game Developer',
      zh: 'Unity 遊戲開發者',
      jp: 'Unityゲーム開発者',
    },
    teamDataGrp2Intro_9Chill: {
      en: 'Unity Game Developer of 9Metaverse.',
      zh: '9Metaverse 的 Unity 遊戲開發者。',
      jp: '9MetaverseのUnityゲーム開発者。',
    },
    teamDataGrp2Post_9Chill: {
      en: 'Unity Game Developer',
      zh: 'Unity 遊戲開發者。',
      jp: 'Unityゲーム開発者',
    },
    teamDataGrp2Intro_9PCT: {
      en: 'Professional Crypto Trader. His daily trading volume could be as high as 1M in BTC (according to the market situation). He gives us advice regarding tokenomics as well as offers us a serious investor perspective to help package 9Metaverse.',
      zh: '專業的加密貨幣交易員。他每天的交易量可能高達100萬BTC（根據市場情況）。他給我們提供了關於tokenomics的建議，同時也為我們提供了嚴肅的投資者視角，幫助包裝9Metaverse。',
      jp: 'プロクリプトトレーダー。彼の日間取引量は100万BTCになる場合もあります(市場の状況に応じて)。彼は私たちに、投資家の視点からトークノミクスのほか9Metaverseのパッケージ化に関するいろいろなアドバイスをくれました。',
    },
    teamDataGrp2Post_9PCT: {
      en: 'Professional Crypto Trader',
      zh: '專業的加密貨幣交易員',
      jp: 'プロクリプトトレーダー',
    },
    'teamDataGrp2Intro_Lime Kat': {
      en: (
        <React.Fragment>
          Community Manager of 9Cat. He is working in a different timezone versus other 9Team members so that we will have at least one moderator during the day. He looks forward to meeting with all you guys and starting the 9Cat Saga adventure with you guys! Come talk to him in
          &nbsp;
          <a target="_blank" href="http://discord.gg/9cat" rel="noreferrer">Discord</a>
          . Also, a fan of mecha anime.
        </React.Fragment>
      ),
      zh: (
        <React.Fragment>
          9Cat的社區經理。他與其他9Team成員在不同的時區工作，這樣我們在白天至少會有一個版主。他期待著與大家見面，與大家一起開始9Cat Saga的冒險之旅! 請到以下地點與他交談
          &nbsp;
          <a target="_blank" href="http://discord.gg/9cat" rel="noreferrer">Discord</a>
          .同時也是機甲類動漫的粉絲。
        </React.Fragment>
      ),
      jp: (
        <React.Fragment>
          9Catのコミュニティマネージャ。 彼は他の9チームメンバーと異なるタイムゾーンで働いているので、日中に少なくとも1人のモデレーターがいることになります。 彼はみなさんと出会って、一緒に9Cat Sagaアドベンチャーを始めるのを楽しみにしています！ぜひここで彼と話しに来てください：
          &nbsp;
          <a target="_blank" href="http://discord.gg/9cat" rel="noreferrer">Discord</a>
          。それに、彼はロボットアニメファンです。
        </React.Fragment>
      ),
    },
    teamDataGrp2Post_8: {
      en: 'Community Manager',
      zh: '社區經理',
      jp: 'コミュニティマネージャー',
    },
    sectionTitle: {
      en: 'CORE TEAM',
      zh: '核心團隊',
      jp: 'コアチーム',
    },
  },
  investorAndPartner: {
    sectionTitle: {
      en: 'INVESTOR & PARTNER',
      zh: '投資者及合作伙伴',
      jp: '投資家とパートナー',
    },
  },
  asSeenOn: {
    en: 'As Seen On',
    zh: '如所見',
    jp: '表示される内容',
  },
  faq: {
    d1q1: {
      en: 'What is 9Cat?',
      zh: '這個項目是關於什麼的？',
      jp: 'このプロジェクトは何に関するものですか？',
    },
    d1a1: {
      en: '9Cat is the main character in the 9Cat gaming metaverse. The metaverse will comprise several collectible character NFT’s and play and own games in the 9Cat Saga. We are launching our initial debut set of 9999 playable characters which will grant access to all our games, allow for NFT breeding in the future and generate 9Milk tokens which can be used for in-game items, future whitelisting, and character personalization.',
      zh: `9Cat的目標是圍繞9Cat和總體的'9Metaverse'發展一個品牌。
9Metaverse將包含多個主題星球供玩家探索，每個星球都有自己的特色風格、NPC、特殊角色等。
這些星球將為名為 "9Cat Saga" 的2.5D星際生存射擊遊戲奠定基礎，該遊戲將在主要平台上開發，首先是MAC、PC，然後擴展到手機平台。
該遊戲將以有趣的、令人興奮的和可持續的遊戲方式為重點，遊玩邊玩邊賺的遊戲。"
      `,
      jp: `9Catは9Cat前後のブランドと全体的な 9Metaverseの開発を目指している。
9Metaverseには、プレイヤーが探索するための複数のテーマ惑星が含まれており、それぞれに独自のシグネチャースタイル、NPC、特殊キャラクターなどがあります。
これらの惑星は、MAC、パソコンを皮切りに、主要プラットフォームで開発される2.5D銀河間サバイバルシューターゲーム9CatSagaセットの基盤を構築し、モバイルプラットフォームに拡張する予定だ。
ゲームは、楽しく、刺激的で、持続可能なゲームプレイに焦点を当てて、プレイツー収益で行われます。`,
    },
    d1q2: {
      en: 'When do you plan on releasing the game?',
      zh: '你們打算什麼時候發布遊戲？',
      jp: 'いつゲームをリリースする予定ですか?',
    },
    d1a2: {
      en: (
        <div>
          The game is currently in Alpha and available to 9Cat NFT holders.
          <br />
          Initial Mainnet release is set for Q4, 2023 with plenty of updates and expansion to mobile platforms (iOS / Android) planned post-release.
          <br />
          New releases of the Alpha occur roughly once a month with Changelogs being posted on both Discord and Medium.
        </div>
      ),
      zh: (
        <div>
          該遊戲目前處於Alpha階段，可供9Cat NFT持有人使用。
          <br />
          最初的主網發布定於2023年第四季度，併計劃在發布後進行大量更新和擴展到移動平台（iOS / Android）。
          <br />
          Alpha的新版本大約每月一次，更新日誌發佈在Discord和Medium上。
        </div>
      ),
      jp: (
        <div>
          このゲームは現在アルファで、9CatNFTホルダーが利用できる。
          <br />
          メインネットの初期リリースは2023年第4四半期に予定されており、リリース後に予定されているモバイルプラットフォーム(iOS/アンドロイド) へのアップデートと拡張が十分に行われている。
          <br />
          アルファの新しいリリースはおよそ月に1回行われ、不和と中程度の両方で変更履歴が投稿されます。
        </div>
      ),
    },
    d1q3: {
      en: 'Where can I buy 9Cat NFTs?',
      zh: '我在哪裡可以買到9Cat NFTs？',
      jp: '9Cat NFTはどこで買えますか？',
    },
    d1a3: {
      en: (
        <div>
          You may purchase a genesis 9Cat NFT from our website via our Minting Portal:
          {' '}
          <a href="https://9cat.io/mint" target="_blank" rel="noreferrer">https://9cat.io/mint</a>
          <br />
          The Minting portal is compatible with Metamask and each 9Cat NFT will cost 0.07ETH + Ethereum Network Fees
          <br />
          Once the minting cap has been reached (9999 total), the only place you will be able to purchase 9Cat NFTs is via secondary NFT marketplaces like OpenSea.
          <br />
          Our official OpenSea Page is: https://opensea.io/collection/9catnft
          <br />
          Please be wary of fake collections and only purchase from the collection above.
          <br />
          If you spot a fake collection or scam, please inform us via Discord.
        </div>
      ),
      zh: (
        <div>
          你可以通過我們的鑄造傳送門在我們的網站上購買創世9Cat NFT：
          <a href="https://9cat.io/mint" target="_blank" rel="noreferrer">https://9cat.io/mint</a>
          <br />
          造幣門戶與Metamask兼容，每個9Cat NFT將花費0.07ETH+以太坊網絡費。
          <br />
          一旦達到鑄造上限（共9999個），你將能夠購買9Cat NFT的唯一地方是通過二級NFT市場如OpenSea。
          <br />
          我們的OpenSea官方頁面是：
          <a href="https://opensea.io/collection/9catnft" target="_blank" rel="noreferrer">https://opensea.io/collection/9catnft</a>
          <br />
          請警惕假的收藏品，只從上面的收藏品中購買。
          <br />
          如果你發現一個假的收藏品或騙局，請通過Discord通知我們。
        </div>
      ),
      jp: (
        <div>
          ジェネシス9Cat NFTは、ミンティングポータルから当社のウェブサイトから購入できます:
          {' '}
          <a href="https://9cat.io/mint" target="_blank" rel="noreferrer">https://9cat.io/mint</a>
          <br />
          鋳造ポータルはメタマスクと互換性があり、9CatNFTはそれぞれ0.07ETH+イーサリアムネットワーク料金がかかる。
          <br />
          造幣局の上限（合計9999）に達したら、9CatNFTを購入できる唯一の場所は、外洋のような2次NFTマーケットプレイスを経由することです。
          <br />
          外洋の公式ページは、:
          {' '}
          <a href="https://opensea.io/collection/9catnft" target="_blank" rel="noreferrer">https://opensea.io/collection/9catnft</a>
          <br />
          偽のコレクションに注意し、上記のコレクションからのみ購入してください。
          <br />
          偽のコレクションや詐欺を見つけた場合は、不和経由でお知らせください。
        </div>
      ),
    },
    d2q1: {
      en: 'Where can I see the Rarity Information of my 9Cat?',
      zh: '我在哪裡可以看到我的9Cat的稀有度信息？',
      jp: '9Catのレアリティ情報はどこで確認できますか?',
    },
    d2a1: {
      en: (
        <div>
          We have partnered with Rarity Sniper to provide a Rarity Ranking for all 9Cat NFTs!
          <br />
          You may check the Rarity Information for your 9Cat NFTs here:
          {' '}
          <a href="https://raritysniper.com/9cat" target="_blank" rel="noreferrer">https://raritysniper.com/9cat</a>
          <br />
          **Please keep in mind that until the 9Cat NFT collection is fully minted, your rarity rank may change periodically.
        </div>
      ),
      zh: (
        <div>
          我們已經與Rarity Sniper合作，為所有9Cat NFT提供稀有度排名！你可以在這裡查看你的9貓NFT的稀有度信息。
          <br />
          你可以在這裡查看你的9Cat NFT的稀有度信息：
          <a href="https://raritysniper.com/9cat" target="_blank" rel="noreferrer">https://raritysniper.com/9cat</a>
          <br />
          **請記住，在9Cat NFT系列完全鑄成之前，你的稀有度排名可能會定期變化。
        </div>
      ),
      jp: (
        <div>
          すべての9Cat NFTのレアリティランキングはレアリティスナイパーで公開中！
          <br />
          9Cat NFTのレアリティ情報は
          <br />
          <a href="https://raritysniper.com/9cat" target="_blank" rel="noreferrer">https://raritysniper.com/9cat</a>
          &nbsp;
          で確認できます。
          <br />
          **9Cat NFT全コレクションがミントされるまで、あなたのレアリティは変動する可能性があります。
        </div>
      ),
    },
    d2q2: {
      en: 'Where can I find more information?',
      zh: '我在哪裡可以找到更多的信息？',
      jp: 'さらに詳しい情報はどこで確認できますか?',
    },
    d2a2: {
      en: (
        <div>
          If you'd like to find more information about the 9Cat Project, you may check out our social media accounts, Medium page or Lightpaper.
          <br />
          Facebook:
          {' '}
          <a href="https://facebook.com/9CatNFT" target="_blank" rel="noreferrer">https://facebook.com/9CatNFT</a>
          <br />
          Instagram:
          {' '}
          <a href="https://instagram.com/9CatNFT" target="_blank" rel="noreferrer">https://instagram.com/9CatNFT</a>
          <br />
          Twitter:
          {' '}
          <a href="https://twitter.com/9CatNFT" target="_blank" rel="noreferrer">https://twitter.com/9CatNFT</a>
          <br />
          Medium:
          {' '}
          <a href="https://medium.com/@9metaverse" target="_blank" rel="noreferrer">https://medium.com/@9metaverse</a>
          <br />
          9Cat Saga Lightpaper:
          {' '}
          <a href="https://metaverse-9.gitbook.io/9metaverse-lightpaper/" target="_blank" rel="noreferrer">https://metaverse-9.gitbook.io/9metaverse-lightpaper/</a>
          <br />
          Alternatively, feel free to join our Discord Community and ask your questions there! We won't bite, promise! ;)
        </div>
      ),
      zh: (
        <div>
          如果你想找到更多關於9Cat項目的信息，你可以查看我們的社交媒體賬戶、Medium頁面或Lightpaper。
          <br />
          Facebook:
          {' '}
          <a href="https://facebook.com/9CatNFT" target="_blank" rel="noreferrer">https://facebook.com/9CatNFT</a>
          <br />
          Instagram:
          {' '}
          <a href="https://instagram.com/9CatNFT" target="_blank" rel="noreferrer">https://instagram.com/9CatNFT</a>
          <br />
          Twitter:
          {' '}
          <a href="https://twitter.com/9CatNFT" target="_blank" rel="noreferrer">https://twitter.com/9CatNFT</a>
          <br />
          Medium:
          {' '}
          <a href="https://medium.com/@9metaverse" target="_blank" rel="noreferrer">https://medium.com/@9metaverse</a>
          <br />
          9Cat Saga Lightpaper:
          {' '}
          <a href="https://metaverse-9.gitbook.io/9metaverse-lightpaper/" target="_blank" rel="noreferrer">https://metaverse-9.gitbook.io/9metaverse-lightpaper/</a>
          <br />
          另外，請隨時加入我們的Discord社區，在那裡提出你的問題！我們不會咬人的。我們不會咬人的，保證！ ；）
        </div>
      ),
      jp: (
        <div>
          9Catプロジェクトの詳細については、私たちのソーシャルメディアアカウント、Medium、またはライトペーパーをご覧ください。
          <br />
          フェイスブック:
          {' '}
          <a href="https://facebook.com/9CatNFT" target="_blank" rel="noreferrer">https://facebook.com/9CatNFT</a>
          <br />
          インスタグラム:
          {' '}
          <a href="https://instagram.com/9CatNFT" target="_blank" rel="noreferrer">https://instagram.com/9CatNFT</a>
          <br />
          ツイッター:
          {' '}
          <a href="https://twitter.com/9CatNFT" target="_blank" rel="noreferrer">https://twitter.com/9CatNFT</a>
          <br />
          Medium:
          {' '}
          <a href="https://medium.com/@9metaverse" target="_blank" rel="noreferrer">https://medium.com/@9metaverse</a>
          <br />
          9Cat Saga Lightpaper:
          {' '}
          <a href="https://metaverse-9.gitbook.io/9metaverse-lightpaper/" target="_blank" rel="noreferrer">https://metaverse-9.gitbook.io/9metaverse-lightpaper/</a>
          <br />
          または、お気軽に不和コミュニティに参加して、そこで質問をしてください!私たちは噛まない、約束!;)
        </div>
      ),
    },
    d2q3: {
      en: 'I\'d like to contact the team - Where should I go?',
      zh: '我想聯繫團隊 - 我應該怎去聯絡？',
      jp: 'チームに連絡したいのですが - どこへ行けばいいですか?',
    },
    d2a3: {
      en: `If you'd like to reach out to us, the fastest way is simply head to our Discord Community and create a Support Ticket with your query or proposal.
      Alternatively, you may send an email to us at marketing@9Cat.io`,
      zh: `如果你想與我們聯繫，最快的方法是簡單地前往我們的Discord社群，並創建一個支持票，提出你的疑問或建議。
      或者，你也可以發送電子郵件給我們：marketing@9Cat.io`,
      jp: `私たちに連絡したい場合は、 最速の方法は、不和コミュニティに向かって、クエリや提案を使ってサポートチケットを作成することです。
      別の方法としては、marketing@9Cat.io にメールをお送りください。`,
    },
  },
  joinDiscordBanner: {
    joinOurDiscord: {
      en: 'JOIN OUR DISCORD',
      zh: '加入我們的Discord社群',
      jp: 'ディスクに参加する',
    },
    getLatestNews: {
      en: 'Get latest news from 9Cat!',
      zh: '獲取9Cat的最新消息!',
      jp: '9Catから最新情報を入手！',
    },
  },
  mintPage: {
    mintCatTitleV4: {
      en: 'MINT CATS',
      zh: 'MINT CATS',
      jp: 'MINT CATS',
    },
    mintDescriptionV4: {
      en: 'Owning 9cats to get more benefits from games. And you can access to member-exclusive items in the future.',
      zh: '擁有9貓可獲得更多遊戲好處。未來您還可以訪問會員專屬物品。',
      jp: '9キャットを所有して、ゲームからさらなる利益を得ることができます。そして、将来的にはメンバー専用アイテムにアクセスできます。',
    },
    errorInsufficientFunds: {
      en: 'Insufficient Funds.',
      zh: '資金不充足。',
      jp: '資金不足です。',
    },
    selectMintCount: {
      en: 'Please select count of 9Cat you want to mint',
      zh: '請選擇你要的9Cat數量',
      jp: '9Catの数を選択してください。',
    },
    verifyWhitelistMessage: {
      en: 'This will confirm and verify if you can mint during presale.',
      zh: '這將確認和驗證你是否可以在預售期間鑄造。',
      jp: 'これはプレセール中にミントできるかどうかを確認し、確認します。',
    },
    insufficientFunds: {
      en: 'Not enough ETH to mint',
      zh: '沒有足夠的 ETH 來鑄造',
      jp: '鋳造できるETHが不足しています',
    },
    moreThanMaxMint: {
      en: 'You would like to mint {0} x 9Cat, but only {1} is available for you to mint now.',
      zh: '你想鑄造 {0} 隻9Cat，但現在只有 {1} 隻可供你鑄造。',
      jp: 'ミントを入れたいと思います{0} 9Cat, しかし、 {1} あなたが今ミントするために利用可能です。',
    },
    confirmTx: {
      en: 'Please confirm the transaction at your wallet',
      zh: '請在你的錢包確認鑄造交易',
      jp: 'お財布でお取引をご確認ください',
    },
    txSubmitted: {
      en: 'Transaction submitted, waiting for confirmation',
      zh: '您的交易已提交。 等待確認。',
      jp: 'トランザクションが送信され、確認待ち',
    },
    confirmInviteCode: {
      en: 'Confirming the invitation code.',
      zh: '正在確認邀請碼。',
      jp: '招待コードを確認しています。',
    },
    confirmInviteCodeMsg: {
      en: 'Confirm invitation code:',
      zh: '確認邀請碼:',
      jp: '招待コードを確認:',
    },
    txFailed: {
      en: 'Transaction Failed: ',
      zh: '交易失敗:',
      jp: 'トランザクションに失敗:',
    },
    thankYou: {
      en: 'Thank you!',
      zh: '十分感謝!',
      jp: 'ありがとうございます',
    },
    currentMintAllMessage: {
      en: 'You may check out your cats on Opensea. It might takes few minutes to reflect on Opensea.',
      zh: '你可以在Opensea上查看你的9Cats。可能需要幾分鐘的時間才能在Opensea上查看。',
      jp: 'あなたは外洋で猫をチェックアウトすることができます。外洋に反映するには数分かかるかもしれません。',
    },
    connectedWallet: {
      en: 'Connected Wallet:',
      zh: '已連接的錢包：',
      jp: '接続済みウォレット：',
    },
    viewOnOpenSea: {
      en: 'VIEW ON OPENSEA',
      zh: 'Opensea上查看',
      jp: 'オープンシーで見る',
    },
    mintedAllAvailable: {
      en: 'You have already used this wallet to purchase nine 9Cats so it reached purchase limit. Please use another wallet instead.',
      zh: '您已經使用此錢包購買了九隻 9Cat，因此已達到購買限額。 請改用另一個錢包。',
      jp: 'あなたはすでにこの財布を個の9Catsの購入に使用しているので、購入制限に達しました。 代わりに別の財布を使ってください。',
    },
    changeWalletBtn: {
      en: 'CHANGE WALLET',
      zh: '更換錢包',
      jp: 'ウォレットの変更',
    },
    mintedAllViewOpensea: {
      en: 'You can also look for more 9Cat in the secondary market under our official collection in OpenSea.',
      zh: '你也可以在我們OpenSea官方收藏的二級市場尋找更多的9Cat。',
      jp: 'また、外洋の公式コレクションの下で、セカンダリマーケットで9Catをもっと探すこともできます。',
    },
    max9CatsCanMintNow: {
      en: 'Max 9Cats you can mint now:',
      zh: '你現在最多可以鑄造9Cat的數量：',
      jp: 'あなたが今ミントすることができます最大9Cats:',
    },
    getMax: {
      en: 'GET MAX.',
      zh: '已達到最多。',
      jp: '取得マックス。',
    },
    haveInviteCode: {
      en: 'I have invitation code',
      zh: '我有邀請碼',
      jp: '招待コードがあります',
    },
    notThisWallet: {
      en: 'NOT THIS WALLET?',
      zh: '不是這個銀包?',
      jp: 'このウォレットではありませんか?',
    },
    linkedInviteCode: {
      en: 'Linked Invitation Code:',
      zh: '已連結的邀請碼:',
      jp: '招待コード：',
    },
    invitationCode: {
      en: 'Invitation Code:',
      zh: '邀請碼:',
      jp: '招待コード：',
    },
    mintDescription: {
      en: (
        <React.Fragment>
          When you mint, you will first perform a sign action in MetaMask.
          <br />
          The action is made to confirm using the current invitation link. Once you signed, all of your minting will be counted under the invitation link owner as invitation mint. The linkage is irreversible.
        </React.Fragment>
      ),
      zh: (
        <React.Fragment>
          當你鑄造時，你將首先在MetaMask中執行一個簽名動作。
          <br />
          這個動作是為了確認使用當前的邀請鏈接。一旦你簽名，你所有的造幣都將被算在邀請鏈接所有者的名下，成為邀請幣。這種連結是不可逆的。
        </React.Fragment>
      ),
      jp: (
        <React.Fragment>
          ミントするときは、まずMetaMaskでサインアクションを実行します。
          <br />
          アクションは、現在の招待リンクを使用して確認されます。 あなたがサインをすると、あなたの造幣局は招待リンクの所有者の下に招待造幣局としてカウントされます。 リンクは元に戻せません。
        </React.Fragment>
      ),
    },

  },
  toastMessages: {
    mintSuccess: {
      en: 'Your mint transaction is successful. It might takes few minutes to see your items.',
      zh: '你的鑄幣交易已經成功。可能需要幾分鐘時間才能看到你的物品。',
      jp: 'あなたのミントの取引は成功しました。 商品を見るのに数分かかる場合があります。',
    },
    mintSuccessOpenSea: {
      en: 'VIEW ON OPENSEA',
      zh: '在OPENSEA上查看',
      jp: 'オープンシーで見る',
    },
    txSuccess: {
      en: 'Your Transaction is successful. It might takes few minutes to see your transaction.',
      zh: '你的鑄幣交易已經成功。可能需要幾分鐘時間才能看到你的物品。',
      jp: 'トランザクションは成功しました。トランザクションを確認するには数分かかる場合があります。',
    },
    viewEtherscan: {
      en: 'VIEW ON OPENSEA',
      zh: '在OPENSEA上查看',
      jp: 'オープンシーで見る',
    },
    userDeniedTX: {
      en: 'User denied transaction.',
      zh: '用戶拒絕了交易。',
      jp: 'ユーザーが取引を拒否しました。',
    },
    Error: {
      en: 'Error ',
      zh: '錯誤',
      jp: 'エラー',
    },
    txFailed: {
      en: 'Your Transaction is fail. Please try again. ',
      zh: '你的交易失敗。 請再試一次。',
      jp: 'トランザクションは失敗しました。もう一度やり直してください。',
    },
    txProcessing: {
      en: 'Your Transaction is processing...',
      zh: '你的交易正在處理中...',
      jp: 'トランザクションを処理中...',
    },
    confirming: {
      en: 'Please confirm your transaction with metamask.',
      zh: '請使用 metamask 確認你的交易。',
      jp: 'メタマスクでのお取引をご確認ください。',
    },
    signing: {
      en: 'Please click sign to proceed if you agree.',
      zh: '如果同意，請點擊簽名繼續。',
      jp: '同意する場合は、サインをクリックして続行してください。',
    },
  },
  footer: {
    lightpaper: {
      en: 'Light Paper',
      zh: '白皮書',
      jp: 'ライトペーパー',
    },
    latestNews: {
      en: 'LatestNews',
      zh: '最新消息',
      jp: '最近のニュース',
    },
    roadmap: {
      en: 'Roadmap',
      zh: 'Roadmap',
      jp: 'ロードマップ',
    },
    team: {
      en: 'Team',
      zh: '團隊',
      jp: 'チーム',
    },
    nMetaverse: {
      en: '9Metaverse',
      zh: '9Metaverse',
      jp: '9Metaverse',
    },
    game: {
      en: 'Game',
      zh: '遊戲',
      jp: 'ゲーム',
    },
    nCatNFTs: {
      en: '9Cat NFTs',
      zh: '9Cat NFTs',
      jp: '9Cat NFT',
    },
    tokens: {
      en: 'Tokens',
      zh: 'Tokens',
      jp: 'トークン',
    },
    faq: {
      en: 'FAQ',
      zh: '常見問題',
      jp: 'よくあるご質問（FAQ）',
    },
    privacyPolicy: {
      en: 'Privacy Policy',
      zh: '私隱條例',
      jp: 'プライバシーポリシー',
    },
    contactUs: {
      en: 'Contact Us',
      zh: '聯絡我們',
      jp: 'お問い合わせ',
    },
    merch: {
      en: 'Merch',
      zh: 'Merch',
      jp: 'グッズストア',
    },
    joinOurCommunity: {
      en: 'Join Our Community',
      zh: '加入我們社群',
      jp: 'コミュニティに参加',
    },
  },
  nbean: {
    mint9Bean: {
      en: 'Mint 9Bean',
      zh: 'Mint 9Bean',
      jp: 'Mint 9Bean',
    },
    moreInfo: {
      en: 'More Info',
      zh: '更多資訊',
      jp: '情報',
    },
    paragraph: {
      en: 'Deep within the 9Metaverse on a planet far far away, 9Cat finds itself staring at a creature it’s never seen before. The little creature it dubs ‘9Bean’ due to its bean-shaped face is hiding behind a piece of wood. 9Cat meows at the little creature in an attempt to communicate with it, and at first it seems perplexed by the sound, but then bobs up and down.',
      zh: '在9Metaverse裡一個遙遠星球上，9Cat 發現自己正盯著一個他以前從未見過的生物。因其豆形臉而被稱為“9Bean”的小生物躲在一塊木頭後面。 9Cat 對著他喵喵叫，試圖與他交流，起初他似乎對聲音感到困惑，但隨後開始上下擺動。',
      jp: '9Meatverseのはるか遠くにある惑星で、9Catは今まで見たことのない生き物を見つめていました。豆のような形をした顔から「9Bean」と呼ばれている小さな生き物は、木の陰にこっそりと隠れていました。9Catは交流しようとミャーミャーと鳴きました。すると9Beanは、最初は戸惑っていたものの、徐々に上下に動き始めました。',
    },
    infoLink: {
      en: 'https://medium.com/@9metaverse/introducing-9bean-the-free-to-mint-9cat-companion-6b36a3582b6d',
      zh: 'https://medium.com/@9metaverse/9bean%E7%99%BB%E5%9F%B8-%E5%85%8D%E8%B2%BB%E9%91%84%E9%80%A0%E4%BD%A0%E7%9A%849cat-saga%E5%90%8C%E4%BC%B4-aa0e937e93a7',
      jp: 'https://medium.com/@9metaverse/9bean%E7%99%BB%E5%A0%B4-%E7%84%A1%E6%96%99%E3%81%A79cat-saga%E3%81%AE%E4%BB%B2%E9%96%93%E3%82%92%E4%BD%9C%E3%82%8D%E3%81%86-63fea5c2265e',
    },
    title: {
      en: '9Bean is in the house',
      zh: '領養可愛的9Bean!',
      jp: 'かわいい9Bean を飼う！',
    },
    actQuick: {
      en: 'Act quick! The whitelist minting will end soon!',
      zh: '趕快行動吧！白名單免費鑄幣將會結束！',
      jp: '今すぐ！無料ミントはまもなく終了します！',
    },
    mintBtn: {
      en: 'FREE MINT NOW',
      zh: '馬上免費鑄造',
      jp: '今すぐ無料ミント',
    },
    alreadyMinted: {
      en: 'You have already minted a 9Bean. Please check your wallet.',
      zh: '請查看你的錢包，你已經鑄造了9Bean。',
      jp: 'あなたはすでに 9Bean をミントしました。ウォレットをご確認ください。',
    },
    notInWhitelist: {
      en: 'This wallet address is not in the whitelist',
      zh: '這個錢包地址不在白名單中。',
      jp: 'このウォレットアドレスはホワイトリストに登録されていません。',
    },
    minted: {
      en: 'Minted',
      zh: 'Minted',
      jp: 'Minted',
    },
    companion: {
      en: 'The best companion of 9Cat',
      zh: '9Cat的最佳冒險伙伴',
      jp: '9Catの最高の仲間',
    },
    fillForm: {
      en: 'Please fill in this form to get whitelisted.',
      zh: '請填寫此表格以取得白名單。',
      jp: 'ホワイトリストに登録するには、このフォームにご記入ください。',
    },
    registerWhitelist: {
      en: 'Register Whitelist',
      zh: '取得白名單',
      jp: 'ホワイトリストに登録する',
    },
    comingSoon: {
      en: 'Coming Soon',
      zh: '即將開放',
      jp: '近日公開',
    },
    startsIn: {
      en: "9Bean's free mint starts in",
      zh: '9Bean免費鑄造日倒數',
      jp: '9Bean の無料ミントのカウントダウン',
    },
  },
  yumcha: {
    p1Title: {
      en: '9Cat Dim Sum at the READY!',
      zh: '9Cat點心來了!',
      jp: '9Cat飲茶がやってきた！',
    },
    p1Content: {
      en: (
        <React.Fragment>
          <div>
            Drop into the Yum Cha restaurant in Hong Kong for some 9Cat themed delicacies and rewards!
          </div>
          <div>
            Spend >500HKD in one sitting on Sat/Sun/Mon and receive a Special Edition Yum Cha 9Bean NFT!
          </div>
          <div>
            Each 9Cat Dim Sum order will also net you an in-game consumable 9Bun NFT.
            A 9Bun for you, and one for 9Bean too!
          </div>
        </React.Fragment>
      ),
      zh: '9Cat首次與餐廳合作，跨出遊戲世界外來到香港的YUM CHA餐廳！我們為你們準備了9Cat主題的點心包和NFT兌換卡，歡迎喜歡9Cat的人來品嚐可愛的點心和帶NFT回家。活動期間於星期六日一消費滿500港幣就能領取一張飲茶聯乘特別版9Bean NFT兌換卡，而且每點一籠9Cat主題包點就可以取得一張9Bun NFT兌換卡，喂養你的9Bean。一籠包給你，一籠包給9Bean。',
      jp: 'ゲームの世界を飛び出した9CATが香港のYUM CHAレストランに登場！イベント期間中、土日月曜に 500 香港ドル以上お買い上げいただくと、ティーライド用の特別版 9Bean NFT 引き換えカードを受け取ることができます。また、9Cat フードを注文するたびに、9Bean に餌を与えるための 9Bun NFT 引き換えカードを取得できます。',
    },
    termsAndCondition: {
      en: 'TERMS & CONDITIONS',
      zh: '條款及細則',
      jp: '利用規約',
    },
    p2Title: {
      en: '9BEAN - Your reliable pet!',
      zh: '9BEAN - 你的忠實寵物',
      jp: '9BEAN - あなたの忠実なペット',
    },
    p2Content: {
      en: 'Enjoy a mountain of delicious custard buns from Yum Cha, then dive in game with your exclusive 9Bean NFT! This little guy will help you tackle all the alien swarms that come your way, but don\'t forget...It needs food too!! Eat, Fight and most importantly Survive in 9Cat Saga, available on Mac and PC now!',
      zh: '享受完YUM CHA的美味點心之後，兌換你的專屬9Bean NFT投入遊戲吧。這隻小小的豆形外星生物會幫助你解決所有的外星蟲蟲...但不要忘記他是需要食物的！吃飽再打才能在9Cat Saga中生存！現在Mac及PC都可以遊玩喔，下載遊戲加入我們吧。',
      jp: 'YUM CHA のおいしいカスタード バンを楽しんだ後は、専用の 9Bean NFT を引き換えてゲームに入れます。この小さな豆の形をしたエイリアンは、エイリアンのバグをすべて解決するのに役立ちます...9Cat Saga で食べて、戦って、そして最も重要なことに生き残りましょう。 Mac 版と PC 版で利用できるようになりました。',
    },
    title: {
      en: 'YUM CHA EVENT',
      zh: (
        <React.Fragment>
          飲茶聯乘
          <br />
          合作活動
        </React.Fragment>
      ),
      jp: 'ヤムチャイベント',
    },
    scrollDown: {
      en: 'Scroll Down',
      zh: '滑下去',
      jp: '下スクロール',
    },
    location: {
      en: 'LOCATION',
      zh: '地址',
      jp: '位置',
    },
    address: {
      en: '2/F Nan Fung Place,\n173 Des Voeux Road Central',
      zh: '德輔道中173號南豐大廈2樓1-2號舖',
      jp: '2/F Nan Fung Place, 173 Des Voeux Road Central',
    },
    inputRedeemCode: {
      en: 'Input redeem code',
      zh: '輸入兌換號碼',
      jp: '引き換えコードを入力',
    },
    redeem: {
      en: 'REDEEM',
      zh: '兌換',
      jp: '引き換える',
    },
    howToGetRedeemCode: {
      en: 'HOW TO GET REDEEM CODE?',
      zh: '如何取得兌換碼?',
      jp: '引き換えコードを取得するには？',
    },
    redeemed9Bean: {
      en: 'Please wait for one to two days, we are picking up 9Bean to your side...',
      zh: '請等待一至兩天，我們正在接送9Bean到你的身邊...',
      jp: '9Bean をお手元にお届けしますので、1 ～ 2 日お待ちください。',
    },
    redeemedBun: {
      en: 'Please wait for one to two days, we are picking up 9Bun to your side...',
      zh: '請等待一至兩天，我們正在接送9Bun到你的身邊...',
      jp: '9Bun をお手元にお届けしますので、1 ～ 2 日お待ちください。',
    },
    checkOS: {
      en: 'You may check out your NFTs on Opensea. It might takes 1-2 days to reflect on Opensea.',
      zh: '你可以在Opensea上查看NFTs，可能需要一至兩天的時間才能在Opensea上查看。',
      jp: '現在、Opensea で NFTs を表示できます。Opensea で利用できるようになるまで1 日か 2 日かかる場合があります。',
    },
    keepRedeeming: {
      en: 'Keep Redeeming',
      zh: '繼續兌換',
      jp: '引き換え続ける',
    },
    dum: {
      en: 'LOADING',
      zh: '處理中',
      jp: '読み込み中',
    },
    termsAndConditionContent: {
      en: YUM_CHA_TERMS_AND_CONDITION_EN,
      zh: YUM_CHA_TERMS_AND_CONDITION_ZH,
      jp: YUM_CHA_TERMS_AND_CONDITION_JP,
    },
    mediumLink: {
      en: 'https://medium.com/@9metaverse/9cat-teams-up-with-yum-cha-hk-for-an-out-of-this-world-special-collaboration-13208a7da874',
      zh: 'https://medium.com/@9metaverse/%E9%A3%B2%E8%8C%B6%E9%BB%9E%E5%BF%83%E8%B7%A8%E9%80%B2%E5%85%83%E5%AE%87%E5%AE%99-9cat-%E5%9C%98%E9%9A%8A%E8%81%AF%E4%B9%98-%E9%A3%B2%E8%8C%B6-yum-cha-%E6%8E%A8%E5%87%BA%E9%A6%96%E5%80%8B-%E7%9D%87%E4%BD%8F%E6%B5%81%E5%A5%B6-x-9bean-nft%E5%88%AE%E5%88%AE%E5%8D%A1%E6%B6%88%E8%B2%BB%E6%B4%BB%E5%8B%95-7ed269604487',
      jp: 'https://medium.com/@9metaverse/9cat-teams-up-with-yum-cha-hk-for-an-out-of-this-world-special-collaboration-13208a7da874',
    },
  },
  dum: {
    en: '',
    zh: '',
    jp: '',
  },
  days: {
    en: 'Days',
    zh: '日',
    jp: '日',
  },
  hours: {
    en: 'Hours',
    zh: '小時',
    jp: '時間',
  },
  minutes: {
    en: 'Minutes',
    zh: '分鐘',
    jp: '分',
  },
  seconds: {
    en: 'Seconds',
    zh: '秒',
    jp: '秒',
  },
  lang: {
    en: 'English',
    zh: '中文',
    jp: '日本語',
  },
  errors: {
    Redeemed: {
      en: 'Redeemed',
      zh: '兌換碼已被使用',
      jp: 'Redeemed',
    },
    'Code Not Found': {
      en: 'Code Not Found',
      zh: '查無此兌換碼',
      jp: 'Code Not Found',
    },
  },
  mediaKitPage: {
    title: {
      en: '9Cat Media Resources',
      zh: '9Cat圖片素材',
      jp: '9Cat画像素材',
    },
    description: {
      en: 'Here you will find a range of 9Cat brand assets that you can use for the creation of marketing materials. These assets are provided free of charge, and may not be modified or monetized without permission.',
      zh: '這裡提供了一些有關9Cat的圖片素材，開放自由使用，可用作宣傳用途。用戶可以免費下載這些圖片，但並不可以對圖片進行修改或未經許可利用資源獲利。',
      jp: 'オープンで無料で使用でき、プロモーション目的で使用できる 9Cat に関する画像リソースを次に示します。ユーザーはこれらのパターンを無料でダウンロードできますが、許可なくパターンを変更したり、リソースから利益を得たりすることは許可されていません。',
    },
    logos: {
      en: 'Logos',
      zh: '標誌圖案',
      jp: 'ロゴス',
    },
    graphicAssets: {
      en: 'Graphic Assets',
      zh: '遊戲圖片資源',
      jp: 'ゲーム画像アセット',
    },
    videos: {
      en: 'Videos',
      zh: '影片',
      jp: '動画',
    },
    wallpaper: {
      en: 'Wallpaper',
      zh: '電腦及手機桌面背景',
      jp: '壁紙',
    },
    inAppStickers: {
      en: 'In-app Stickers',
      zh: '9Cat主題貼圖',
      jp: '9Catテーマのステッカ',
    },
    inAppStickersTitle: {
      en: 'In-app Stickers',
      zh: 'In-app Stickers',
      jp: 'In-app Stickers',
    },
    logosDescription: {
      en: 'You may find logos for 9Cat, 9Cat Saga and the 9Metaverse here.\nColour and Monochrome versions for each are available.',
      zh: '9Cat、9Cat Saga遊戲及9Metaverse的標誌圖片可以在這裡下載，配有顏色版及單色版本，可用於不同背景。',
      jp: '9Cat、9Cat Saga、9Metaverse のロゴはこちらにあります。\nそれぞれカラー版とモノクロ版があります。',
    },
    graphicAssetsDescription: {
      en: 'Various 9Cat and 9Cat Saga graphics assets can be found here.\nYou are free to implement these assets into your marketing materials, but any modification of original assets and monetization of materials created is prohibited.',
      zh: '在這裡可以找到9Cat及9Cat Saga遊戲的不同設計圖片。你可以免費使用這些資源作為宣傳用途，但並不可以對圖案片行修改或未經許可用作商業獲利用途。',
      jp: 'ここでは、9Cat および 9Cat Saga ゲームのさまざまなデザインの写真を見つけることができます。これらのリソースを宣伝目的で自由に使用することはできますが、許可なくゴボを変更したり、営利目的で使用したりすることはできません。',
    },
    videosDescription: {
      en: 'You may find various 9Cat introduction, interview and update videos here.\nYou are welcome to create clips from these videos, but clips must retain their original messaging. It is prohibited to manipulate videos to mislead, defame or alter the original messaging.',
      zh: '我們製作了不少9Cat的介紹影片和動畫放在這裡，歡迎使用這些影片或是Youtube上載的影片進行創作。記得影片必須保留原本內容，不可對影片進行任何扭曲資訊或遊戲內容的修改。 ',
      jp: 'ここでは、さまざまな 9Cat の紹介とアニメーションのビデオを見つけることができます。これらのビデオからクリップを作成することは大歓迎です。クリップは元のメッセージを保持する必要があります。動画を操作して誤解を与えたり、中傷したり、元のメッセージを変更したりすることは禁止されています。',
    },
    wallpaperDescription: {
      en: 'Wanna be surrounded by 9cat? Wallpaper is one of the options. You may use it your devices, from phone to tablet, as well as desktop.',
      zh: '想被9Cat團團圍住嗎？畫師們創作了一些可以用在電腦桌面和手機桌面的背景圖案！我們的團隊十分喜歡這些圖，也想分享給玩家們使用。這裡提供了不同的像素大小。',
      jp: '9Cat に囲まれたいですか？アーティストは、コンピューター デスクトップやモバイル デスクトップで使用できる背景パターンをいくつか作成しました! 私たちのチームはこれらの写真をとても気に入っており、プレイヤーと共有したいと考えています。壁紙ごとに異なるサイズが用意されています。',
    },
    inAppStickersDescription: {
      en: 'You may find 9Cat themed stickers for various popular messaging apps here.\nChinese and International sticker packs are available for Whatsapp, Telegram and Signal.',
      zh: '貼圖已上載於各個最受歡迎的貼圖平台，分別有無文字版和中文字版，可用於Whatsapp、Telegram及Signal上。',
      jp: 'ステッカーは、最も人気のあるすべてのステッカー プラットフォームにアップロードされています。それぞれ無言版と中国語版があります。 Whatsapp、Telegram、Signal で利用できます。',
    },
    downloadAll: {
      en: 'DOWNLOAD ALL',
      zh: '全部下載',
      jp: 'すべてダウンロード',
    },
    allStickersTitle: {
      en: 'All Stickers',
      zh: '所有貼圖',
      jp: 'すべてのステッカー',
    },
    allBannersTitle: {
      en: 'All Banners',
      zh: '所有Banners',
      jp: 'すべてのバナー',
    },
    portrait: {
      en: 'Portrait',
      zh: '手機版',
      jp: 'モバイル版',
    },
    landscape: {
      en: 'Landscape',
      zh: '電腦版',
      jp: 'PC版',
    },
    weSupports: {
      en: 'We supports',
      zh: '支援',
      jp: 'サポート',
    },
    getSticker: {
      en: 'GET STICKER',
      zh: '查看貼圖',
      jp: 'ステッカーを入手',
    },
    d: {
      en: '',
      zh: '',
      jp: '',
    },
  },
  mainPageV4: {
    bannerHeader: {
      en: 'MULTIPLAYER SHOOTER GAME • FREE TO PLAY',
      zh: '多人射擊遊戲 • 免費遊玩',
      jp: 'マルチプレイヤーシューティングゲーム • 無料プレイ',
    },
    infoTitle1: {
      en: (
        <>
          DON'T GO IT ALONE
          <br />
          FORM A CREW!
        </>
      ),
      zh: (
        <>
          不想做孤獨9貓?
          <br />
          組織隊友冒險吧!
        </>
      ),
      jp: (
        <>
          一人で行くな
          <br />
          クルーを組もう！
        </>
      ),
    },
    infoContent1: {
      en: 'Grab some friends for the adventure of a lifetime - Explore all the worlds of the 9Metaverse together with up to 4 people in Multiplayer mode. Your friends aren’t on the same system? No worries! Crossplay is available! See who’s ready to rumble and dive straight into the action together!',
      zh: '讓我們告訴你一個秘密。在這個遊戲中，沒有壓力，只有樂趣。你可以邀請你的朋友一起加入，我們將一起探索9Metaverse的奇幻世界。在這裡你可以放鬆身心，與好友們一同歷險。\n不管他們使用什麼遊戲系統，都可以輕鬆跨平台連線。讓我們放鬆心情，享受這場未知冒險，來加入我們的遊戲，讓冒險開始吧！',
      jp: 'あなたにひとつ秘密を教えましょう。このゲームでは、ストレスは一切ありません。代わりに、楽しさが待っています。最大4人で、友達と一緒に9Metaverseの世界を探索しましょう。友達が別のゲームシステムを使っている？心配ありません！クロスプレイが可能です！誰が準備できているかを確認し、一緒にアクションに飛び込みましょう！',
    },
    infoTitle2: {
      en: 'NEVER A DULL MOMENT - QUESTS MADE TO ENJOY!',
      zh: '充滿樂趣的9貓冒險等待著你!',
      jp: '"楽しい9Catの冒険が待っています！"',
    },
    infoContent2: {
      en: 'Our GM’s are avid gamers and want to create an experience that they’re proud of and everyone will enjoy. No repetitive constant grinding or same mission types that you see in some other games. There will be quests, map randomness and more added in time. An action packed made for even the most hardcore space travelling cats!',
      zh: '我們的遊戲製作團隊熱愛遊戲，渴望打造一個令他們自豪，讓每位玩家愛不釋手的遊戲體驗，這遊戲將隨著時間會不斷增加全新的任務、地圖，增加不同的隨機性，和各種可愛的貓咪傢俬道具及寵物系統！就算你是最難搞的宇宙9貓，也能找到自己的遊戲樂趣！',
      jp: '私たちのGM（ゲームマスター）たちはゲーム愛好者で、みんなが誇りに思い、楽しむことができる体験を創り出したいと心から願っています。繰り返しの退屈なゲームプレイや同じミッションの繰り返しはありません。クエスト、マップのランダム性、さらに多くの要素が定期的に追加されます。これは、最も熱心な宇宙旅行者のために作られた、アクション満載のゲームです！',
    },
    newAdventureTitleMap1: {
      en: 'UFO Ruins: Ancient Mysteries from Space',
      zh: 'UFO遺跡：來自太空的古老神秘',
      jp: 'UFO遺跡：宇宙からの古代の謎',
    },
    newAdventureTitleMap2: {
      en: 'Mt. Sakura: A Beautiful but Dangerous Place',
      zh: '櫻花山：美麗而危險的地方',
      jp: 'さくら山：美しいが危険な場所',
    },
    newAdventureTitle: { // TODO: to be removed. added newAdventureContentMap1 newAdventureContentMap2 above
      en: 'A NEW ADVENTURE EVERY TIME',
      zh: '每次冒險都有嶄新的體驗',
      jp: '毎回の冒険は常に新しい体験です',
    },
    newAdventureContentMap1: {
      en: 'The UFO Ruins are like a puzzle left behind by aliens. Inside, there are strange machines and secret messages waiting to be uncovered. But beware, there are also tough enemies and tricky puzzles. Exploring here is like an exciting adventure full of surprises.',
      zh: 'UFO遺跡就像是外星人留下的一個謎題。內部有奇怪的機器和等待被揭示的秘密訊息。但要小心，這裡也有強大的敵人和棘手的謎題。在這裡探索就像是一場充滿驚奇的刺激冒險。',
      jp: 'UFOの遺跡は、エイリアンが残したパズルのようです。中には奇妙な機械や秘密のメッセージが隠れています。しかし、用心しなければなりません。強敵や難解なパズルも待ち受けています。ここを探索することは、驚きに満ちたエキサイティングな冒険のようです。',
    },
    newAdventureContentMap2: {
      en: 'Mt. Sakura is inspired by Japan and is a stunning place with forests, lakes, and waterfalls. But there’s a big problem – the volcano might explode! It’s because of Contaminated Dark Matter. An extremely fast playful creature found in the mountainous areas of Mount Sakura. Catching it is said to bring good luck and fortune according to ancient lore.',
      zh: '櫻花山受到日本的啟發，是一個令人驚嘆的地方，擁有森林、湖泊和瀑布。但有一個大問題 - 火山可能會爆發！這是因為受到污染的黑暗物質影響。在櫻花山山區發現了一種極快且充滿活力的生物。據古老傳說，捕捉它會帶來好運和財富。',
      jp: 'さくら山は日本にインスパイアされ、森林、湖、滝などが美しい場所です。しかし、大きな問題があります - 火山が爆発する可能性があります！それは汚染されたダークマターのためです。さくら山の山岳地帯に生息する、非常に速くて遊び心のある生物です。それを捕まえると、古代の伝説によれば幸運と富をもたらすと言われています。',
    },
    newAdventureContent: { // TODO: to be removed. added newAdventureContentMap1 newAdventureContentMap2 above
      en: 'An extremely fast playful creature found in the mountainous areas of Mount Sakura. Catching it is said to bring good luck and fortune according to ancient lore.',
      zh: '那裡有種一種跑得極快，而且很愛跟9貓玩捉迷藏的生物，生活在櫻花山的星球上。據說古老的傳說，抓住它能帶來好運和財富(?) 聽說櫻花山星球其實就是櫻花形狀!住著妖和精的土地。',
      jp: 'あそこには、非常に速く、9Catと一緒にかくれんぼを楽しむことが大好きな生物がいます。彼らは桜山の惑星に住んでいます。古代の伝説によれば、それを捕まえると幸運と富をもたらすと言われています。桜山の惑星は実際には桜の形をしていると言われています！ここは妖精と精霊が住む土地でもあります。',
    },
    viewAll: {
      en: 'VIEW ALL',
      zh: '查看全部',
      jp: 'すべて表示',
    },
    the9CatFamilyTitle: {
      en: 'The 9CAT TEAM',
      zh: '9CAT部隊',
      jp: '9CAT チーム',
    },
    family9CatContent: {
      en: '9Cat explores alien worlds, battling foes and uncovering secrets.',
      zh: '9Cat探索異世界，與敵人交戰，揭示9宇宙當中的秘密。',
      jp: '9Catは異世界を探索し、敵と戦い、秘密を解き明かします。',
    },
    family9BeanContent: {
      en: 'A charming companion in the 9Cat Saga, 9Bean is a mystical creature, born of nature’s essence. It stands as a loyal ally, evolving with every adventure, and even has a special tie to Yum Cha’s collaboration.',
      zh: '在9Cat Saga中，可愛的夥伴9Bean是一個神秘生物，誕生於大自然的精華。它是一位忠實的盟友，在每次冒險中不斷進化，甚至還與Yum Cha的合作有一段特別的故事。',
      jp: '9Cat Sagaの楽しい仲間、9Beanは自然のエッセンスから生まれた不思議な存在。冒険とともに進化し、そしてYum Chaとのコラボレーションにも特別な結びつきがあります。',
    },
    family9BunContent: {
      en: 'A delectable delight from the Yum Cha collaboration, 9Bun is more than just a tasty treat. It’s a consumable in-game item that fuels your 9Bean’s growth, offering a unique bonding experience in the 9Metaverse.',
      zh: '9Bun源自於與飲茶的獨家合作，它不僅是美味佳餚，更是一種可在遊戲中使用的消耗品，能夠助長你的9Bean成長，為你在9Metaverse打造獨特的夥伴體驗。',
      jp: 'Yum Chaコラボとの素晴らしいコラボレーションから生まれた9Bunは、おいしいおやつだけでなく、ゲーム内で使用できる消耗アイテムです。これはあなたの9Beanの成長を促進し、9Metaverseで特別な結びつきを提供します。',
    },
    family9FishyContent: {
      en: 'Beyond being a 9Photon voucher, 9Fishy serves as a versatile ticket granting access to a world of surprises within the 9Cat Saga’s Gacha system. Dive into the excitement, collect unique treasures, and embark on unforgettable adventures with the power of 9Fishy.',
      zh: '除了作為9Photon的相關的換領券，9Fishy還能用作向貓神許願的道具，可以進入9Cat Saga的Gacha系統中領取武器或外觀裝備。一起潛入刺激的遊戲中，收集獨特的寶藏，一起啟程展開難忘的冒險之旅。',
      jp: '9Fishyは9Photonの交換券としてだけでなく、猫神に願い事をするためのアイテムとしても使用でき、9Cat Sagaのガチャシステムから武器や外見装備を取得するために利用できます。一緒に刺激的なゲームにダイブし、ユニークな宝物を集め、忘れられない冒険に旅立ちましょう。',
    },
    viewCollection: {
      en: 'VIEW COLLECTION',
      zh: '查看系列',
      jp: 'コレクションを表示',
    },
    playNowSectionTitle: {
      en: 'Do you also love 9Cats?',
      zh: '你也喜歡9貓嗎?',
      jp: 'あなたも9Catが好きですか？',
    },
    playNowSectionDescription: {
      en: 'PLAY NOW',
      zh: 'PLAY NOW',
      jp: 'PLAY NOW',
    },
  },
  team: {
    r0_0Name: {
      en: 'PLAY NOW',
      zh: 'PLAY NOW',
      jp: 'PLAY NOW',
    },
    r0_0Name: {
      en: '9A/David Kwan',
      zh: '9A/David Kwan',
      jp: '9A/David Kwan',
    },
    r0_0Title: {
      en: 'Chief Business Officer',
      zh: 'Chief Business Officer',
      jp: 'Chief Business Officer',
    },
    r0_0Exp: {
      en: 'Loves and belives in Gaming. The Angel Investor of 9Cat.',
      zh: 'Loves and belives in Gaming. The Angel Investor of 9Cat.',
      jp: 'Loves and belives in Gaming. The Angel Investor of 9Cat.',
    },
    r0_1Name: {
      en: '9Lives/Wendy Lam',
      zh: '9Lives/Wendy Lam',
      jp: '9Lives/Wendy Lam',
    },
    r0_1Title: {
      en: 'CEO & Game Director',
      zh: 'CEO & Game Director',
      jp: 'CEO & Game Director',
    },
    r0_1Exp: {
      en: 'Founder and CEO of 9Cat. An Entrepreneur, App & Game Developer, 99% Cat Lover.',
      zh: 'Founder and CEO of 9Cat. An Entrepreneur, App & Game Developer, 99% Cat Lover.',
      jp: 'Founder and CEO of 9Cat. An Entrepreneur, App & Game Developer, 99% Cat Lover.',
    },
    r0_2Name: {
      en: '9Dragon/Jun Wong',
      zh: '9Dragon/Jun Wong',
      jp: '9Dragon/Jun Wong',
    },
    r0_2Title: {
      en: 'Chief Financial Officer',
      zh: 'Chief Financial Officer',
      jp: 'Chief Financial Officer',
    },
    r0_2Exp: {
      en: 'Keep track of our expenses and an absolute mobile game lover.',
      zh: 'Keep track of our expenses and an absolute mobile game lover.',
      jp: 'Keep track of our expenses and an absolute mobile game lover.',
    },
    r1Title: {
      en: 'Game Development',
      zh: 'Game Development',
      jp: 'Game Development',
    },
    r1_0Name: {
      en: '9CM',
      zh: '9CM',
      jp: '9CM',
    },
    r1_0Title: {
      en: 'Project Manager',
      zh: 'Project Manager',
      jp: 'Project Manager',
    },
    r1_0Exp: {
      en: 'Leads the team to build the 9Cat Saga game in the most efficient way.',
      zh: 'Leads the team to build the 9Cat Saga game in the most efficient way.',
      jp: 'Leads the team to build the 9Cat Saga game in the most efficient way.',
    },
    r1_1Name: {
      en: '9NO',
      zh: '9NO',
      jp: '9NO',
    },
    r1_1Title: {
      en: 'Lead Game Developer',
      zh: 'Lead Game Developer',
      jp: 'Lead Game Developer',
    },
    r1_1Exp: {
      en: 'Led a lot of game development projects using the scrum approach. Aware the needs of good development cycle.',
      zh: 'Led a lot of game development projects using the scrum approach. Aware the needs of good development cycle.',
      jp: 'Led a lot of game development projects using the scrum approach. Aware the needs of good development cycle.',
    },
    r1_2Name: {
      en: '9UP',
      zh: '9UP',
      jp: '9UP',
    },
    r1_2Title: {
      en: 'Game Developer',
      zh: 'Game Developer',
      jp: 'Game Developer',
    },
    r1_2Exp: {
      en: 'Mainly responsible for core gameplay and multiplayer networking.',
      zh: 'Mainly responsible for core gameplay and multiplayer networking.',
      jp: 'Mainly responsible for core gameplay and multiplayer networking.',
    },
    r1_3Name: {
      en: '9Chill',
      zh: '9Chill',
      jp: '9Chill',
    },
    r1_3Title: {
      en: 'Game Developer & Game Architect',
      zh: 'Game Developer & Game Architect',
      jp: 'Game Developer & Game Architect',
    },
    r1_3Exp: {
      en: 'Passionate gamer and game developer. You may find him in global game jam events easily!',
      zh: 'Passionate gamer and game developer. You may find him in global game jam events easily!',
      jp: 'Passionate gamer and game developer. You may find him in global game jam events easily!',
    },
    r1_4Name: {
      en: '9HitCombo',
      zh: '9HitCombo',
      jp: '9HitCombo',
    },
    r1_4Title: {
      en: 'Game Developer & Game Designer',
      zh: 'Game Developer & Game Designer',
      jp: 'Game Developer & Game Designer',
    },
    r1_4Exp: {
      en: 'Fighting game addict. Can play for a whole day without realizing the sun already set. So bad at direction that he can get lost on a straight line.',
      zh: 'Fighting game addict. Can play for a whole day without realizing the sun already set. So bad at direction that he can get lost on a straight line.',
      jp: 'Fighting game addict. Can play for a whole day without realizing the sun already set. So bad at direction that he can get lost on a straight line.',
    },
    r1_5Name: {
      en: '9DevAnson',
      zh: '9DevAnson',
      jp: '9DevAnson',
    },
    r1_5Title: {
      en: 'Game Developer',
      zh: 'Game Developer',
      jp: 'Game Developer',
    },
    r1_5Exp: {
      en: 'Unity Game Developer of 9Metaverse.',
      zh: 'Unity Game Developer of 9Metaverse.',
      jp: 'Unity Game Developer of 9Metaverse.',
    },
    r1_6Name: {
      en: 'Lime Kat',
      zh: 'Lime Kat',
      jp: 'Lime Kat',
    },
    r1_6Title: {
      en: 'Game Designer',
      zh: 'Game Designer',
      jp: 'Game Designer',
    },
    r1_6Exp: {
      en: 'Young, fresh spirit knowing the 00\'s generation\'s need',
      zh: 'Young, fresh spirit knowing the 00\'s generation\'s need',
      jp: 'Young, fresh spirit knowing the 00\'s generation\'s need',
    },
    r1_7Name: {
      en: '9Machi',
      zh: '9Machi',
      jp: '9Machi',
    },
    r1_7Title: {
      en: 'Game Designer',
      zh: 'Game Designer',
      jp: 'Game Designer',
    },
    r1_7Exp: {
      en: '玩遊戲時毫無節制；數度於Pokemon本傳作售後一週內集齊圖鑑，亦曾於各大手遊揮金如土；自詡為玩家的榜樣，廠商眼中的提款機，冀Fenine們能引以為鑑。但比起玩遊戲，他更愛胡思亂想。曾在日內瓦街頭與友人比較各種"0與1之間有無限個小數"的證明。非常毒，也喜歡閱讀，認為The Undoing Project是世一神作。',
      zh: '玩遊戲時毫無節制；數度於Pokemon本傳作售後一週內集齊圖鑑，亦曾於各大手遊揮金如土；自詡為玩家的榜樣，廠商眼中的提款機，冀Fenine們能引以為鑑。但比起玩遊戲，他更愛胡思亂想。曾在日內瓦街頭與友人比較各種"0與1之間有無限個小數"的證明。非常毒，也喜歡閱讀，認為The Undoing Project是世一神作。',
      jp: '玩遊戲時毫無節制；數度於Pokemon本傳作售後一週內集齊圖鑑，亦曾於各大手遊揮金如土；自詡為玩家的榜樣，廠商眼中的提款機，冀Fenine們能引以為鑑。但比起玩遊戲，他更愛胡思亂想。曾在日內瓦街頭與友人比較各種"0與1之間有無限個小數"的證明。非常毒，也喜歡閱讀，認為The Undoing Project是世一神作。',
    },
    r1_8Name: {
      en: '9Ping',
      zh: '9Ping',
      jp: '9Ping',
    },
    r1_8Title: {
      en: 'Backend Developer',
      zh: 'Backend Developer',
      jp: 'Backend Developer',
    },
    r1_8Exp: {
      en: '<<9Ping is still thinking what to write here>>',
      zh: '<<9Ping is still thinking what to write here>>',
      jp: '<<9Ping is still thinking what to write here>>',
    },
    r2Title: {
      en: 'Art & Design',
      zh: 'Art & Design',
      jp: 'Art & Design',
    },
    r2_0Name: {
      en: '9x9=99',
      zh: '9x9=99',
      jp: '9x9=99',
    },
    r2_0Title: {
      en: 'Art Director',
      zh: '團隊藝術總監',
      jp: '美術ディレクター',
    },
    r2_0Exp: {
      en: 'Art Director of 9Cat Team. She is a Virgo and a perfectionist. She is very bad at math and does not even memorize the multiplier table.',
      zh: 'Art Director of 9Cat Team. She is a Virgo and a perfectionist. She is very bad at math and does not even memorize the multiplier table.',
      jp: 'Art Director of 9Cat Team. She is a Virgo and a perfectionist. She is very bad at math and does not even memorize the multiplier table.',
    },
    r2_1Name: {
      en: '9Tuna',
      zh: '9Tuna',
      jp: '9Tuna',
    },
    r2_1Title: {
      en: 'Chief Experience Officer',
      zh: 'Chief Experience Officer',
      jp: 'Chief Experience Officer',
    },
    r2_1Exp: {
      en: 'She worked in various industries but always focused on UI and UX for almost a decade. In her leisure time, she is trapped on Netflix and she loves to draw stuff that is relatable to her.',
      zh: 'She worked in various industries but always focused on UI and UX for almost a decade. In her leisure time, she is trapped on Netflix and she loves to draw stuff that is relatable to her.',
      jp: 'She worked in various industries but always focused on UI and UX for almost a decade. In her leisure time, she is trapped on Netflix and she loves to draw stuff that is relatable to her.',
    },
    r2_2Name: {
      en: 'AdamFlip',
      zh: 'AdamFlip',
      jp: 'AdamFlip',
    },
    r2_2Title: {
      en: 'Artist',
      zh: 'Artist',
      jp: 'Artist',
    },
    r2_2Exp: {
      en: 'Responsible for creating enemy design, map visuals and enemy animations',
      zh: 'Responsible for creating enemy design, map visuals and enemy animations',
      jp: 'Responsible for creating enemy design, map visuals and enemy animations',
    },
    r2_3Name: {
      en: '9RushToSleep',
      zh: '9RushToSleep',
      jp: '9RushToSleep',
    },
    r2_3Title: {
      en: 'Artist',
      zh: 'Artist',
      jp: 'Artist',
    },
    r2_3Exp: {
      en: 'His strengths include designing enemies, weapons, accessories as well as creating 3D objects.',
      zh: 'His strengths include designing enemies, weapons, accessories as well as creating 3D objects.',
      jp: 'His strengths include designing enemies, weapons, accessories as well as creating 3D objects.',
    },
    r2_4Name: {
      en: '9urrr',
      zh: '9urrr',
      jp: '9urrr',
    },
    r2_4Title: {
      en: 'Artist',
      zh: 'Artist',
      jp: 'Artist',
    },
    r2_4Exp: {
      en: '她生活在魚缸罩子裡，喜歡收集宇宙中稀奇古怪的東西，就像一個外星人，如果在工作時推動她，就會聽見她發出類似Urrrr~~~的大叫聲滾走，但請不用太過擔心，只要把《哈利波特》塞到懷裡就會立刻安靜。假日時她喜歡旅行，且家裡有3隻貓，但跟她睡覺的那隻會咬她。',
      zh: '她生活在魚缸罩子裡，喜歡收集宇宙中稀奇古怪的東西，就像一個外星人，如果在工作時推動她，就會聽見她發出類似Urrrr~~~的大叫聲滾走，但請不用太過擔心，只要把《哈利波特》塞到懷裡就會立刻安靜。假日時她喜歡旅行，且家裡有3隻貓，但跟她睡覺的那隻會咬她。',
      jp: '她生活在魚缸罩子裡，喜歡收集宇宙中稀奇古怪的東西，就像一個外星人，如果在工作時推動她，就會聽見她發出類似Urrrr~~~的大叫聲滾走，但請不用太過擔心，只要把《哈利波特》塞到懷裡就會立刻安靜。假日時她喜歡旅行，且家裡有3隻貓，但跟她睡覺的那隻會咬她。',
    },
    r3Title: {
      en: 'Community',
      zh: 'Community',
      jp: 'Community',
    },
    r3_0Name: {
      en: '9Monkee',
      zh: '9Monkee',
      jp: '9Monkee',
    },
    r3_0Title: {
      en: 'Commuity Manager',
      zh: 'Commuity Manager',
      jp: 'Commuity Manager',
    },
    r3_0Exp: {
      en: 'Teacher, Parkour Monkey, Tech enthusiast, gamer and Meme lover. Spends most of the time laughing at questionable memes. The algorithm has pinned him as a degen.',
      zh: 'Teacher, Parkour Monkey, Tech enthusiast, gamer and Meme lover. Spends most of the time laughing at questionable memes. The algorithm has pinned him as a degen.',
      jp: 'Teacher, Parkour Monkey, Tech enthusiast, gamer and Meme lover. Spends most of the time laughing at questionable memes. The algorithm has pinned him as a degen.',
    },
    r3_1Name: {
      en: 'Kim Yaranon - 9Tears',
      zh: 'Kim Yaranon - 9Tears',
      jp: 'Kim Yaranon - 9Tears',
    },
    r3_1Title: {
      en: 'QA Tester',
      zh: 'QA Tester',
      jp: 'QA Tester',
    },
    r3_1Exp: {
      en: 'A gamer and opportunist. A random player of 9Cat Saga. She became our tester in 2023!',
      zh: 'A gamer and opportunist. A random player of 9Cat Saga. She became our tester in 2023!',
      jp: 'A gamer and opportunist. A random player of 9Cat Saga. She became our tester in 2023!',
    },
  },
};

class I18nStore {
  // strings = nls();
  language = 'en'

  dict = localizations

  processedDict = {

  }

  setLanguage = (lang: string) => {
    // this.strings = nls(lang);
    // moment.locale(lang.toLowerCase());
    this.language = lang;
    this.dict = this.processedDict[lang];
  }

  processLanguage = () => {
    LANGUAGES.forEach((lang) => {
      this.processedDict[lang] = {};
    });
    const processObj = (obj, keys: [String]) => {
      if (obj[LANGUAGES[0]]) {
        LANGUAGES.forEach((lang) => {
          const input1 = [lang];
          keys.forEach(k => input1.push(k));
          this.processedDict = _.set(this.processedDict, input1, obj[lang]);
        });
        return;
      }
      Object.entries(obj).forEach(([k, obj2]) => {
        const _keys = keys.slice();
        _keys.push(k);
        processObj(obj2, _keys);
      });
    };
    Object.entries(localizations).forEach(([key, loc]) => {
      processObj(loc, [key]);
    });
  }

  get yumChaSectionData() {
    return [
      {
        title: 'p1Title',
        titleStyle: {
          textShadow: '2px 2px 0px #65BDE6',
        },
        content: 'p1Content',
        imageComponent: (
          <img
            style={{
              width: '100%',
              height: 'auto',
            }}
            alt=""
            src="/images/yum-cha/9cat-yumcha-banner1.png"
          />
        ),
        isImageLeft: true,
        swap: true,
        extraTextComponent: (
          <Flex
            style={{ gap: 12 }}
          >
            <a
              style={{ flex: 1 }}
              href={this.dict.yumcha.mediumLink}
              target="_blank"
              rel="noreferrer"
            >
              <IconMainButton
                style={{ flex: 1, width: 'unset', backgroundColor: COLORS.blue2, color: 'black' }}
                icon="/images/yum-cha/medium-logo.svg"
              >
                {this.dict.nbean.moreInfo}
              </IconMainButton>
            </a>
            <MainButton
              style={{
                flex: 1,
                width: 'unset',
                backgroundColor: 'transparent',
                borderStyle: 'solid',
                borderWidth: 1,
              }}
              onClick={() => {
                store.isShowTermsAndConditionDialog = true;
              }}
            >
              {this.dict.yumcha.termsAndCondition}
            </MainButton>
          </Flex>
        ),
      },
      {
        title: 'p2Title',
        titleStyle: {
          textShadow: '2px 2px 0px #65BDE6',
        },
        content: 'p2Content',
        imageComponent: (
          <img
            style={{
              width: '100%',
              height: 'auto',
            }}
            alt=""
            src="/images/yum-cha/9cat-yumcha-banner2.png"
          />
        ),
        isImageLeft: false,
      },
    ];
  }

  constructor() {
    makeAutoObservable(this, {
      strings: observable,
      language: observable,
      setLanguage: action,
      processLanguage: action,
    });
    // build processed dict
    this.processLanguage();
    let lang = 'en';
    if (localStorage.getItem('language')) {
      lang = localStorage.getItem('language');
    } else {
      const l = navigator.language.split('-')[0];
      if (LANGUAGES.includes(l)) {
        lang = l;
      }
    }
    this.setLanguage(lang);
  }
}

export const i18nStore = new I18nStore();

export default i18nStore;
