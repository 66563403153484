import React, {useEffect, StyleHTMLAttributes} from 'react';
import {observer} from 'mobx-react';
import { reaction } from 'mobx';
import { Flex, VFlex } from '../Flex';
import ToastManager from '../toast/ToastManager';

interface DialogProps {
  dialogID: String;
  onShow: () => void;
  onClose: () => void;
  storeGetter: () => any;
  title: String;
  containerStyle: StyleHTMLAttributes;
}

const Dialog = observer((props:DialogProps) => {
  const {dialogID, onShow, onClose, storeGetter, title, containerStyle} = props;
  useEffect(() => {
    return reaction(storeGetter, (r) => {
      if (r) {
        onShow && onShow();
        document.getElementById(dialogID).showModal();
      } else {
        onClose && onClose();
        document.getElementById(dialogID).close();
      }
    });
  }, []);
  return (
    <dialog id={dialogID} style={containerStyle}>
      <Flex
        style={{padding: 20, gap: '20px', position: 'relative'}}
      >
        <div
          style={{position: 'absolute', right: 8, top: 8}}
          className="simple-btn"
          onClick={() => {
            onClose && onClose();
          }}
        >
          <img
            style={{width: 16, height: 16}}
            src="/images/v3/dialog-close.svg"
            alt=""
          />
        </div>
        <header>
          <h5 style={{textAlign: 'start'}}>{title}</h5>
        </header>
      </Flex>
      {props.children}
      <ToastManager />
    </dialog>
  );
});

export default Dialog;
