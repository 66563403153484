import React, {Component} from 'react';
import styled, { keyframes } from 'styled-components';
import {observer} from 'mobx-react';
import { VFlex } from '../Flex';

interface RotationCatProps {
  style: any,
  darkTheme: Boolean,
}

const rotation1 = keyframes`
0% { transform: rotate(0deg); }
50% { transform: rotate(180deg);  }
100% { transform: rotate(360deg);  }
`;

const A = -0.2;
const B = 1;
const C = 0;

const rotation2 = keyframes`
0% { transform: rotate3d(${A}, ${B}, ${C}, 0deg) translateY(-10px); }
50% { transform: rotate3d(${A}, ${B}, ${C}, 180deg) translateY(-10px);  }
100% { transform: rotate3d(${A}, ${B}, ${C}, 360deg) translateY(-10px);  }
`;

const Container = styled(VFlex)`
align-items: center;
justify-content: center;
z-index: 5;
`;

const TextImage = styled.img`
  animation: ${rotation1} 4s linear infinite;
  width: 195px;
  height: 195px;
`;

const Cat = styled.img`
  position: absolute;
  animation: ${rotation2} 4s linear infinite;
`;

const RotationCat = observer((props:RotationCatProps) => {
  const {style, darkTheme} = props;
  return (
    <Container
      style={style}
    >
      <TextImage
        src={
          darkTheme
            ? '/images/v3/circle-text-purple.svg'
            : '/images/v3/circle-text-dark.svg'
        }
        alt=""
      />
      <Cat
        src="/images/v3/circle-rotation-cat.png"
        alt=""
      />
    </Container>
  );
});

export default RotationCat;
