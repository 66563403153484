import React, { Component, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Flex, ResponsiveFlex, VFlex } from './Flex';
import { widthStore } from '../mobx/width-store';
import { Container, ContainerWithCritical } from './Container';
import { Body, STitleV4 } from './Typography';
import PlayIcon from './PlayIcon';
import { COLORS, getLtMediaQuery } from '../utils/constants';
import { MainButton } from './MainButton';
import OpenseaIcon from './OpenseaIcon';
import i18nStore from '../localizations';
import { useSize } from '../utils/utils';

interface The9CatFamilyProps {
}

const SFamilyName = styled.div`
color: ${props => (props.isSelected ? '#FFFFFF' : '#7A7A7A')};
font-family: Racing Sans One;
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.4px;
${getLtMediaQuery(400)} {
  font-size: 20px;
}
`;


const The9CatFamily = observer((props: The9CatFamilyProps) => {
  const l = i18nStore.dict.mainPageV4;
  const F_CONFIG = [
    {
      name: '9Cat',
      href: 'https://opensea.io/collection/9catnft',
      imgStyle: {
        height: 'auto',
        width: '67%',
        position: 'absolute',
        bottom: '-3.5%',
        transform: 'translateX(5%)',
      },
    },
    {
      name: '9Bean',
      href: 'https://opensea.io/collection/9bean',
      imgStyle: {
        height: 'auto',
        width: '40%',
        position: 'absolute',
        right: 0,
      },
    },
    {
      name: '9Bun',
      href: 'https://opensea.io/collection/9cat-saga-inventory',
      imgStyle: {
        height: 'auto',
        width: '28%',
        position: 'absolute',
        left: '5%',
        top: '35%',
      },
    },
    {
      name: '9Fishy',
      href: 'https://opensea.io/collection/9metaverse-9cat-saga',
      imgStyle: {
        height: 'auto',
        width: '40%',
        position: 'absolute',
        left: 0,
      },
    },
  ];
  const [selectedIdx, setSelectedIdx] = useState(0);
  const selectedConfig = F_CONFIG[selectedIdx];
  const containerRef = useRef();
  const containerSize = useSize(containerRef);
  return (
    <Container
      style={{
        alignItems: 'flex-start',
        padding: '88px 0px 144px 0px',
        alignSelf: 'center',
      }}
      c={1300}
    >
      <ResponsiveFlex
        style={{
          width: '100%',
          flexDirection: !widthStore.isFamilyDesktop && 'column-reverse',
        }}
        c={1200}
      >
        <VFlex
          style={{
            position: 'relative',
            flex: 1,
            paddingLeft: widthStore.isFamilyDesktop && 32,
            alignItems: !widthStore.isFamilyDesktop && 'center',
          }}
        >
          <STitleV4
            style={{
              textAlign: !widthStore.isFamilyDesktop && 'center',
              maxWidth: 427,
              color: 'white',
            }}
          >
            {l.the9CatFamilyTitle}
          </STitleV4>
          <Flex style={{ gap: 24 }}>
            {
              F_CONFIG.map((c, idx) => {
                const isSelected = selectedIdx === idx;
                return (
                  <Flex
                    style={{ transition: 'all 0.3s', marginTop: 20 }}
                    className="simple-btn"
                    onClick={() => {
                      setSelectedIdx(idx);
                    }}
                  >
                    <div
                      style={{
                        transition: 'all 0.3s',
                        width: isSelected ? 14 : 0,
                        marginRight: isSelected ? 17 : 0,
                        overflow: 'hidden',
                        opacity: isSelected ? 1 : 0,
                      }}
                    >
                      <PlayIcon />
                    </div>
                    <SFamilyName isSelected={isSelected}>
                      {c.name}
                    </SFamilyName>
                  </Flex>
                );
              })
            }
          </Flex>
          <Flex>
            <VFlex
              style={{
                position: 'relative',
                width: widthStore.isFamilyDesktop ? 390 : containerSize?.width,
                // height: 142,
                background: COLORS.darkBlack,
                padding: '15px 25px',
                boxShadow: '2px 2px 0px 0px #BF77E6',
                marginTop: 30,
              }}
            >
              <Body>
                {l[`family${F_CONFIG[selectedIdx].name}Content`]}
              </Body>
            </VFlex>
            {
              widthStore.isFamilyDesktop && (
                <div
                  style={{ position: 'relative', alignSelf: 'flex-start', marginTop: 80 }}
                >
                  <Flex
                    style={{
                      position: 'absolute',
                      height: 2,
                      width: 100,
                      backgroundColor: COLORS.primary,
                      left: 0,
                      top: 0,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <VFlex
                        style={{
                          position: 'absolute',
                          left: 0,
                          backgroundColor: COLORS.primary,
                          width: 150,
                          height: 2,
                          transform: 'rotate(40deg)',
                          transformOrigin: '0 0',
                        }}
                      />
                    </div>
                  </Flex>
                </div>
              )
            }
          </Flex>
          {/* <a
            href={selectedConfig.href}
            target="_blank"
            rel="noreferrer"
            style={{
              width: widthStore.isFamilyDesktop ? 390 : containerSize?.width,
            }}
          >
            <MainButton
              style={{marginTop: 38, gap: 12, width: !widthStore.isFamilyDesktop && 'unset', alignSelf: 'stretch'}}
            >
              <OpenseaIcon />
              <div style={{transform: 'translateY(1px)'}}>
                {l.viewCollection}
              </div>
            </MainButton>
          </a> */}
        </VFlex>
        <VFlex
          style={widthStore.isFamilyDesktop ? {
            width: 'auto',
            height: 617,
            transition: 'opacity: 0.3s',
            flex: 1,
            alignItems: 'center',
            position: 'relative',
          } : {
            position: 'relative',
            width: 'calc(100vw - 72px)',
            // maxHeight: 617,
            aspectRatio: '733 / 617',
            transition: 'opacity: 0.3s',
            alignItems: 'center',
          }}
          ref={containerRef}
        >
          <img
            style={widthStore.isFamilyDesktop ? {
              height: '100%',
              objectFit: 'contain',
              aspectRatio: '733 / 617',
            } : {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            src="/images/v4/family/bg.png"
            alt=""
          />
          {
            F_CONFIG.map(({ name, imgStyle }, idx) => {
              return (
                <img
                  style={{
                    ...imgStyle,
                    filter: `brightness(${idx === selectedIdx ? '100%' : '50%'})`,
                    transition: 'all 0.3s',
                  }}
                  className="simple-btn"
                  onClick={() => {
                    setSelectedIdx(idx);
                  }}
                  src={`/images/v4/family/${name.toLowerCase()}.gif`}
                  alt=""
                />
              );
            })
          }
        </VFlex>
      </ResponsiveFlex>
    </Container>
  );
});

export default The9CatFamily;
