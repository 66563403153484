import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import { Container, ContainerWithCritical } from './Container';
import { store } from '../mobx/store';
import { Flex, ResponsiveFlex, VFlex } from './Flex';
import { Body, SContentV4, STitleV4 } from './Typography';
import { widthStore } from '../mobx/width-store';
import i18nStore from '../localizations';
import { COLORS } from '../utils/constants';

interface NewAdventureProps {
}

const CONTENT_CONFIGS = [
  {
    title: '9Cat',
  },
  {
    title: '9Bean',
  },
  {
    title: '9Bun',
  },
  {
    title: '9Fishy',
  },
];

const ST1 = styled.div`
color: var(--lightgrey, #F5F5F5);
text-align: center;
font-family: Racing Sans One;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 2.4px;
`;

const NewAdventure = observer((props:NewAdventureProps) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const l = i18nStore.dict.mainPageV4;
  const h = widthStore.isAdventureDesktop ? 868 : 500;
  return (
    <ResponsiveFlex
      style={{alignSelf: 'stretch', gap: 50, maxWidth: '100vw'}}
      c={1290}
    >
      <Flex
        style={{
          position: 'relative',
          flex: 1,
          minHeight: h,
          height: h,
          width: !widthStore.isAdventureDesktop && '100vw',
        }}
      >
        {
          currentIdx === 0 ? (
            <Flex style={{flex: 1, justifyContent: 'flex-end', alignSelf: 'stretch'}}>
              <img
                style={{
                  flex: !widthStore.isAdventureDesktop && 1,
                  height: widthStore.isAdventureDesktop ? h : 'auto',
                  width: !widthStore.isAdventureDesktop && '100vw',
                  alignSelf: 'stretch',
                  transition: 'opacity: 0.3s',
                  objectFit: 'contain',
                }}
                src="/images/v4/adventure/map01bg.png"
                alt=""
              />
            </Flex>
          ) : (
            <>
              <img
                style={{
                  width: 'auto',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  transition: 'opacity: 0.3s',
                }}
                src="/images/v4/adventure/bg.png"
                alt=""
              />
              <img
                style={{
                  width: 'auto',
                  height: '28%',
                  position: 'absolute',
                  top: '46%',
                  right: 0,
                  transition: 'opacity: 0.3s',
                }}
                src="/images/v4/adventure/panda.png"
                alt=""
              />
              <img
                style={{
                  width: 'auto',
                  height: '28%',
                  position: 'absolute',
                  top: '23%',
                  right: '20%',
                  transition: 'opacity: 0.3s',
                }}
                src="/images/v4/adventure/fox.png"
                alt=""
              />
            </>
          )
        }
      </Flex>
      <Flex
        style={{
          flex: 1,
          position: 'relative',
          justifyContent: 'flex-start',
        }}
      >
        <VFlex style={{gap: 24, maxWidth: widthStore.isAdventureDesktop && 569, padding: '0px 36px 36px 36px'}}>
          <STitleV4>
            {l.newAdventureTitle}
          </STitleV4>
          <Flex style={{gap: 24}}>
            {
              [1, 2].map((v, idx) => {
                return (
                  <VFlex key={v} style={{gap: 8}}>
                    <VFlex
                      className="simple-btn"
                      style={{
                        width: 80,
                        height: 80,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 40,
                        backgroundColor: currentIdx !== idx ? '#494949' : COLORS.primary,
                      }}
                      onClick={() => setCurrentIdx(idx)}
                    >
                      <img
                        style={{
                          width: 80, height: 'auto',
                        }}
                        src={`/images/v4/adventure/map0${v}.png`}
                        alt=""
                      />
                    </VFlex>
                    <ST1>
                      {`MAP${idx + 1}`}
                    </ST1>
                  </VFlex>
                );
              })
            }
          </Flex>
          <SContentV4 style={{color: 'white'}}>
            {l.newAdventureContent}
          </SContentV4>
        </VFlex>
      </Flex>
    </ResponsiveFlex>
  );
});

export default NewAdventure;
