import React, {Component} from 'react';
import {observer} from 'mobx-react';

interface DropdownArrowImageProps {
  isShowingDropdown: Boolean;
}

const DropdownArrowImage = observer((props:DropdownArrowImageProps) => {
  const {isShowingDropdown} = props;
  return (
    <img
      style={{
        width: 12,
        height: 'auto',
        objectFit: 'contain',
        transition: 'all 0.3s',
        transform: `rotate(${isShowingDropdown ? '-' : ''}90deg) translate(1px, 0px)`,
      }}
      src={isShowingDropdown ? '/images/v3/right-arrow-purple.png' : '/images/v3/right-arrow-white.png'}
      alt=""
    />
  );
});

export default DropdownArrowImage;
