
import { constant } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled,
{ keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import Wobble from 'react-reveal/Wobble';
import { now } from 'mobx-utils';
import RSSParser from 'rss-parser';
import moment from 'moment';
import Marquee from 'react-fast-marquee';
import { Flex } from '../Flex';

const MarqueeText = styled.div`
font-family: Raleway;
font-style: italic;
font-weight: 900;
font-size: 36px;
line-height: 42px;
letter-spacing: -0.02em;

/* dark-black */

color: #050505;
`;

const TEXT = [
  '9CAT',
  '9CAT SAGA ADVENTURE',
].map(t => (
  <span
    style={{ margin: '0px 20px' }}
  >
    {t}
  </span>
));

type Props = {
  style: any;
  text: String;
};


export const NSeriesMarqueeText = (props: Props) => {
  const t = props.text || TEXT;
  return (
    <Flex
      style={{
        minWidth: '100vw',
        height: '60px',
        backgroundColor: '#7DE3CF',
        alignItems: 'center',
        ...props.style,
      }}
    >
      <Marquee
        gradient={false}
        speed={200}
      >
        <MarqueeText>
          {t}
          {t}
          {t}
        </MarqueeText>
      </Marquee>
    </Flex>
  );
};
