import { observer } from 'mobx-react';
import React from 'react';
import { store } from '../../mobx/store';
import ToastContainer from './ToastContainer';


export default observer(() => {
  return (
    <ToastContainer
      toasts={store.toasts}
      onRemove={(id: string) => {
        store.hideToast(id);
      }}
    />
  );
});
