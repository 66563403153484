import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import i18nStore from '../../localizations';
import { store } from '../../mobx/store';
import { VFlex } from '../Flex';
import { BodyHeader } from '../Typography';

interface LogoButtonProps {
  textKey: String;
  img: String;
  textExtraComponent: any;
  extraComponent: any;
  downloadURL:String;
}

export const LogoButton = observer((props:LogoButtonProps) => {
  const {downloadURL, textExtraComponent, extraComponent, textKey, img} = props;
  return (
    <VFlex
      style={{alignItems: 'center', width: store.isDesktop ? '20%' : '50%', marginBottom: '50px'}}
    >
      <a
        className="simple-btn"
        href={`#${textKey}`}
        // target="_blank"
        // rel="noreferrer"
        style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}
      >
        <img
          style={{height: 40, width: 'auto'}}
          src={img}
          alt=""
        />
        <BodyHeader style={{marginTop: 20, color: '#C4C4C4', fontSize: '15px', alignItems: 'center', display: 'flex'}}>
          {i18nStore.dict.mediaKitPage[textKey]}
          {textExtraComponent}
        </BodyHeader>
      </a>
      {extraComponent}
    </VFlex>
  );
});
