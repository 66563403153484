import React from 'react';
import styled from 'styled-components';
import { MediaQueryMobileOnly } from '../../utils/constants';

export const ShadowTextContainer = styled.div`
position: absolute;
z-index: -1;
`;

export const PartnerName = styled.h2`
/* Sub-Header */

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: 0.05em;
margin-top: 12px;

/* lightgrey */

color: #F5F5F5;

/* body-purple */

text-shadow: 2px 2px 0px #BF77E6;
`;

export const PartnerText = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
text-align: center;
letter-spacing: 0.02em;
color: #F5F5F5;
`;

export const PartnerTagText = styled.span`
position: absolute;
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
text-align: center;
letter-spacing: 0.05em;
/* background-shadow */
color: #1A1E1F;
transform: translate(-15px, -5px);
text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
${MediaQueryMobileOnly} {
  transform: translate(-8px, -4px);
  font-size: 16px;
}
`;

export const S60Racing = styled.div`
color: var(--lightgrey, #F5F5F5);
font-family: Racing Sans One;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 6px;
${MediaQueryMobileOnly} {
  font-size: 32px;
}
`;
