import { useWeb3React } from '@web3-react/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { store } from '../../mobx/store';
import { COLORS, NETWORK_CONFIG } from '../../utils/constants';
import { copyToClipboard } from '../../utils/utils';
import { Flex, VFlex } from '../Flex';
import { SpinnerContainer } from '../SpinnerContainer';
import { AlertText, SubText } from '../Typography';

type Props = {

}

export const InvitationLinkComponent = observer((props:Props) => {
  const {account} = useWeb3React();
  if (!account) return null;
  if (store.profile === undefined) {
    return (
      <SpinnerContainer
        style={{
          marginBottom: 50,
        }}
      >
        <Spinner animation="border" variant="light" />
      </SpinnerContainer>
    );
  }
  if (!store.profile || !store.profile.details.invitationCode) {
    return null;
  }
  if (store.profile) {
    const link = `https://9cat.io/mint?code=${store.profile.details.invitationCode}`;
    return (
      <React.Fragment>
        <VFlex
          style={{marginBottom: 10}}
        >
          <AlertText style={{color: COLORS.green1, marginBottom: 10}}>
            Here is your unique invitation as a 9Cat OG:
          </AlertText>
          <Flex>
            <SubText>
              {link}
            </SubText>
            <div style={{width: 30}} />
            <div
              style={{
                backgroundColor: COLORS.purple1,
                color: 'white',
                fontWeight: 'bold',
                padding: '5px 20px',
              }}
              className="simple-btn"
              onClick={() => {
                const result = copyToClipboard(link);
                if (result) {
                  store.showToast({
                    title: 'Invitation link is copied to your clipboard',
                    type: 'success',
                  });
                }
              }}
            >
              Copy
            </div>
          </Flex>
        </VFlex>
      </React.Fragment>
    );
  }
  return null;
});
