import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import Tada from 'react-reveal/Tada';
import Fade from 'react-reveal/Fade';
import Lottie from 'react-lottie';

import { Container } from './Container';
import { COLORS, NETWORK_CONFIG } from '../utils/constants';
import { Flex, VFlex } from './Flex';
import { MainTitle } from './Typography';
import ScaleWrapper from './ScaleWrapper';
import { store } from '../mobx/store';
import i18nStore from '../localizations';


const animationData = require('../lottie/TakeMeHome.json');

interface JoinDiscordBannerProps {
}

const defaultState = {
};

const DiscordBtnText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;
`;

const JoinOurDiscordBtn = styled.div`
display: flex;
position: 'absolute';
left: 80;
top: 190;
backgroundColor: #1A1E1F,
width: 263;
height: 67;
alignItems: center;
color: white;
`;

const JoinOurDiscordBtnHover = styled.a`
background-color: ${COLORS.darkBlack};
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;

position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
transition: opacity 0.2s ease-out;
-webkit-transition: opacity 0.2s ease-out;
-moz-transition: opacity 0.2s ease-out;
-o-transition: opacity 0.2s ease-out;
cursor: pointer;
-moz-user-select: -moz-none;
-khtml-user-select: none;
-webkit-user-select: none;
-o-user-select: none;
user-select: none;
opacity: 0;

color: ${COLORS.purple1};
&:hover {
  opacity: 1;
  color: ${COLORS.purple1};
}

`;

type _State = typeof defaultState;
interface State extends _State {
}

const JoinDiscordBanner = observer((props:JoinDiscordBannerProps) => {
  const l = i18nStore.dict.joinDiscordBanner;
  const joinDiscordBtn = (
    <JoinOurDiscordBtn
      style={{
        width: 263,
        minHeight: 68,
        backgroundColor: '#1A1E1F',
        alignItems: 'center',
      }}
    >
      <Flex
        style={{position: 'relative', width: '100%', height: '100%'}}
      >
        <img
          src="/images/v2/akar-icons_discord-fill-white.svg"
          style={{
            width: '24px',
            height: '24px',
            marginLeft: 26,
          }}
          alt=""
        />
        <DiscordBtnText
          style={{marginLeft: 24}}
        >
          {l.joinOurDiscord}
        </DiscordBtnText>
        <JoinOurDiscordBtnHover
          style={{
            minHeight: 68,
            transform: store.isDesktop ? 'translateY(-22px)' : null,
          }}
          href={NETWORK_CONFIG.bottomDiscord}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/images/v2/akar-icons_discord-fill.svg"
            style={{
              width: '24px',
              height: '24px',
              marginLeft: 26,
            }}
            alt=""
          />
          <DiscordBtnText
            style={{marginLeft: 23.9}}
          >
            {l.joinOurDiscord}
          </DiscordBtnText>
        </JoinOurDiscordBtnHover>
      </Flex>
    </JoinOurDiscordBtn>
  );
  if (store.screenSize.width < 600) {
    return (
      <VFlex
        style={{
          width: '100%',
          marginTop: 0,
          position: 'relative',
          alignItems: 'center',
          zIndex: 2,
        }}
      >
        <img
          style={{width: '100%', height: 'auto'}}
          src="/images/v2/getintowhitelist-bg-mobile.png"
          alt=""
        />
        <VFlex
          style={{
            zIndex: 20,
            position: 'absolute',
            top: 70,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
          }}
        >
          <Fade>
            <MainTitle
              style={{
                fontSize: 40,
                letterSpacing: '0.1em',
                textAlign: 'center',
              }}
            >
              {l.getLatestNews}
            </MainTitle>
          </Fade>
          <div
            style={{
              height: 23,
            }}
          />
          <Fade>
            <Flex
              style={{
                marginTop: 40,
                minHeight: 48,
                alignItems: 'stretch',
              }}
            >
              {joinDiscordBtn}
            </Flex>
          </Fade>
          <div
            style={{
              minWidth: 282,
              minHeight: 282,
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                rendererSettings: {

                },
                animationData,
              }}
              style={{
                marginTop: 30,
                width: 282,
                height: 282,
                cursor: 'default',
              }}
            />
          </div>
        </VFlex>
      </VFlex>
    );
  }
  const banner = (
    <Container
      style={{
        backgroundColor: COLORS.purple1,
        width: '100vw',
        height: 337,
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          mixBlendMode: 'color-burn',
          position: 'absolute',
        }}
        alt=""
        src="/images/v2/discord-banner-bg.png"
      />
      <Flex
        style={{
          width: '100%',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <VFlex
          style={{
            paddingLeft: 60,
          }}
        >
          <MainTitle
            style={{
              fontSize: store.screenSize.width < 1100 ? 40 : 60,
              letterSpacing: '-0.02em',
              marginBottom: 24,
            }}
          >
            {l.getLatestNews}
          </MainTitle>
          {joinDiscordBtn}
        </VFlex>
        <div style={{flex: 1}} />
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            rendererSettings: {
            },
            animationData,
          }}
          style={{
            maxWidth: 495,
            minWidth: 495,
            minHeight: 495,
            maxHeight: 495,
            marginRight: 100,
            cursor: 'default',
            zIndex: 1,
          }}
        />
      </Flex>
    </Container>
  );
  return banner;
});

export default JoinDiscordBanner;
