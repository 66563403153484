import styled from 'styled-components';
import { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import { MAX_DESKTOP_WIDTH, getLtMediaQuery } from '../utils/constants';

import { VFlex } from './Flex';
import { store } from '../mobx/store';


export const Container = styled(VFlex)`
max-width: ${props => props.c || MAX_DESKTOP_WIDTH}px;
width: 100%;
position: relative;
`;

export const ContainerWithPadding = styled(Container)`
width: 90%;
`;

export const ContainerWithCritical = observer(styled(Container)`
${props => getLtMediaQuery((props.c || MAX_DESKTOP_WIDTH) + (store.isDesktop ? 60 : 20))} {
  padding: ${props => (store.isDesktop ? '0px 56px' : '0px 16px')};
}
`);

export const SPageContainer = styled(VFlex)`
min-height: 100vh;
width: 100vw;
position: relative;
padding-top: 60px;
`;

export const H = ({h, style}:{h:number, style: CSSProperties}) => {
  return (
    <div style={{height: h, ...style}} />
  );
};
