import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { IconMainButton } from './MainButton';
import { NETWORK_CONFIG } from '../utils/constants';
import { store } from '../mobx/store';
import i18nStore from '../localizations';

interface ViewOpenseaBtnProps {
}

const ViewOpenseaBtn = observer((props:ViewOpenseaBtnProps) => {
  return (
    <a
      href={`${NETWORK_CONFIG.openseaRoot}/${store.web3Ctx?.account}`}
      target="_blank"
      rel="noreferrer"
    >
      <IconMainButton
        icon="/images/v2/opensea-colored.png"
        text={i18nStore.dict.mintPage.viewOnOpenSea}
      />
    </a>
  );
});

export default ViewOpenseaBtn;
