import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { COLORS, WIDTH_THRESHOLD } from '../utils/constants';
import ButtonSpinner from './ButtonSpinner';
import { Flex, VFlex } from './Flex';

type Props = {
  onClick: (evt: any) => void,
  disabled: Boolean,
  isLoading: Boolean,
  children: any,
  hoverClassName: String;
  spinnerProps: {style:any};
}


const StyledButton = styled.div`
position: relative;
background-color: #D585FF;
display: flex;
align-items: center;
justify-content: center;
width: 271px;
height: 48px;
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;

/* Light / White */

color: #000;

&.disabled {
  background-color: ${COLORS.grey1};
}

`;

const StyledBorderButton = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 271px;
height: 48px;
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;
border: 1px solid #F5F5F5;
color: white;
`;


export const MainButtonProvider = observer((props:Props) => {
  const {isLoading, Component, style, onMouseEnter, onMouseLeave, disabled, spinnerProps, ...p} = props;
  const [isHovering, setIsHovering] = useState();
  let className = (isHovering && props.hoverClassName && props.hoverClassName) || 'simple-btn';
  if (disabled) {
    className = `simple-btn ${className} disabled`;
  }
  if (p.className) {
    className = `simple-btn ${className} ${p.className}`;
  }
  return (
    <Component
      onMouseEnter={() => {
        setIsHovering(true);
        onMouseEnter && onMouseEnter();
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        onMouseLeave && onMouseLeave();
      }}
      disabled={disabled}
      {...p}
      style={{
        opacity: disabled ? 0.5 : undefined,
        cursor: disabled ? 'unset' : 'pointer',
        ...style,
      }}
      className={className}
      type="button"
    >
      {
        isLoading ? (
          <ButtonSpinner style={{borderColor: 'black'}} {...spinnerProps} />
        ) : props.children
      }
    </Component>
  );
});

export const MainButton = observer((props:Props) => {
  return (
    <MainButtonProvider Component={StyledButton} {...props} />
  );
});

export const BorderButton = observer((props:Props) => {
  return (
    <MainButtonProvider Component={StyledBorderButton} {...props} />
  );
});


type IconMainButtonProps = {
  onClick: (evt: any) => void,
  icon: string,
  text: String,
  iconStyle: any,
  disabled: Boolean,
}

export const IconMainButton = observer((props:IconMainButtonProps) => {
  return (
    <MainButton
      {...props}
    >
      <Flex
        style={{alignItems: 'center', marginRight: 6}}
      >
        <img
          src={props.icon}
          alt=""
          style={{
            width: 24,
            height: 'auto',
            marginRight: 20,
            ...props.iconStyle,
          }}
        />
        <span>
          {props.text || props.children}
        </span>
      </Flex>
    </MainButton>
  );
});

export const ShadowButton = styled(Flex)`
background-color: ${COLORS.green2};
box-shadow: 8px 8px 0px #292F33;
font-family: Mulish;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;

/* dark-black */

color: #050505;
height: 54.4px;
width: 197px;
justify-content: center;
margin-top: 80px;
`;
