import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import { ResponsiveFlex, VFlex } from './Flex';
import { ContainerWithCritical, H } from './Container';
import { SContentV4, STitleV4 } from './Typography';
import { MAX_DESKTOP_WIDTH } from '../utils/constants';
import { store } from '../mobx/store';
import i18nStore from '../localizations';
import Video from './Video';

interface InfoSectionV4Props {
}

const SBGSpot = styled.div`
width: 80%;
aspect-ratio: 1/1;
border-radius: 995px;
background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(88, 193, 217, 0.50) 0%, rgba(88, 193, 217, 0.00) 100%);
opacity: 0.5;
`;

const InfoSectionV4 = observer((props:InfoSectionV4Props) => {
  const l = i18nStore.dict.mainPageV4;
  return (
    <VFlex
      style={{width: '100%', backgroundColor: 'white', position: 'relative', alignItems: 'center', overflow: 'hidden'}}
    >
      <ContainerWithCritical>
        <SBGSpot style={{position: 'absolute', right: '-50%', top: '-50%'}} />
        <SBGSpot style={{position: 'absolute', left: '-50%', bottom: '-50%'}} />
        <ResponsiveFlex
          style={{marginTop: 53, gap: 32, justifyContent: 'space-evenly', alignItems: 'center'}}
        >
          <VFlex
            style={{flex: 1, position: 'relative', aspectRatio: '1.2 / 1', width: !store.isDesktop && '100vw'}}
          >
            <Video
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src="/images/v4/9cat-feature.mp4"
            />
          </VFlex>
          <VFlex style={{flex: 1, gap: 24, maxWidth: 560}}>
            <STitleV4>
              {l.infoTitle1}
            </STitleV4>
            <SContentV4>
              {l.infoContent1}
            </SContentV4>
          </VFlex>
        </ResponsiveFlex>
        <ResponsiveFlex style={{marginTop: 33, gap: 32, justifyContent: 'space-evenly', alignItems: 'center', flexDirection: !store.isDesktop && 'column-reverse'}}>
          <VFlex style={{flex: 1, gap: 24, maxWidth: 560}}>
            <STitleV4>
              {l.infoTitle2}
            </STitleV4>
            <SContentV4>
              {l.infoContent2}
            </SContentV4>
          </VFlex>
          <div style={{flex: 1}}>
            <img
              style={{
                height: 'auto',
                width: '100%',
                objectFit: 'cover',
                userSelect: 'none',
              // maxWidth: 688,
              }}
              src="/images/v4/info.gif"
              alt=""
            />
          </div>
        </ResponsiveFlex>
      </ContainerWithCritical>
      <H h={80} />
    </VFlex>
  );
});

export default InfoSectionV4;
