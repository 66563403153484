import React, {Component} from 'react';
import {observer} from 'mobx-react';
import i18nStore from '../../localizations';
import { Flex, VFlex } from '../Flex';
import { Body, SubTitle } from '../Typography';
import { BorderButton } from '../MainButton';
import { store } from '../../mobx/store';

interface MKRowProps {
  titleKey: String;
  descriptionKey: String;
  imgComponent: any;
  bottomComponent: any;
  size: Number;
  href: String;
  id: String;
}

const MKRow = observer((props:MKRowProps) => {
  const {id, titleKey, descriptionKey, imgComponent, size, bottomComponent, href} = props;
  const l = i18nStore.dict.mediaKitPage;
  return (
    <VFlex style={{gap: 24, alignItems: !store.isDesktop && 'center', width: '90vw', maxWidth: 1200}}>
      <div id={titleKey} style={{transform: 'translateY(-100px)'}} />
      <SubTitle style={{alignSelf: 'flex-start'}}>
        {l[titleKey]}
      </SubTitle>
      <Flex style={{flexWrap: 'wrap', width: '100%', gap: 32, justifyContent: !store.isDesktop && 'center'}}>
        <Body style={{maxWidth: size && 500}}>
          {l[descriptionKey]}
        </Body>
        {imgComponent}
      </Flex>
      {
        href && (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            <BorderButton>
              {`${l.downloadAll}`}
            </BorderButton>
          </a>
        )
      }
      {bottomComponent}
    </VFlex>
  );
});

export default MKRow;
