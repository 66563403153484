import { observer } from 'mobx-react-lite';
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Flex, VFlex } from '../Flex';
import { MainTitle } from '../Typography';
import { NSeriesMarqueeText } from './NSeriesMarqueeText';
import { COLORS, MediaQueryMobileOnly } from '../../utils/constants';
import ScaleWrapper from '../ScaleWrapper';
import { useIsDesktop } from '../../utils/utils';
import i18nStore from '../../localizations';

type Props = {};

const Background = styled.div`
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
background: linear-gradient(180deg, #F5F5F5 0%, #6FD1FF 87.5%, #45C0F9 100%);
`;

const RoadMapItemText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
/* or 129% */

display: flex;
align-items: center;
letter-spacing: 0.02em;

/* background-shadow */
margin-left: 12px;
color: #1A1E1F;
max-width: 239px;

${MediaQueryMobileOnly} {
  max-width: 1000px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  /* lightgrey */

  color: #F5F5F5;
}

`;

const DateText = styled.div`
/* Sub-Header */

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 33px;
letter-spacing: 0.05em;

/* background-shadow */

color: #1A1E1F;

${MediaQueryMobileOnly} {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.05em;

  /* lightgrey */

  color: #F5F5F5;
}
`;

const Dot = styled.div`
width: 29px;
height: 29px;
/* Grey#3 */

background: #7A7A7A;
/* background */

border: 8px solid #2E3435;
box-sizing: border-box;
border-radius: 100px;
`;

const ToTheMoonText = styled.div`
/* Body-Header */

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;

/* primary-shadow */

color: #BF77E6;

transform: translate(-20px,500px) rotateZ(-90deg);
position: absolute;
width: 167px;
`;

const BOTTOM = 39;

const data = [
  {
    q: 1,
    y: 23,
    dateTitle: 'Q1 2023',
    items: [
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
    ],
    bottom: 0,
  },
  {
    q: 2,
    y: 23,
    dateTitle: 'Q2 2023',
    items: [
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'milk',
      },
      {
        icon: 'gamepad',
      },
    ],
    bottom: BOTTOM,
  },
  {
    q: 3,
    y: 23,
    dateTitle: 'Q3 2023',
    items: [
      {
        icon: 'star',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'coin',
      },
    ],
    bottom: BOTTOM * 2,
  },
  {
    q: 4,
    y: 23,
    dateTitle: 'Q4 2023',
    items: [
      {
        icon: 'milk',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'coin',
      },
      {
        icon: 'gamepad',
      },
      {
        icon: 'gamepad',
      },
    ],
    bottom: BOTTOM * 3,
  },
];


const RoadMapItem = observer((props:{text:String, icon: string, hasLine: Boolean, q: Number, y: Number, idx: Number}) => {
  const {icon, q, y, idx} = props;
  const isDesktop = useIsDesktop();
  const l = i18nStore.dict.roadMapV3;
  return (
    <Flex
      style={{
        marginRight: 8,
        marginBottom: 18,
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <img
        style={{
          width: 21,
          height: 21,
        }}
        src={`/images/v3/${icon}${isDesktop ? '' : '-white'}.svg`}
        alt=""
      />
      <RoadMapItemText>
        {l[`roadMap${y}Q${q}_${idx + 1}`]}
      </RoadMapItemText>
    </Flex>
  );
});

export const RoadMapV3 = observer((props: Props) => {
  const isDesktop = useIsDesktop();
  const l = i18nStore.dict.roadMapV3;
  return (
    <VFlex
      style={{
        position: 'relative',
        width: '100vw',
      }}
    >
      <div id="roadmap" style={{position: 'absolute', transform: 'translateY(-100px)'}} />
      <VFlex
        style={{
          zIndex: 1,
        }}
      >
        {
          isDesktop ? (
            <ScaleWrapper
              maxWidth={1418}
              height={700}
              style={{flexDirection: 'column'}}
            >
              <MainTitle
                style={{
                  alignSelf: 'flex-start',
                  padding: isDesktop ? '64px 54px' : '32px 0px 0px 22px',
                  color: COLORS.darkBlack,
                  fontSize: isDesktop ? 120 : 40,
                  fontFamily: 'Racing Sans One',
                }}
              >
                {l.title}
              </MainTitle>
              <Flex
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  margin: '110px 0px 100px 0px',
                }}
              >
                {
                  data.map((d1, idx) => {
                    return (
                      <Fade>
                        <VFlex
                          style={{
                            position: 'relative',
                          }}
                        >
                          {
                            d1.items.map((item, idx2) => {
                              return (
                                <RoadMapItem
                                  {...item}
                                  q={d1.q}
                                  y={d1.y}
                                  idx={idx2}
                                />
                              );
                            })
                          }
                          <div
                            style={{
                              marginTop: 37,
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <Dot style={{justifyContent: 'center', alignItems: 'center', zIndex: 10}} />
                            {
                              (idx === 0) ? (
                                <div
                                  style={{
                                    width: 1190,
                                    position: 'absolute',
                                    left: 0,
                                    height: 3,
                                    backgroundColor: '#505A5C',
                                    transformOrigin: 'bottom left',
                                    transform: 'translate(15px,-15px) rotateZ(-8deg)',
                                    zIndex: -1,
                                  }}
                                />
                              ) : null
                            }
                          </div>
                          <DateText
                            style={{
                              marginTop: 16,
                              marginBottom: d1.bottom,
                            }}
                          >
                            {`Q${d1.q} 20${d1.y}`}
                          </DateText>
                        </VFlex>
                      </Fade>
                    );
                  })
                }
                <VFlex
                  style={{
                    position: 'relative',
                    marginLeft: 40,
                    alignItems: 'center',
                    transform: 'translateY(-212px)',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <ToTheMoonText>
                    {l.toTheMoonText}
                  </ToTheMoonText>
                  <div className="arrow-up" />
                  <div
                    style={{width: 6, height: 325, backgroundColor: '#000000'}}
                  />
                  <Dot style={{justifyContent: 'center', alignItems: 'center', zIndex: 10}} />
                </VFlex>
              </Flex>
            </ScaleWrapper>
          ) : (
            <VFlex>
              <MainTitle
                style={{
                  alignSelf: 'flex-start',
                  padding: isDesktop ? '64px 54px' : '32px 0px 0px 22px',
                  color: COLORS.darkBlack,
                  fontSize: isDesktop ? 120 : 40,
                  fontFamily: 'Racing Sans One',
                }}
              >
                {l.title}
              </MainTitle>
              {
                data.map((d) => {
                  return (
                    <Flex
                      style={{
                        marginLeft: 11,
                        alignItems: 'flex-start',
                      }}
                    >
                      <Flex
                        style={{
                          alignItems: 'flex-start',
                          marginTop: 12,
                          marginLeft: 11,
                          paddingBottom: 24,
                          flex: 1,
                        }}
                      >
                        <div className="chatbox-triangle" />
                        <VFlex
                          style={{
                            backgroundColor: '#1A1E1F',
                            padding: '16px 41px',
                            marginRight: 22,
                            flex: 1,
                          }}
                        >
                          <DateText>
                            {d.dateTitle}
                          </DateText>
                          <div style={{height: 18}} />
                          {
                            d.items.map((item, idx) => {
                              return (
                                <RoadMapItem
                                  {...item}
                                  q={d.q}
                                  y={d.y}
                                  idx={idx}
                                />
                              );
                            })
                          }
                        </VFlex>
                      </Flex>
                    </Flex>
                  );
                })
              }
            </VFlex>
          )
        }
      </VFlex>
      <Background />
      <NSeriesMarqueeText
        style={{
          backgroundColor: COLORS.purple2,
        }}
      />
    </VFlex>
  );
});
