import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Flex, VFlex } from '../Flex';
import { MainTitle } from '../Typography';
import ScaleWrapper from '../ScaleWrapper';
import { useIsDesktop } from '../../utils/utils';
import RotationCat from './RotationCat';
import i18nStore from '../../localizations';
import { GRID_4_CLASS_NAME } from '../../utils/constants';


const OtherSupporters = [
  // {
  //   src: '/images/partners/hkba_logo.png',
  //   link: 'https://hkba.club',
  // },
  {
    src: '/images/partners/geps.jpg',
    link: 'https://geps.hk/',
  },
  {
    src: '/images/partners/logo-cyberport.png',
    link: 'https://cyberport.hk/en/',
  },
  {
    src: '/images/partners/hkba_logo.png',
    link: 'https://hkba.club/',
  },
  {
    src: '/images/partners/XPwy4YlfReWCFy9FRzql_7yd6oa03Udz2OJ2r.png',
    link: 'https://linktr.ee/w3projecthub',
  },
  // {
  //   src: '/images/v3/logo-metaversepets.png',
  //   link: 'https://metaversepets-nft.com/',
  // },
  {
    src: '/images/partners/catheon_gaming.png',
    link: 'https://catheongaming.com/',
  },
  {
    src: '/images/partners/milways_logo_white.png',
    link: 'https://milways.io/',
  },
  {
    src: '/images/partners/Day Karkal Jewelry.png',
    link: 'https://daykarkal.com/',
  },
  {
    src: '/images/partners/chord_hero.webp',
    link: 'https://chordhero.com/',
  },
  {
    src: '/images/partners/BigY_logo.png',
    link: 'https://medium.com/@9metaverse/bigycomic-welcome-to-the-9metaverse-453b4e10a88a',
  },
  // {
  //   src: '/images/partners/goga_ai.png',
  //   link: 'https://goga.ai/',
  // },
  // {
  //   src: '/images/partners/MetaOps.jpg',
  //   link: 'https://metaopsgaming.com/',
  // },
  // {
  //   src: '/images/partners/tikka_from_the_east.png',
  //   link: 'https://www.instagram.com/tikkafromeast/',
  // },
  // {
  //   src: '/images/partners/yoomoota.svg',
  //   link: 'https://yoomoota.io/',
  // },
  // {
  //   src: '/images/partners/Zombiees.png',
  //   link: 'https://www.zombiees.io/',
  // },
  // {
  //   src: '/images/partners/linx_games.svg',
  //   link: 'https://linx.games/',
  // },
  // {
  //   src: '/images/partners/ReverseDao.png',
  //   link: 'https://www.reversedao.io/',
  // },
];

const CONTAINER_STYLE = {
  width: '80vw',
  maxWidth: 900,
  alignSelf: 'center',
  justifyContent: 'center',
};

export const InvestorAndPartner = observer(() => {
  const isDesktop = useIsDesktop();
  const l = i18nStore.dict.investorAndPartner;
  return (
    <VFlex
      style={{
        alignItems: 'center',
        position: 'relative',
      }}
      id="partners"
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          // background: 'linear-gradient(180deg, #000000 0%, #292E30 87.5%, #3B494D 100%)',
          zIndex: -1,
        }}
      />
      <MainTitle style={{ textAlign: 'center', marginTop: 20, marginBottom: 50 }}>
        {l.sectionTitle}
      </MainTitle>
      <VFlex
        style={{ alignItems: 'center', gap: 53 }}
      >
        {/* <Flex
          style={{ ...CONTAINER_STYLE, gap: 48 }}
        >
          <a
            href="https://milways.io/"
            className="simple-btn"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{
                width: 'auto', height: 300,
              }}
              src="/images/partners/milways_logo_white.png"
              alt=""
            />
          </a>
          <a
            href="https://daykarkal.com/"
            className="simple-btn"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: 'auto', height: 170 }}
              src="/images/partners/Day Karkal Jewelry.png"
              alt=""
            />
          </a>
        </Flex>
        <Flex
          style={{ ...CONTAINER_STYLE, gap: 48 }}
        >
          <a
            href="https://hkba.club/"
            className="simple-btn"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{
                width: 'auto', height: 93,
              }}
              src="/images/partners/hkba_logo.png"
              alt=""
            />
          </a>
          <a
            href="https://cyberport.hk/en/"
            className="simple-btn"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: 'auto', height: 120 }}
              src="/images/partners/logo-cyberport.png"
              alt=""
            />
          </a>
        </Flex> */}
        <div
          style={{ ...CONTAINER_STYLE, marginBottom: 96 }}
          className={GRID_4_CLASS_NAME}
        >
          {
            OtherSupporters.map((s) => {
              return (
                <VFlex
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
                >
                  <a
                    href={s.link}
                    className="simple-btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{
                        width: '100%', height: '100%', objectFit: 'contain',
                      }}
                      src={s.src}
                      alt=""
                    />
                  </a>
                </VFlex>
              );
            })
          }
        </div>
      </VFlex>
      {
        !isDesktop ? (
          <RotationCat
            style={{
              marginBottom: 50,
              transform: 'scale(1.3)',
            }}
            darkTheme
          />
        ) : null
      }
    </VFlex>
  );
});
