import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Container } from '../Container';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { headers } from '../Header';
import { DiscordButton, IGButton, MediumButton, NFTCalendarButton, OpenSeaButton, TGButton, ThreadsButton, TwitterButton, YoutubeButton } from '../SocialMediaButton';
import { store } from '../../mobx/store';
import { COLORS, NETWORK_CONFIG } from '../../utils/constants';
import { useIsDesktop } from '../../utils/utils';
import i18nStore, { LANGUAGES, localizations } from '../../localizations';
import Dropdown from './Dropdown';

interface FooterV2Props {
}

const defaultState = {
};

type _State = typeof defaultState;
interface State extends _State {
}

export const FooterTextButton = styled.div`
font-family: Mulish;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 0px;
overflow: visible;
letter-spacing: 0.2px;
white-space: nowrap;
/* primary */

color: #ABABAB;
transition: opacity 0.3s;
&:hover {
  color: #ABABAB;
  opacity: 0.7;
}
&:active {
  color: #ABABAB;
  opacity: 0.4;
}
`;

const CopyrightText = styled.span`
font-family: Mulish;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.2px;

/* Light / Gray */

color: #464848;

`;

const JoinOurComunityText = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
text-align: center;

/* lightgrey */

color: #F5F5F5;
`;

const footers = [
  [
    // {
    //   name: 'lightpaper',
    //   href: NETWORK_CONFIG.lightpaper,
    //   target: '_blank',
    // },
    {
      name: 'latestNews',
      href: '/#latest-news',
    },
    // {
    //   name: 'roadmap',
    //   href: '/#roadmap',
    // },
    {
      name: 'team',
      href: '/#team',
    },
  ],
  // [
  // {
  //   name: 'nMetaverse',
  //   href: '/#9metaverse',
  // },
  // {
  //   name: 'game',
  //   href: '/#9cat_saga_adventure',
  // },
  // {
  //   name: 'nCatNFTs',
  //   href: '/#nft',
  // },
  // {
  //   name: 'Tokens',
  //   href: '/#token_info',
  // },
  // ],
  [
    // {
    //   name: 'faq',
    //   href: '/#faq',
    // },
    {
      name: 'privacyPolicy',
      href: '/privacy',
    },
    // {
    //   name: 'contactUs',
    //   href: NETWORK_CONFIG.discord,
    //   target: '_blank',
    // },
    {
      name: 'merch',
      href: 'https://merch.9cat.io/',
      target: '_blank',
    },
  ],
  // {
  //   name: 'Light Paper',
  //   href: 'https://metaverse-9.gitbook.io/9metaverse-lightpaper/',
  //   target: '_blank',
  // },
  // {
  //   name: 'Sale Info',
  //   href: '/#sale-info',
  // },
  // {
  //   name: 'Utility',
  //   href: '/#utility',
  // },
  // {
  //   name: '9Milk',
  //   href: '/#9Milk',
  // },
  // {
  //   name: 'About Us',
  //   href: '/#about-us',
  // },
];

const LanguageOptions = LANGUAGES.map((k) => {
  return {
    label: localizations.lang[k],
    value: k,
  };
});

const FooterV3 = observer((props: FooterV2Props) => {
  const isDesktop = store.screenSize.width > 980;
  const l = i18nStore.dict.footer;
  return (
    <VFlex
      style={{ backgroundColor: COLORS.darkBlack, alignSelf: 'stretch', alignItems: 'center' }}
    >
      <Container
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '276px',
          // paddingTop: store.isDesktop ? 50 : 30,
          paddingTop: 50,
        }}
      >
        <ResponsiveFlex
          style={{
            width: '100%',
            padding: '0px 30px',
            maxWidth: 1147,
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: isDesktop ? undefined : 'center',
          }}
        >
          <VFlex
            style={{ alignItems: store.isDesktop ? 'flex-start' : 'center' }}
          >
            <Dropdown
              options={LanguageOptions}
              onSelected={(selection) => {
                i18nStore.setLanguage(selection.value);
              }}
              value={i18nStore.language}
              containerStyle={{ width: 140 }}
              extraComponents={(
                <img
                  src="/images/v3/globe-icon.svg"
                  style={{
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    left: 16,
                  }}
                  alt=""
                />
              )}
            />
            <VFlex
              style={{ alignItems: 'center' }}
              className="simple-btn"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                });
              }}
            >
              <img
                src="/images/Logo_300x100.png"
                alt=""
                style={{ width: 196, height: 'auto', marginTop: isDesktop ? 26 : 4 }}
              />
              <CopyrightText
                style={{ marginTop: 15 }}
              >
                © Daytech Limited. All rights reserved.
              </CopyrightText>
            </VFlex>
          </VFlex>
          <div style={{ width: 50, height: 30 }} />
          <Flex
            style={{
              alignItems: 'flex-start',
            }}
          >
            {
              footers.map((f2) => {
                return (
                  <VFlex>
                    {
                      f2.map((h) => {
                        return (
                          <a
                            href={h.href}
                            target={h.target}
                          >
                            <FooterTextButton
                              style={{
                                margin: store.isDesktop ? 22 : '30px 15px',
                              }}
                            >
                              {l[h.name]}
                            </FooterTextButton>
                          </a>
                        );
                      })
                    }
                  </VFlex>
                );
              })
            }
          </Flex>
          {
            isDesktop ? (
              <div style={{ flex: 1 }} />
            ) : (
              <div style={{ width: 50, height: 30 }} />
            )
          }
          <VFlex>
            <JoinOurComunityText>
              {l.joinOurCommunity}
            </JoinOurComunityText>
            <Flex
              style={{
                marginTop: 20,
                gap: 25,
              }}
            >
              <IGButton size={24} />
              <DiscordButton size={24} />
              <ThreadsButton size={24} />
              <YoutubeButton size={24} />
              <TGButton size={24} />
              <TwitterButton size={24} />
              <MediumButton size={24} />
            </Flex>
          </VFlex>
        </ResponsiveFlex>
        <div style={{ height: 100 }} />
      </Container>
    </VFlex>
  );
});

export default FooterV3;
