import { NETWORK_CONFIG } from '../utils/constants';

type SocialMediaButtonProps = {
  src: String,
  href: String,
  size: number,
  imgStyle: any,
};

export const SocialMediaButton = ({ href, src, size, imgStyle }: SocialMediaButtonProps) => {
  return (
    <a
      className="simple-btn"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {
        typeof src === 'string' ? (
          <img
            style={{
              height: size || 45,
              width: size || 45,
              objectFit: 'contain',
              ...imgStyle,
            }}
            src={src}
            alt=""
          />
        ) : src
      }
    </a>
  );
};

export const MediumButton = ({ isBlue, size, top, imgStyle }) => {
  return (
    <SocialMediaButton
      size={size}
      href={NETWORK_CONFIG.medium}
      src="/images/v2/medium-logo.svg"
    />
  );
};

export const TwitterButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={top ? NETWORK_CONFIG.topTwitter : NETWORK_CONFIG.bottomTwitter}
      src="/images/v2/akar-icons_twitter-fill.svg"
    />
  );
};

export const NFTCalendarButton = ({ size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href="https://nftcalendar.io/event/9cat-nft-fan-art-competition-with-a-share-of-a-1500-usdt-prize-pool-ethan/"
      src="/images/v2/nft_calendar_logo_vector.svg"
    />
  );
};

export const DiscordButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={top ? NETWORK_CONFIG.topDiscord : NETWORK_CONFIG.bottomDiscord}
      src="/images/v2/akar-icons_discord-fill.svg"
    />
  );
};

export const IGButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={NETWORK_CONFIG.instagram}
      src="/images/v2/akar-icons_instagram-fill.svg"
    />
  );
};


export const TGButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={NETWORK_CONFIG.telegram}
      src="/images/telegram.svg"
    />
  );
};

export const OpenSeaButton = ({ isBlue, size, top }) => {
  return (
    <a
      className="simple-btn"
      href={NETWORK_CONFIG.opensea}
      target="_blank"
      rel="noreferrer"
      style={{
        height: size || 45,
        width: size || 45,
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <img
        style={{
          height: size || 65,
          width: size || 65,
        }}
        src="/images/v2/opensea.svg"
        alt=""
      />
    </a>
  );
};

export const YoutubeButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={NETWORK_CONFIG.youtube}
      src="/images/youtube.svg"
    />
  );
};

export const ThreadsButton = ({ isBlue, size, top }) => {
  return (
    <SocialMediaButton
      size={size}
      href={NETWORK_CONFIG.threads}
      src="/images/threads.svg"
    />
  );
};
