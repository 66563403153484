import React, {Component, CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { Flex } from '../Flex';

interface InAppStickerPlatformsComponentProps {
  style:CSSProperties,
}

const stickerConfigs = [
  {img: 'icon-whatsapp.png'},
  {img: 'icon-tg.png'},
  {img: 'icon-signal.png'},
  {img: 'icon-line.png'},
];

const InAppStickerPlatformsComponent = observer((props:InAppStickerPlatformsComponentProps) => {
  const {style} = props;
  return (
    <Flex style={{justifyContent: 'space-between', maxWidth: 120, width: '90%', ...style}}>
      {
        stickerConfigs.map(({img}) => {
          return (
            <img
              key={img}
              style={{width: 24, height: 'auto'}}
              src={`/images/v3/${img}`}
              alt=""
            />
          );
        })
      }
    </Flex>
  );
});

export default InAppStickerPlatformsComponent;
