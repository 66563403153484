import { store } from './store';

class WidthStore {
  get isAdventureDesktop() {
    return store.screenSize.width > 1290;
  }

  get isFamilyDesktop() {
    return store.screenSize.width > 1200;
  }

  get isPlayNowDesktop() {
    return store.screenSize.width > 1000;
  }
}

export const widthStore = new WidthStore();
