import { NETWORK_CONFIG } from '../utils/constants';

interface APIResponse<T> {
  status: number,
  body: T,
}

type APIInput = { uri: string, method: string, headers: any, body: any, isForm?: boolean };

type APIResAsync<T> = Promise<APIResponse<T>>;

export async function api({ uri, headers = {}, body, method, isForm }: APIInput) {
  // // console.log(getFirebaseToken());
  // let authorization = await firebase.auth().currentUser.getIdToken();
  const combinedHeaders = Object.assign(headers, {});
  let inputBody = body;
  const finalUri = uri.startsWith('http') ? uri : (NETWORK_CONFIG.baseURL + uri);
  if (!isForm) {
    inputBody = JSON.stringify(body);
    if (body != null && body instanceof Object) {
      inputBody = JSON.stringify(body);
    }
    combinedHeaders['Content-Type'] = 'application/json';
  } else {
    // combinedHeaders['Content-Type'] = 'multipart/form-data';
  }
  // console.log('init fetch');
  // console.log(finalUri);
  // console.log(inputBody);
  // console.log(combinedHeaders);
  const res = await fetch(finalUri, {
    headers: combinedHeaders,
    body: inputBody,
    method,
  });
  let returnBody;
  // console.log(res);
  try {
    returnBody = await res.json();
    // console.log(returnBody);
    if (res.status > 200) {
      return Promise.reject(returnBody);
    }
  } catch (e) {
    if (res.status > 200) {
      return Promise.reject(e);
    }
  }
  return {
    status: res.status,
    body: returnBody,
  };
}

export function get<T>(obj: { uri: string, headers?: any, body?: any, method?: string }): APIResAsync<T> {
  // Object.assign(obj, {method: 'GET'});
  obj.method = 'GET';
  return api(obj);
}

export function post<T>(obj: { uri: string, headers?: any, body?: any, method?: string, isForm?: boolean }): APIResAsync<T> {
  obj.method = 'POST';
  // console.log('post');
  // console.log(obj);
  return api(obj);
}

export function DELETE<T>(obj: { uri: string, headers?: any, body?: any, method?: string, isForm?: boolean }): APIResAsync<T> {
  obj.method = 'DELETE';
  // console.log('post');
  // console.log(obj);
  return api(obj);
}
