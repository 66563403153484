import { observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { VFlex, Flex } from './Flex';
import {store} from '../mobx/store';
import { headers } from './Header';
import { FooterTextButton } from './FooterV2';
import { COLORS } from '../utils/constants';
import i18nStore from '../localizations';
import DropdownArrowImage from './DropdownArrowImage';

export const HamburgerOverlay = observer(() => {
  const [dropdownStates, setDropdownStates] = useState([]);
  const history = useHistory();
  const onLinkClick = (evt, obj) => {
    console.log('on link click');
    if (obj.target || !obj.href) return;
    evt.preventDefault();
    if (location.pathname !== obj.href) {
      history.push(obj.href);
      if (!obj.hash) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    if (obj.hash) {
      location.hash = obj.hash;
    }
  };
  return (
    <CSSTransition
      in={store.isShowOverlay}
      timeout={300}
      classNames="page"
      unmountOnExit
    >
      <div
        classNames="page"
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        <VFlex
          style={{
            width: '100%',
            backgroundColor: COLORS.grey3,
            alignItems: 'center',
            paddingBottom: 30,
          }}
        >
          <Flex
            style={{
              justifyContent: 'space-between',
              padding: '0px 20px',
              alignItems: 'center',
              height: store.headerHeight,
              alignSelf: 'stretch',
            }}
          >
            <img
              src="/images/Logo_300x100.png"
              alt=""
              style={{width: 129, height: 'auto'}}
              className="simple-btn"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                store.isShowOverlay = false;
              }}
            />
            <img
              src="/images/v2/close.svg"
              alt=""
              style={{width: 20, height: 20}}
              className="simple-btn"
              onClick={() => {
                store.isShowOverlay = false;
              }}
            />
          </Flex>
          {
            headers.map((h, idx) => {
              return (
                <React.Fragment>
                  <FooterTextButton
                    style={{
                      margin: 12,
                    }}
                    href={h.href}
                    target={h.target}
                    onClick={(evt) => {
                      if (h.dropdown) {
                        const ns = dropdownStates.slice(0);
                        ns[idx] = !ns[idx];
                        setDropdownStates(ns);
                        return;
                      }
                      onLinkClick(evt, h);
                      store.isShowOverlay = false;
                    }}
                  >
                    {i18nStore.dict.headers[h.name]}
                    {
                      h.dropdown && (
                        <React.Fragment>
                          <div style={{width: 10}} />
                          <DropdownArrowImage
                            isShowingDropdown={dropdownStates[idx]}
                          />
                        </React.Fragment>
                      )
                    }
                  </FooterTextButton>
                  {
                    h.dropdown && dropdownStates[idx] && (
                      <React.Fragment>
                        {h.dropdown.map((d) => {
                          return (
                            <FooterTextButton
                              style={{
                                margin: 12,
                              }}
                              href={d.href}
                              target={d.target}
                              onClick={(evt) => {
                                if (h.dropdown) {
                                  const ns = dropdownStates.slice(0);
                                  ns[idx] = !ns[idx];
                                  setDropdownStates(ns);
                                }
                                onLinkClick(evt, d);
                                store.isShowOverlay = false;
                              }}
                            >
                              {i18nStore.dict.headers[d.name]}
                            </FooterTextButton>
                          );
                        })}
                        <div style={{width: '80%', height: 1, backgroundColor: '#414343', margin: '5px 0px'}} />
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              );
            })
          }
        </VFlex>
      </div>
    </CSSTransition>
  );
});
