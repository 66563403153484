import React, {Component, useRef, useState } from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import { Header } from '../Header';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { BodyHeader, Header1, Header2, MainTitle, UnderlineText } from '../Typography';
import { store } from '../../mobx/store';
import { COLORS, fadeinout, fadeinoutweak, NETWORK_CONFIG } from '../../utils/constants';
import { Section } from '../Section';
import SectionDataComponent from '../SectionDataComponent';
import i18nStore from '../../localizations';
import { IconMainButton, MainButton } from '../MainButton';
import FooterV3 from '../v3/FooterV3';
import AddressItem from './AddressItem';
import TermsAndConditionDialog from '../dialog/TermsAndConditionDialog';
import { ConnectWalletBtn } from '../ConnectWalletBtn';
import { CodeContainer } from '../mint/InvitationCodeComponent';
import { WalletInfoComponent } from '../WalletInfoComponent';
import { TimeSubText } from '../banner/banner-common';
import { post } from '../../api/api';
import {TRedeemCode} from '../../types';
import ViewOpenseaBtn from '../ViewOnOpenseaBtn';


interface YumChaPageProps {
}

const ScrolldownContainer = styled(VFlex)`
animation: ${fadeinoutweak} 5s linear infinite;
`;

const CheckOSDIV = styled.span`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
/* or 200% */

text-align: center;
letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

const YumChaPage = observer((props:YumChaPageProps) => {
  const opacity = (200 - Math.min(store.scrollYOffset, 200)) / 200;
  const l = i18nStore.dict.yumcha;
  const [redeemCodeInput, setRedeemCodeInput] = useState('');
  const uselessCodes = useRef({});
  const [isRedeeming, setIsRedeeming] = useState('');
  const [redeemResult, setRedeemResult]:[TRedeemCode] = useState();
  return (
    <VFlex
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Header />
      <VFlex
        style={{position: 'relative', alignItems: 'center'}}
      >
        <img
          style={{
            width: '100%',
            height: '100vh',
            minHeight: '800px',
            objectFit: 'cover',
          }}
          src="/images/yum-cha/banner.png"
          alt=""
        />
        {
          (() => {
            if (redeemResult) {
              return (
                <VFlex
                  style={{
                    alignItems: 'center',
                    marginTop: 180,
                    position: 'absolute',
                    maxWidth: '80vw',
                    gap: 20,
                  }}
                >
                  <img
                    style={{width: 226}}
                    src="/images/yum-cha/9bun.png"
                    alt=""
                  />
                  <Header1>
                    {redeemResult?.reward?.type === 'NBeanAirdrop' ? l.redeemed9Bean : l.redeemedBun}
                  </Header1>
                  <CheckOSDIV>
                    {l.checkOS}
                  </CheckOSDIV>
                  <ViewOpenseaBtn />
                  <WalletInfoComponent />
                  <UnderlineText
                    className="simple-btn"
                    onClick={() => {
                      setRedeemCodeInput('');
                      setRedeemResult(null);
                    }}
                  >
                    {l.keepRedeeming}
                  </UnderlineText>
                </VFlex>
              );
            }
            if (store.web3Ctx?.account) {
              return (
                <VFlex
                  style={{
                    alignItems: 'center',
                    marginTop: 180,
                    position: 'absolute',
                    gap: 30,
                  }}
                >
                  <MainTitle
                    style={{
                      marginTop: 41, textAlign: 'center',
                    }}
                  >
                    {l.title}
                  </MainTitle>
                  <CodeContainer
                    style={{marginTop: 0, alignItems: 'center', gap: 22}}
                  >
                    <BodyHeader>
                      {l.inputRedeemCode}
                    </BodyHeader>
                    <input
                      style={{alignSelf: 'stretch'}}
                      onChange={(evt) => {
                        setRedeemCodeInput(evt.target.value.toUpperCase());
                      }}
                      value={redeemCodeInput}
                    />
                    <MainButton
                      style={{marginTop: 0, color: 'black'}}
                      className="simple-btn"
                      onClick={async () => {
                        if (uselessCodes.current[redeemCodeInput]) {
                          store.showErrorToast(uselessCodes.current[redeemCodeInput]);
                          return;
                        }
                        if (isRedeeming) return;
                        setIsRedeeming(true);
                        try {
                          const result = await post({
                            uri: '/redeem',
                            body: {
                              to_address: store.web3Ctx.account,
                              code: redeemCodeInput,
                            },
                          });
                          const c:TRedeemCode = result.body;
                          setRedeemResult(c);
                        } catch (error) {
                          if (error.message) {
                            uselessCodes.current[redeemCodeInput] = error;
                          }
                          store.showErrorToast(error);
                        }
                        setIsRedeeming(false);
                      }}
                      isLoading={isRedeeming}
                    >
                      {l.redeem}
                    </MainButton>
                    <a
                      href={l.mediumLink}
                      target="_blank"
                      rel="noreferrer"
                      className="simple-btn"
                    >
                      <UnderlineText>
                        {l.howToGetRedeemCode}
                      </UnderlineText>
                    </a>
                  </CodeContainer>
                  <WalletInfoComponent
                    acc={store.web3Ctx.account}
                  />
                </VFlex>
              );
            }
            return (
              <VFlex
                style={{
                  alignItems: 'center',
                  marginTop: 180,
                  position: 'absolute',
                }}
              >
                <img
                  style={{
                    width: 200,
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                  src="/images/yum-cha/logo.png"
                  alt=""
                />
                <MainTitle
                  style={{
                    marginTop: 41, textAlign: 'center',
                  }}
                >
                  {l.title}
                </MainTitle>
                <ConnectWalletBtn
                  style={{
                    color: '#050505',
                  }}
                />
              </VFlex>
            );
          })()
        }
        <div
          style={{
            position: 'fixed',
            bottom: 30,
            opacity,
            transform: 'scale(0.8)',
          }}
        >
          <ScrolldownContainer
            style={{
              alignItems: 'center',
              gap: 12,
            }}
          >
            <img
              style={{width: 30, height: 'auto'}}
              src="/images/yum-cha/arrow-down.svg"
              alt=""
            />
            <BodyHeader style={{color: 'white'}}>
              {l.scrollDown}
            </BodyHeader>
          </ScrolldownContainer>
        </div>
      </VFlex>
      <VFlex
        className="full-width"
        style={{
          marginTop: 30,
          alignSelf: 'center',
        }}
      >
        <SectionDataComponent
          l={i18nStore.dict.yumcha}
          data={i18nStore.yumChaSectionData[0]}
        />
        <SectionDataComponent
          l={i18nStore.dict.yumcha}
          data={i18nStore.yumChaSectionData[1]}
        />
      </VFlex>
      <MainTitle
        style={{alignSelf: 'center'}}
      >
        {l.location}
      </MainTitle>
      <ResponsiveFlex
        style={{alignSelf: 'center', marginTop: 49, marginBottom: 120, gap: 24}}
      >
        {
          [
            {
              iframeComponent: (
                <iframe
                  title="TST"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3289.0148791946735!2d114.15360565073041!3d22.28579587971214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007cdef8a021%3A0xd8a277aa4af176c9!2z6aOy6Iy2!5e0!3m2!1szh-TW!2sbe!4v1667492555935!5m2!1szh-TW!2sbe"
                  className="full-width"
                  style={{border: 0, width: store.isDesktop && 400}}
                  allowFullScreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              ),
              title: 'YUM CHA',
              address: l.address,
              phone: '3708 8081',
            },
          ].map((data) => {
            return (
              <AddressItem
                {...data}
              />
            );
          })
        }
      </ResponsiveFlex>
      <VFlex
        style={{
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <img
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: 300,
            objectFit: 'cover',
          }}
          src="/images/yum-cha/footer-plain.png"
          alt=""
        />
        <img
          style={{
            width: 'auto',
            height: '70%',
            objectFit: 'contain',
            position: 'absolute',
          }}
          src="/images/yum-cha/logo.png"
          alt=""
        />
      </VFlex>
      <FooterV3 />
      <TermsAndConditionDialog />
    </VFlex>
  );
});

export default YumChaPage;
