import React, { useCallback, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { store } from '../../mobx/store';
import { COLORS, MediaQueryMobileOnly, ROTATE_KEYFRAMES } from '../../utils/constants';
import { VFlex } from '../Flex';
import { Body, SubText } from '../Typography';
// import { Alert, alertVariants } from '../../components/Alert';
import { ToastProps, AlertTypes } from './types';

const StyledToast = styled.div`
  display: flex;
  align-items: center;
  left: 31px;
  position: fixed;
  max-width: 422px;
  transition: all 250ms ease-in;
  width: 422px;
  min-height: 77px;
  background-color: ${COLORS.darkBlack};
  padding: 12px 0px;

  ${MediaQueryMobileOnly} {
    max-width: 400px;
  }
`;

const StyledLeftContainer = styled.div`
  width: 24px;
  height: 24px;
  margin: 0px 16px;
  line-height: 0px;
`;

const StyledLink = styled.a`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  letter-spacing: 2px;
  text-decoration-line: underline;

  /* lightgrey */

  color: #F5F5F5;
`;

const StyledLoadingImage = styled.img`
animation: ${ROTATE_KEYFRAMES} 1.5s linear infinite; 
`;

const Toast: React.FC<ToastProps> = ({ toast, onRemove, style, ttl, ...props }) => {
  const timer = useRef();
  const ref = useRef(null);
  const removeHandler = useRef(onRemove);
  const { id, title, description, type } = toast;

  const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler]);

  const handleMouseEnter = () => {
    clearTimeout(timer.current);
  };

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      handleRemove();
    }, ttl);
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (ttl > 0) {
      timer.current = window.setTimeout(() => {
        handleRemove();
      }, ttl);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [timer, ttl, handleRemove]);

  return (
    <CSSTransition nodeRef={ref} timeout={250} style={style} {...props}>
      <StyledToast ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <StyledLeftContainer>
          {(() => {
            switch (toast.type) {
              case 'success':
                return (
                  <img
                    style={{width: 24, height: 24}}
                    src="/images/v2/circle-tick.svg"
                    alt=""
                  />
                );
              case 'loading':
                return (
                  <StyledLoadingImage
                    style={{width: 24, height: 24}}
                    src="/images/v2/toast-spinner.svg"
                    alt=""
                  />
                );
              default:
                return (
                  <img
                    style={{width: 24, height: 24}}
                    src="/images/v2/alert.svg"
                    alt=""
                  />
                );
            }
          })()}
        </StyledLeftContainer>
        <VFlex style={{flex: 1}}>
          <Body>
            {title}
          </Body>
          {
            toast.link ? (
              <StyledLink
                className="simple-btn"
                style={{marginTop: 8}}
                href={toast.link.url}
                target="_blank"
              >
                {toast.link.text}
              </StyledLink>
            ) : null
          }
          {
            toast.description && toast.description.length > 0 ? (
              <SubText style={{textAlign: 'start'}}>
                {toast.description}
              </SubText>
            ) : null
          }
        </VFlex>
        <img
          style={{margin: '12px', width: 18, height: 18}}
          className="simple-btn"
          src="/images/v2/close.svg"
          alt=""
          onClick={() => {
            store.hideToast(toast.id);
          }}
        />
      </StyledToast>
    </CSSTransition>
  );
};

export default Toast;
