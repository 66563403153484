
import { constant } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import Wobble from 'react-reveal/Wobble';
import {now} from 'mobx-utils';

import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { Web3Provider } from '@ethersproject/providers';
import { injectedConnector, useConnect } from '../../hooks/hooks';
import { useNCContract } from '../../hooks/useContract';
import { store } from '../../mobx/store';
import { COLORS, fadeinout, NETWORK_CONFIG, PHASE } from '../../utils/constants';
import { getDisplayPriceString, getPhase, getTimeLeftObj, numberInGroups, useIsDesktop } from '../../utils/utils';
import { CatImage } from '../CatImage';
import { MainButton } from '../MainButton';

import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import DiscordSVG from '../svg/DiscordSVG';
import { TimeSubText } from './banner-common';
import { BodyHeader } from '../Typography';
import i18nStore from '../../localizations';

const TimeContainer = styled.div`
width: 90px;
height: 110px;
background: #1A1E1F;
border-radius: 4px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const TimeText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
letter-spacing: 0.1em;
margin-left: 3px;
color: #F5F5F5;
`;

const TimeColon = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
letter-spacing: 0.1em;
margin: 0px 6px 0px 12px;
transform: translateY(-12px);
color: #FFF;
animation: ${fadeinout} 2s ease infinite;
`;

const SubtitleText = styled.h3`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
text-align: center;
letter-spacing: -0.02em;
margin-top: 41px;
color: #7A7A7A;
`;

type Props = {
  onPhaseChange: (p:string) => void,
};


export const Countdown = observer((props:Props) => {
  const {onPhaseChange} = props;
  let headline = '';
  const isNeedRefresh = Date.now() < NETWORK_CONFIG.PUBLIC_SALE_TS;
  const nowTS = isNeedRefresh ? now() : Date.now();
  const prevPhase = useRef(getPhase(nowTS));
  let targetTS = -1;
  const phase = getPhase(nowTS);
  switch (phase) {
    case PHASE.BEFORE_ANYTHING:
      headline = i18nStore.dict.nbean.startsIn;
      targetTS = NETWORK_CONFIG.PRESALE_TS;
      break;
    case PHASE.PRESALE:
      headline = i18nStore.dict.nbean.startsIn;
      targetTS = NETWORK_CONFIG.PUBLIC_SALE_TS;
      break;
    case PHASE.PUBLIC:
      headline = 'Free mint has Started';
      break;
    default:
  }
  if (phase !== prevPhase.current) {
    onPhaseChange(phase);
    prevPhase.current = phase;
  }
  const processNumber = (d) => {
    return `${d}`.padStart(2, '0');
  };
  const countdownPairs = [];
  if (targetTS > 0) {
    const timeLeftObj = getTimeLeftObj(targetTS - now());
    if (timeLeftObj.d >= 1) {
      countdownPairs.push(
        {
          k: i18nStore.dict.days,
          v: timeLeftObj.d,
        },
      );
    }
    countdownPairs.push(
      {
        k: i18nStore.dict.hours,
        v: timeLeftObj.h,
      },
      {
        k: i18nStore.dict.minutes,
        v: timeLeftObj.m,
      },
    );
    if (timeLeftObj.d === 0) {
      countdownPairs.push({
        k: i18nStore.dict.seconds,
        v: timeLeftObj.s,
      });
    }
  }
  return (
    <VFlex
      style={{alignItems: 'center'}}
    >
      <BodyHeader>
        {headline}
      </BodyHeader>
      <Flex
        style={{
          marginTop: 12,
          transform: `scale(${(store.screenSize.width < 545 && countdownPairs.length > 3) ? 0.7 : 1})`,
        }}
      >
        {
          (nowTS < NETWORK_CONFIG.PUBLIC_SALE_TS) && (
            <React.Fragment>
              {
                (() => {
                  const c = [];
                  for (let i = 0; i < countdownPairs.length; i++) {
                    c.push((
                      <TimeContainer>
                        <TimeText>
                          {processNumber(countdownPairs[i].v)}
                        </TimeText>
                        <TimeSubText>
                          {countdownPairs[i].k}
                        </TimeSubText>
                      </TimeContainer>
                    ));
                    if (i !== countdownPairs.length - 1) {
                      c.push((
                        <TimeColon>
                          :
                        </TimeColon>
                      ));
                    }
                  }
                  return c;
                })()
              }
            </React.Fragment>
          )
        }
      </Flex>
    </VFlex>
  );
});
