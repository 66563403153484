import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from '../Flex';
import { Header } from '../Header';
import { H } from '../H';
import { store } from '../../mobx/store';
import { RoadMapV3 } from '../v3/RoadMapV3';
import FooterV3 from '../v3/FooterV3';
import BasePage from './BasePage';

interface RoadmapPageProps {
style: CSSProperties;
}

const RoadmapPage = observer((props:RoadmapPageProps) => {
  return (
    <BasePage h={0}>
      <RoadMapV3 />
    </BasePage>
  );
});

export default RoadmapPage;
