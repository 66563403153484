import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import { store } from '../mobx/store';
import { SSideContainer } from '../GlobalStyle';
import { DiscordButton, IGButton, MediumButton, OpenSeaButton, TGButton, ThreadsButton, TwitterButton, YoutubeButton } from './SocialMediaButton';

interface SideSocialBtnsBarProps {
  style: CSSProperties;
}

const SideSocialBtnsBar = observer((props: SideSocialBtnsBarProps) => {
  const { style } = props;
  if (!store.isDesktop) return null;
  return (
    <SSideContainer
      style={{
        right: store.scrollbarWidth,
        position: 'absolute',
        padding: '24px 16px',
        ...style,
      }}
    >
      {/* <OpenSeaButton size={24} /> */}
      <IGButton size={24} />
      <DiscordButton size={24} />
      <ThreadsButton size={24} />
      <YoutubeButton size={24} />
      <TGButton size={24} />
      <TwitterButton size={24} />
      <MediumButton size={24} />
    </SSideContainer>
  );
});

export default SideSocialBtnsBar;
