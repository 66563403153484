import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { COLORS } from '../utils/constants';

interface PlayIconProps {
  fill: String,
  style: CSSProperties,
}

const PlayIcon = observer((props:PlayIconProps) => {
  const {fill, style} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      style={style}
    >
      <path d="M0 16.5005V5.10655V0.500488L14 8.50049L0 16.5005Z" fill={fill || COLORS.primary} />
    </svg>
  );
});

export default PlayIcon;
