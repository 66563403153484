import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { BodyHeader, Header1, Header2, MainTitle, SubHeader } from '../Typography';

interface AddressItemProps {
}

const AddressItem = observer(({iframeComponent, title, address, phone}) => {
  return (
    <ResponsiveFlex
      className="full-width"
      style={{
        alignSelf: 'center',
        alignItems: 'stretch',
        gap: 0,
        maxWidth: 1000,
      }}
    >
      {iframeComponent}
      <VFlex
        style={{
          backgroundColor: '#2E3435',
          justifyContent: 'center',
          padding: '20px 36px',
          gap: 12,
          flex: 1,
        }}
      >
        <SubHeader>
          {title}
        </SubHeader>
        <Flex style={{gap: 8}}>
          <img
            src="/images/yum-cha/location-icon.png"
            alt=""
          />
          <BodyHeader style={{textAlign: 'start'}}>
            {address}
          </BodyHeader>
        </Flex>
        <Flex style={{gap: 8}}>
          <img
            src="/images/yum-cha/phone-icon.png"
            alt=""
          />
          <BodyHeader>
            {phone}
          </BodyHeader>
        </Flex>
      </VFlex>
    </ResponsiveFlex>
  );
});

export default AddressItem;
