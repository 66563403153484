import styled from 'styled-components';
import { COLORS, getLtMediaQuery, MediaQueryMobileOnly, WIDTH_THRESHOLD } from '../utils/constants';
import { VFlex } from './Flex';

export const MainTitle = styled.h1`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
letter-spacing: 0.1em;
color: #F5F5F5;
text-shadow: 4px 4px 0px #65BDE6;
`;

export const SubTitle = styled.h2`
/* Header 2 */
font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 42px;
letter-spacing: 0.1em;
margin: 0px;

/* lightgrey */
color: #F5F5F5;

/* body-purple */
text-shadow: 2px 2px 0px #BF77E6;
`;

export const Paragraph = styled.p`
margin: 0px;
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
letter-spacing: 0.02em;
color: #F5F5F5;
margin-top: 30px;
`;

export const ShadowText = styled.h1`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 240px;
line-height: 282px;
letter-spacing: 0.15em;

/* dark-black */

color: ${COLORS.darkBlack};

opacity: 0.3;

${MediaQueryMobileOnly} {
  font-size: 100px;
  // transform-origin: top left;
  transform: rotateZ(-90deg);
}
`;

export const Body = styled.div`
/* Body */

font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
/* or 200% */

display: flex;
align-items: center;
letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

export const AlertText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
text-align: center;
letter-spacing: 0.02em;
color: #DE3146; 
`;

export const WhitelistText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* or 129% */

text-align: center;
letter-spacing: 2px;
text-decoration-line: underline;

/* lightgrey */

color: #F5F5F5;
`;

export const MintTitleText = styled.h1`
margin-top: 74px;
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
text-align: center;
letter-spacing: 0.1em;

color: #F5F5F5;

${getLtMediaQuery(400)} {
  font-size: 35px;
}
`;

export const SubText = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;

text-align: center;
letter-spacing: 0.02em;

color: #ABABAB;
`;

export const SubHeader = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 33px;
letter-spacing: 0.05em;
color: #F5F5F5;
`;

export const MintCountText = styled(VFlex)`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1em;

color: #F5F5F5;
`;

export const GetMaxText = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
text-align: center;
letter-spacing: 2px;
text-decoration-line: underline;

color: #F5F5F5;
`;

export const Header2 = styled.h2`
font-family: Raleway;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 42px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1em;

/* lightgrey */

color: #F5F5F5;
`;

export const Header1 = styled.h2`
font-family: Raleway;
font-style: normal;
font-weight: 900;
font-size: 48px;
line-height: 56px;
text-align: center;
letter-spacing: 0.1em;

color: #F5F5F5;
text-shadow: ${props => (props.hasShadow ? '4px 4px 0px #65BDE6' : 'none')};
`;

export const BodyHeader = styled.div`
/* Body-Header */

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
text-align: center;

/* lightgrey */

color: #F5F5F5;
`;

export const DescriptionText = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
/* or 200% */

text-align: center;
letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

export const UnderlineText = styled.span`

font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* or 129% */

text-align: center;
letter-spacing: 2px;
text-decoration-line: underline;

/* lightgrey */

color: #F5F5F5;

`;

export const Subheader2 = styled.div`
color: var(--lightgrey, #F5F5F5);
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
${getLtMediaQuery(WIDTH_THRESHOLD)} {
  max-width: 220px;
}
`;

export const S20Normal = styled.div`
color: var(--lightgrey, #F5F5F5);
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 90% */
letter-spacing: 0.3px;
`;

export const STitleV4 = styled.div`
color: var(--primary, #D585FF);
font-family: 'Racing Sans One';
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 4.8px;
${getLtMediaQuery(WIDTH_THRESHOLD)} {
  color: var(--primary, #D585FF);
  font-family: Racing Sans One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
}
`;

export const SContentV4 = styled.div`
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 171.429% */
letter-spacing: 0.5px;
`;

export const SDesktopBody = SContentV4;

export const S14Normal = styled.div`
color: var(--lightgrey, #F5F5F5);
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 128.571% */
letter-spacing: 0.28px;
`;

export const S24Racing = styled.div`
color: var(--third, #8BFCE5);
text-align: center;
font-family: Racing Sans One;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const S18DesktopSubheader2 = styled.div`
color: var(--lightgrey, #F5F5F5);
text-align: center;

/* Desktop/Subheader 2 */
font-family: Raleway;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

export const S14DesktopBody = styled.div`
color: var(--grey-2, #ABABAB);
text-align: center;

/* Desktop/Body */
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 200% */
letter-spacing: 0.28px;
`;
