import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from '../Flex';
import { Header } from '../Header';
import { TeamV3 } from '../v3/TeamV3';
import { H } from '../H';
import { store } from '../../mobx/store';
import FooterV3 from '../v3/FooterV3';
import BasePage from './BasePage';

interface TeamPageProps {
style: CSSProperties;
}

const TeamPage = observer((props:TeamPageProps) => {
  return (
    <BasePage h={36}>
      <TeamV3 />
    </BasePage>
  );
});

export default TeamPage;
