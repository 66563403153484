import { ReactNode } from 'react';

export const AlertTypes = {
  SUCCESS: 'success',
  DANGER: 'danger',
  LOADING: 'loading',
  // WARNING: 'warning',
  // INFO: 'info',
};

export type AlertTypesType = 'success' | 'danger' | 'loading';

export interface ToastType {
  id: string;
  type: AlertTypesType;
  title: string;
  link?: {
    url: string,
    text: String,
  };
  description?: ReactNode;
  ttl?: number;
}

export interface ToastContainerProps {
  toasts: ToastType[];
  stackSpacing?: number;
  ttl?: number;
  onRemove: (id: string) => void;
}

export interface ToastProps {
  toast: ToastType;
  onRemove: ToastContainerProps["onRemove"];
  ttl: number;
  style: any;
}
