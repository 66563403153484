import { useWeb3React } from '@web3-react/core';
import { observer } from 'mobx-react';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import i18nStore from '../../localizations';
import { store } from '../../mobx/store';
import { INVITATION_CODE_REGEX } from '../../utils/constants';
import { parseQuery } from '../../utils/utils';
import { TimeSubText } from '../banner/banner-common';
import { SpinnerContainer } from '../SpinnerContainer';
import { SubText } from '../Typography';

type Props = {

}

export const InvitedComponent = observer((props:Props) => {
  const {account} = useWeb3React();
  const location = useLocation();
  const query = parseQuery(location.search);
  const l = i18nStore.dict.mintPage;

  if (store.profile === undefined) {
    return (
      <SpinnerContainer
        style={{
          marginBottom: 50,
        }}
      >
        <Spinner animation="border" variant="light" />
      </SpinnerContainer>
    );
  }
  if (store.profile && store.profile.details.referrerInvitationCode) {
    return (
      <React.Fragment>
        <TimeSubText style={{marginTop: 6, marginBottom: 150}}>
          {l.linkedInviteCode}
          {' '}
          {store.profile.details.referrerInvitationCode}
        </TimeSubText>
      </React.Fragment>
    );
  }
  if (
    !account || !query.code || !INVITATION_CODE_REGEX.exec(query.code)
    || (store.profile && store.profile.details.invitationCode === query.code)
  ) return null;
  return (
    <React.Fragment>
      <TimeSubText style={{marginTop: 6}}>
        {l.invitationCode}
        {' '}
        {query.code}
      </TimeSubText>
      <SubText
        style={{
          textAlign: 'center',
          width: '90%',
          maxWidth: 800,
          margin: '10px 0px 150px 0px',
        }}
      >
        {l.mintDescription}
      </SubText>
    </React.Fragment>
  );
});
