import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from '../Flex';
import { Header } from '../Header';
import { TeamV3 } from '../v3/TeamV3';
import { H } from '../H';
import { store } from '../../mobx/store';
import FooterV3 from '../v3/FooterV3';

interface BasePageProps {
style: CSSProperties;
h: Number;
}

const BasePage = observer((props:BasePageProps) => {
  const {style, h, children} = props;
  return (
    <VFlex
      style={{
        width: '100%',
        position: 'relative',
        ...style,
      }}
    >
      <Header />
      <H h={store.headerHeight + h} />
      {children}
      <FooterV3 />
    </VFlex>
  );
});

export default BasePage;
