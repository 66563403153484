import { observer } from 'mobx-react-lite';
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';
import { COLORS, NETWORK_CONFIG, OS_LINK } from '../../utils/constants';
import { OS, getMobileOperatingSystem as getMobileOS, useIsDesktop } from '../../utils/utils';
import { DiscordButton, IGButton, MediumButton, OpenSeaButton, TGButton, TwitterButton, YoutubeButton } from '../SocialMediaButton';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { HeroImageDesktop } from './HeroImageDesktop';
import i18nStore from '../../localizations';
import { S14Normal, S20Normal, Subheader2 } from '../Typography';
import { store } from '../../mobx/store';
import SideSocialBtnsBar from '../SideSocialBtnsBar';
import { ALink } from '../Alink';


export const BannerButton = styled(Flex)`
height: 55.45px;

font-family: Mulish;
font-weight: 800;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
justify-content: center;
text-align: center;
letter-spacing: 0.3px;

/* dark-black */

color: #050505;
`;


type Props = {
}


const imageSequenceKeyframes = keyframes`
from { background-position: 0px; }
to { background-position: -11000px; }
`;

const ImageSequence = styled.div`
height: 500px;
width: 500px;
background: url("/images/catpawn-animation/spritesheet.png");
animation: 0.6s ${imageSequenceKeyframes} steps(22) ${props => (props.hovering ? 'infinite' : 1)};
`;


export const BannerV3 = observer((props: Props) => {
  const l = i18nStore.dict.mainPageV4;
  const [playGameHovering, setPlayGameHovering] = useState();
  return (
    <Fade>
      <VFlex
        style={{
          width: '100vw',
          minHeight: 667,
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <HeroImageDesktop />
        <div
          style={{
            backgroundColor: '#000000A0',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        />
        <Flex
          style={{
            position: 'absolute',
            alignItems: 'center',
            bottom: 52,
            gap: 24,
          }}
        >
          {
            [
              { name: 'STEAM', img: '/images/v4/steam.svg', to: OS_LINK.desktop },
              OS !== 'ios' && { name: 'GOOGLE PLAY', img: '/images/v4/android.svg', isComingSoon: false, to: OS_LINK.android },
              { name: 'APP STORE', img: '/images/v4/mac-os.svg', isComingSoon: false, to: OS_LINK.ios },
              // {name: 'MAC OS', img: '/images/v4/mac-os.svg'},
            ].filter(v => !!v).map(({ name, img, isComingSoon, to }) => {
              return (
                <ALink
                  to={to}
                  className="simple-btn"
                >
                  <Flex
                    style={{ gap: 3, opacity: 0.6 }}
                  >
                    <img
                      style={{ width: 50, height: 'auto' }}
                      src={img}
                      alt=""
                    />
                    {
                      store.isDesktop && (
                        <VFlex>
                          <S20Normal style={{ transform: 'translate(3px,3px)' }}>
                            {name}
                          </S20Normal>
                          {
                            isComingSoon && (
                              <S14Normal
                                style={{ marginLeft: 3, marginTop: 3 }}
                              >
                                Coming Soon!
                              </S14Normal>
                            )
                          }
                        </VFlex>
                      )
                    }
                  </Flex>
                </ALink>
              );
            })
          }
        </Flex>
        <VFlex
          style={{
            position: 'absolute',
            alignItems: 'center',
            top: 145,
          }}
        >
          <Subheader2
            style={{ textAlign: 'center' }}
          >
            {l.bannerHeader}
          </Subheader2>
          <img
            src="/images/portal/9CATSaga_1300x900.png"
            alt=""
            style={{
              width: '75vw',
              maxWidth: 400,
              height: 'auto',
              marginTop: 40,
            }}
          />
          <Flex
            style={{
              marginTop: 40,
              gap: 12,
            }}
          >
            <a
              href={OS_LINK[OS]}
              target="_blank"
              rel="noreferrer"
              style={{ position: 'relative' }}
              onPointerEnter={() => {
                setPlayGameHovering(true);
              }}
              onPointerLeave={() => {
                setPlayGameHovering(false);
              }}
            >
              <BannerButton
                style={{
                  backgroundColor: COLORS.purple1,
                  width: 202,
                  position: 'relative',
                }}
                className="simple-btn"
              >
                {i18nStore.dict.bannerV3.playGameBtn}
                <ImageSequence
                  style={{
                    position: 'absolute',
                    transformOrigin: '0 0',
                    transform: 'scale(0.2)',
                    left: '170px',
                    top: '0px',
                  }}
                  hovering={playGameHovering}
                />
              </BannerButton>
            </a>
          </Flex>
        </VFlex>
        <SideSocialBtnsBar />
      </VFlex>
    </Fade>
  );
});
