import React, {StyleHTMLAttributes} from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { useIsDesktop } from '../utils/utils';
import { Flex, VFlex } from './Flex';

type Props = {
  leftComponent: any,
  rightComponent: any,
  bottomComponent: any,
  topComponent: any,
  widthRatio: any,
  isAnimate: Boolean,
  isRightDominant: Boolean,
  isVRightFirst: Boolean,
  style: StyleHTMLAttributes,
}

export const StyledSectionHeader = styled.div`
margin-top: ${props => props.marginTop || '55px'};
text-align: left;
font-size: ${props => props.fontSize || '33px'};
letter-spacing: 0px;
color: #DD99FD;
opacity: 1;
font-weight: bold;
`;

export const StyledSectionText = styled.div`
text-align: left;
font-size: ${props => props.fontSize || '23px'};
font: normal normal normal 20px/23px;
letter-spacing: 0px;
color: #A1E1FE;
opacity: 1;
margin-top: ${props => props.marginTop || '15px'};
font-weight: bold;
`;

export const Section = ({style, bottomComponent, leftComponent, rightComponent, widthRatio, isRightDominant, isVRightFirst, topComponent, isAnimate}:Props) => {
  const isDesktop = useIsDesktop();
  const shouldListRightCompFirst = isVRightFirst && !isDesktop;
  const Component = isDesktop ? Flex : VFlex;
  const AnimateComponent = (isAnimate ?? true) ? Fade : React.Fragment;
  return (
    <div style={{width: '100%', ...style}}>
      {topComponent}
      <Component style={{maxWidth: '100%'}}>
        <AnimateComponent left>
          {shouldListRightCompFirst ? rightComponent : leftComponent}
        </AnimateComponent>
        <AnimateComponent right>
          {shouldListRightCompFirst ? leftComponent : rightComponent}
        </AnimateComponent>
      </Component>
      {bottomComponent}
    </div>
  );
};

export const StyledSection = ({title, text, titleFontSize, titleMarginTop, textFontSize, textMarginTop}) => {
  return (
    <Section
      topComponent={(
        <StyledSectionHeader
          fontSize={titleFontSize}
          marginTop={titleMarginTop}
        >
          {title}
        </StyledSectionHeader>
      )}
      bottomComponent={(
        <StyledSectionText
          fontSize={textFontSize}
          marginTop={textMarginTop}
        >
          {text}
        </StyledSectionText>
      )}
    />
  );
};
