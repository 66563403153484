import styled, { keyframes } from 'styled-components';

export const TimeSubText = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
text-align: center;
letter-spacing: 0.02em;
color: #ABABAB;
`;
