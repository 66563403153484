import { useWeb3React } from '@web3-react/core';
import { List } from 'immutable';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import styled from 'styled-components';
import { store } from '../../mobx/store';
import { COLORS, NETWORK_CONFIG } from '../../utils/constants';
import { getLoopNumbers, signMessage } from '../../utils/utils';
import CodeInput from '../CodeInput';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { MainButton } from '../MainButton';
import { Spinner } from '../SpinnerContainer';
import OpenSeaSVG from '../svg/OpenSeaSVG';
import { Body, DescriptionText, Header2, MintTitleText } from '../Typography';
import { InvitationLinkComponent } from '../v3/InvitationLinkComponent';
import { WalletInfoComponent } from '../WalletInfoComponent';

type Props = {

}

export const CodeContainer = styled(VFlex)`
background-color: #1A1E1F;
padding: 24px;
align-items: center;
margin-top: 155px;
align-self: center;
width: 90vw;
max-width: 676px;

border: 1px solid;
border-image-slice: 1;
border-width: 1px;
border-image-source: linear-gradient(180deg, #8BFCE5 0%, #D585FF 100%);
`;


export const InvitationCodeComponent = observer((props:Props) => {
  const {account: acc, library} = useWeb3React();
  const [isValid, setIsValid] = useState();
  const [isFetching, setIsFetching] = useState();
  const [codeInputKey, setcodeInputKey] = useState(Date.now());
  return (
    <CodeContainer>
      <Header2 style={{textAlign: 'center'}}>
        Input invitation code
      </Header2>
      <DescriptionText>
        You should get a invitation from your 9cat owner friend.
      </DescriptionText>
      <DescriptionText>
        Input the last 4 character of the link to mint.
      </DescriptionText>
      {
        isFetching ? (
          <VFlex
            style={{
              height: 108,
              justifyContent: 'center',
            }}
          >
            <Spinner
              size="lg"
            />
          </VFlex>
        ) : (
          <CodeInput
            key={codeInputKey}
            isValid={isValid}
            containerStyle={{marginTop: 20}}
            onChange={async (code) => {
              setIsValid(null);
              console.log(code);
              if (code.length === 4) {
                setIsFetching(true);
                const id = `showSigningToast-${Date.now()}`;
                store.showToast({
                  id,
                  title: 'Confirming the invitation code.',
                  type: 'loading',
                  ttl: 1000000,
                });
                try {
                  const msg = `Confirm invitation code: ${code}`;
                  const signature = await signMessage(library, msg, acc);
                  await store.fetchConfirmReferrer(
                    code,
                    signature,
                    msg,
                    acc,
                  );
                  store.hideToast(id);
                  store.showToast({
                    type: 'success',
                    title: 'Linked invitation code!',
                  });
                } catch (e) {
                  store.hideToast(id);
                  store.showErrorToast(e);
                }
                setIsFetching(false);
              }
            }}
          />
        )
      }
      <VFlex
        style={{maxWidth: 550}}
      >
        <div
          style={{
            backgroundColor: '#464848',
            height: 2,
            alignSelf: 'stretch',
            margin: '25px 0px',
          }}
        />
        <MainButton
          style={{marginTop: 0}}
          onClick={() => {
            store.isSkipInvitationCode = true;
          }}
          className="simple-btn"
        >
          I don't have a code
        </MainButton>
      </VFlex>
    </CodeContainer>
  );
});
