import React, {Component, Fragment, useEffect, useState } from 'react';
import {observer} from 'mobx-react';
import { now } from 'mobx-utils';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Flex, VFlex } from '../Flex';
import { BodyHeader, Header1 } from '../Typography';
import { Countdown } from '../banner/Countdown';
import { getPhase, getTimeLeftObj, processNumber } from '../../utils/utils';
import { NETWORK_CONFIG, PHASE } from '../../utils/constants';
import { MainButton } from '../MainButton';
import { store } from '../../mobx/store';
import { ConnectWalletBtn } from '../ConnectWalletBtn';
import { WalletInfoComponent } from '../WalletInfoComponent';
import { Header } from '../Header';
import { Spinner } from '../SpinnerContainer';
import { WHITELISTED } from '../../db/db';
import { NSeriesMarqueeText } from '../v3/NSeriesMarqueeText';
import i18nStore from '../../localizations';
import { IncorrectNetworkComponent } from '../IncorrectNetworkComponent';


interface NBeanMintPageProps {
}

let t = '';

for (let i = 0; i < 100; i++) {
  t += '9BEAN IS SOLD OUT ';
}

const NBeanMintPage = observer((props:NBeanMintPageProps) => {
  const l = i18nStore.dict.nbean;
  const n = now();
  const p = getPhase(n);
  const tlObj = getTimeLeftObj(NETWORK_CONFIG.PUBLIC_SALE_TS - n);
  const [isMinting, setIsMinting] = useState();
  const [isOwned, setIsOwned] = useState();
  const [totalSupply, setTotalSupply] = useState(0);
  const rad = Math.tan((store.screenSize.height - 300) / store.screenSize.width);
  const deg = Math.round(rad / Math.PI * 180);
  const isSoldOut = totalSupply >= 100;
  useEffect(() => {
    let interval = null;
    const cb = async () => {
      console.log('get ts');
      const ts = await store.nBeanContractCall('totalSupply', undefined, {parseInt: true});
      console.log({ts});
      setTotalSupply(ts);
      if (ts >= 100) clearInterval(ts);
      return ts;
    };
    (async () => {
      const ts = await cb();
      if (ts < 100) {
        interval = setInterval(cb, 5000);
      }
    })();
    return () => {
      clearInterval(interval);
    };
  }, []);
  const ctx = useWeb3React();
  useEffect(() => {
    (async () => {
      if (store.web3Ctx?.account) {
        const o = (await store.nBeanContractCall('balanceOf', [store.web3Ctx.account])) === 1;
        setIsOwned(o);
      }
    })();
  }, [ctx, ctx.account]);
  console.log({isSoldOut});
  const renderContent = () => {
    if (isSoldOut) {
      return null;
    }
    return (
      <VFlex style={{alignItems: 'center', gap: 15, marginTop: store.headerHeight}}>
        {
          p === PHASE.BEFORE_ANYTHING && (
            <React.Fragment>
              <Header1 style={{}}>
                {l.comingSoon}
              </Header1>
              <Countdown />
              <BodyHeader style={{color: '#C4C4C4', marginTop: 0}}>
                {l.fillForm}
              </BodyHeader>
              <a
                href="https://forms.gle/JtKACQjB95LuzZTP7"
                target="_blank"
                rel="noreferrer"
                style={{marginTop: -25}}
              >
                <MainButton
                  style={{
                    color: 'black',
                  }}
                  className="simple-btn"
                  onClick={() => {
                  }}
                >
                  <img
                    style={{width: 16, height: 16, position: 'absolute', left: 30}}
                    src="/images/9bean/export-icon.png"
                    alt=""
                  />
                  <div style={{transform: 'translateX(20px)'}}>
                    {l.registerWhitelist}
                  </div>
                </MainButton>
              </a>
            </React.Fragment>
          )
        }
        {
          (p === PHASE.PRESALE || p === PHASE.PUBLIC) && (
            <React.Fragment>
              <Header1>
                9Bean
              </Header1>
              <BodyHeader>
                {l.companion}
              </BodyHeader>
              <img
                style={{width: 316, maxWidth: '80vw', height: 'auto'}}
                src="/images/9bean/GIF_400x400.gif"
                alt=""
              />
              {
                (() => {
                  if (!store.web3Ctx?.account) return null;
                  const canMint = !(p === PHASE.PRESALE && !store.whitelisted) && !isOwned;
                  const cb = async () => {
                    try {
                      if (isMinting || !canMint) return;
                      setIsMinting(true);
                      await store.txFlow(() => {
                        if (p === PHASE.PRESALE) {
                          return store.NBeanContract.mintWhitelist(store.whitelisted);
                        }
                        return store.NBeanContract.mint();
                      });
                      setIsOwned(true);
                    } catch (error) {
                      console.log(error);
                    }
                    setIsMinting(false);
                  };
                  let comp = null;
                  if (!canMint) {
                    comp = (
                      <Flex style={{gap: 15, color: '#DE3146', backgroundColor: '#00000050', padding: '3px 8px', borderRadius: 9}}>
                        <img
                          src="/images/v2/alert.svg"
                          alt=""
                        />
                        {
                          isOwned ? l.alreadyMinted : l.notInWhitelist
                        }
                      </Flex>
                    );
                  }
                  return (
                    <React.Fragment>
                      <MainButton
                        style={{color: '#050505', gap: 15}}
                        disabled={!canMint}
                        onClick={cb}
                      >
                        {
                          isMinting ? (
                            <Spinner style={{}} />
                          ) : l.mintBtn
                        }
                      </MainButton>
                      {comp}
                    </React.Fragment>
                  );
                })()
              }
              {
                store.web3Ctx?.account ? (
                  <VFlex style={{marginTop: -20}}>
                    <WalletInfoComponent acc={store.web3Ctx?.account} />
                  </VFlex>
                ) : (
                  <ConnectWalletBtn style={{color: '#050505'}} />
                )
              }
              <IncorrectNetworkComponent />
              <BodyHeader>
                {
                  `Minted ${totalSupply} / 100`
                }
              </BodyHeader>
              {
                p === PHASE.PRESALE && (
                  <BodyHeader>
                    {l.actQuick}
                    &nbsp;
                    {
                      `${processNumber(tlObj.h)}:${processNumber(tlObj.m)}:${processNumber(tlObj.s)}`
                    }
                  </BodyHeader>
                )
              }
            </React.Fragment>
          )
        }
        <div style={{height: 100}} />
      </VFlex>
    );
  };

  return (
    <VFlex style={{height: '100vh', overflow: 'scroll', justifyContent: 'flex-start'}}>
      <Header />
      <VFlex style={{
        justifyContent: 'center',
        flex: 1,
        alignItems: 'center',
        overflow: store.isDesktop && 'hidden',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
      >
        <VFlex style={{position: 'fixed', bottom: 0, left: 0, top: 0}}>
          <img
            style={{width: '100vw', height: '100vh', objectFit: 'cover'}}
            src={store.isDesktop ? '/images/9bean/mint-bg-desktop.png' : '/images/9bean/mint-bg-mobile.png'}
            alt=""
          />
          <div style={{position: 'absolute', top: 0, left: 0, width: '100vw', height: 100, background: 'linear-gradient(180deg, #1a1e1fFF 0%, #1a1e1f00 100%)'}} />
        </VFlex>
        {
          isSoldOut && (() => {
            const w = Math.sqrt((store.screenSize.width ** 2) + (store.screenSize.height ** 2)) * 1.5;
            console.log('sold out');
            return (
              <React.Fragment>
                <img
                  style={{position: 'absolute', marginTop: 0, transform: store.isDesktop ? '' : 'scale(0.8)'}}
                  src="/images/9bean/sold-out.png"
                  alt=""
                />
                <NSeriesMarqueeText
                  style={{width: w, transform: `rotate(${deg}deg)`, transformOrigin: 'center center', position: 'absolute'}}
                  text={t}
                />
                <NSeriesMarqueeText
                  style={{width: w, transform: `rotate(-${deg}deg)`, transformOrigin: 'center center', position: 'absolute'}}
                  text={t}
                />
              </React.Fragment>
            );
          })()
        }
      </VFlex>
      <VFlex
        style={{
          alignItems: 'center',
        }}
      >
        {renderContent()}
      </VFlex>
    </VFlex>
  );
});

export default NBeanMintPage;
