import React, { useState } from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { Center, Flex, ResponsiveFlex, VFlex } from '../Flex';
import { BubbleTitle } from '../BubbleTitle';
import { MainTitle, ShadowText } from '../Typography';
import { COLORS, MAX_DESKTOP_WIDTH, MediaQueryMobileOnly, WIDTH_THRESHOLD } from '../../utils/constants';
import { Container } from '../Container';
import { CatImage } from '../CatImage';
import { store } from '../../mobx/store';
import { useIsDesktop } from '../../utils/utils';
import i18nStore from '../../localizations';


type TeamMemberProps = {
  name: String,
  src: String,
  href: String,
  intro: String,
  post: String,
}

export const AsSeenOn = observer(() => {
  const isDesktop = useIsDesktop();
  return (
    <ResponsiveFlex
      style={{
        height: isDesktop ? 154 : 314,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        alignSelf: 'center',
      }}
    >
      <ResponsiveFlex>
        <MainTitle>
          {i18nStore.dict.asSeenOn}
        </MainTitle>
        <div style={{ width: 50, height: 20}} />
        <Flex>
          <a
            href="https://raritysniper.com/9cat"
            target="_blank"
            rel="noreferrer"
            className="simple-btn"
          >
            <img
              style={{
                width: 60,
                height: 'auto',
                alignSelf: 'center',
              }}
              src="/images/v3/icon-rarity-sniper.png"
              alt=""
            />
          </a>
          <div style={{width: 30, height: 30}} />
          <img
            style={{
              width: 60,
              height: 'auto',
              alignSelf: 'center',
            }}
            src="/images/v2/nft_calendar_logo_vector.svg"
            alt=""
          />
        </Flex>
      </ResponsiveFlex>
    </ResponsiveFlex>
  );
});
