import React, {Component, useState} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';

import { isDesktop } from 'react-device-detect';
import Fade from 'react-reveal/Fade';
import { Container } from './Container';
import { BubbleTitle } from './BubbleTitle';
import { MainTitle } from './Typography';
import { COLORS, MAX_DESKTOP_WIDTH, MediaQueryMobileOnly, NETWORK_CONFIG } from '../utils/constants';
import { Flex, ResponsiveFlex, VFlex } from './Flex';

import './FAQV2.css';
import { store } from '../mobx/store';
import i18nStore from '../localizations';

interface FAQV2Props {
}

const defaultState = {
};

type _State = typeof defaultState;
interface State extends _State {
}

const FAQItemContainer = styled.div`
max-height: 48px;
display: flex;
flex-direction: column;
align-items: center;
background: #2E3435;
margin-bottom: 8px;
transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
-webkit-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
width: 100%;

${MediaQueryMobileOnly} {
  margin-bottom: 3px;
}
`;

const Q = styled.h5`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 16px;
display: flex;
align-items: center;
letter-spacing: -0.02em;
color: #C4C4C4;
margin: 0px;
${MediaQueryMobileOnly} {
  font-size: 14px;
}
`;

const A = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 28px;
letter-spacing: 0.02em;
color: #C4C4C4;
text-align: left;
align-self: flex-start;
transition: opacity 0.3s cubic-bezier(0, 1, 0, 1);
opacity: 0;
&.open {
  opacity: 1;
}
`;

const FAQData1 = 3;

const FAQData2 = 3;


const FAQItem = observer(({idx, g}) => {
  const [isExpand, setIsExpand] = useState(false);
  const l = i18nStore.dict.faq;
  return (
    <Fade>
      <FAQItemContainer
        className={isExpand ? 'open' : ''}
      >
        <Flex
          style={{
            justifyContent: 'space-between',
            width: '100%',
            height: 48,
            padding: '16px 24px',
          }}
          className="simple-btn"
          onClick={() => {
            setIsExpand(!isExpand);
          }}
        >
          <Q>
            {l[`d${g}q${idx}`]}
          </Q>
          <VFlex
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              height: 20,
              width: 20,
              marginRight: 12,
            }}
          >
            <div style={{position: 'absolute', left: 0, right: 0, height: 2, backgroundColor: '#C4C4C4', borderRadius: 12}} />
            {
              !isExpand ? (
                <div style={{position: 'absolute', top: 0, bottom: 0, width: 2, backgroundColor: '#C4C4C4', borderRadius: 12}} />
              ) : null
            }
          </VFlex>
        </Flex>
        <A
          style={{
            padding: '0px 24px',
          }}
          className={isExpand ? 'open' : ''}
        >
          {l[`d${g}a${idx}`]}
        </A>
      </FAQItemContainer>
    </Fade>
  );
});

const FAQV2 = observer((props:FAQV2Props) => {
  return (
    <Container
      style={{
        alignItems: 'center',
        marginTop: 0,
        position: 'relative',
        backgroundColor: 'white',
        paddingBottom: 120,
      }}
    >
      <div
        style={{
          background: 'linear-gradient(180deg, #8BFCE5 0%, rgba(196, 196, 196, 0) 100%)',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 0,
        }}
      />
      <VFlex
        style={{
          alignItems: 'center',
        }}
      >
        <MainTitle style={{marginTop: 80, marginBottom: 30, color: COLORS.darkBlack}}>
          FAQ
        </MainTitle>
        <ResponsiveFlex
          style={{
            width: '100%',
            maxWidth: MAX_DESKTOP_WIDTH,
            justifyContent: isDesktop ? 'center' : null,
          }}
        >
          <VFlex style={{flex: 1}}>
            {
              (() => {
                const arr = [];
                for (let i = 1; i <= FAQData1; i++) {
                  arr.push((
                    <FAQItem key={`faq1_${i}`} g={1} idx={i} />
                  ));
                }
                return arr;
              })()
            }
          </VFlex>
          {
            isDesktop ? (
              <div style={{minWidth: 8}} />
            ) : null
          }
          <VFlex style={{flex: 1, alignSelf: 'stretch'}}>
            {
              (() => {
                const arr = [];
                for (let i = 1; i <= FAQData2; i++) {
                  arr.push((
                    <FAQItem key={`faq2_${i}`} g={2} idx={i} />
                  ));
                }
                return arr;
              })()
            }
          </VFlex>
        </ResponsiveFlex>
      </VFlex>
    </Container>
  );
});

export default FAQV2;
