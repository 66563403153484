import React, {Component} from 'react';
import {observer} from 'mobx-react';
import { store } from '../mobx/store';
import { Flex } from './Flex';

interface ScaleWrapperProps {
  maxWidth: number;
  height: number;
  style: any;
  children: any;
}

const defaultState = {
};

type _State = typeof defaultState;
interface State extends _State {
}

const ScaleWrapper = observer(({style, maxWidth, height, children}:ScaleWrapperProps) => {
  let transformString = '';
  let marginBottom = 0;
  if (store.screenSize.width < maxWidth) {
    const scale = store.screenSize.width / maxWidth;
    transformString = `scale(${scale},${scale})`;
    marginBottom = -height * (1 - scale);
  }
  return (
    <Flex
      style={{
        transformOrigin: 'top left',
        minWidth: maxWidth,
        alignSelf: store.screenSize.width > maxWidth ? 'center' : 'stretch',
        transform: transformString,
        marginBottom,
        ...style,
      }}
    >
      {children}
    </Flex>
  );
});

export default ScaleWrapper;
