import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { injectedConnector, useConnect, useInactiveListener } from '../hooks/hooks';
import { store } from '../mobx/store';
import { CONNECTED_BEFORE_KEY, NETWORK_CONFIG, PHASE } from '../utils/constants';
import { getIsSelling, getPhase } from '../utils/utils';

export const WalletLoader = () => {
  const [triedConnect, setTriedConnect] = useState(false);
  const ctx = useWeb3React();
  const { activate, account } = ctx;
  const connect = useConnect();
  useEffect(() => {
    // if (localStorage.getItem(CONNECTED_BEFORE_KEY)) {
    //   connect();
    //   setTimeout(() => {
    //     setTriedConnect(true);
    //   }, 1000);
    // }
  }, []);
  useEffect(() => {
    if (account) {
      store.fetchProfile(account);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      localStorage.setItem(CONNECTED_BEFORE_KEY, 'true');
      store.isIncorrectNetwork = false;
    } else {
      store.profile = undefined;
    }
  }, [account]);
  useInactiveListener(!triedConnect);
  return null;
};
