import { useWeb3React } from '@web3-react/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import i18nStore from '../../localizations';
import { store } from '../../mobx/store';
import { COLORS, INVITATION_CODE_REGEX } from '../../utils/constants';
import { formatBN, parseQuery } from '../../utils/utils';
import { Flex, VFlex } from '../Flex';
import { IconMainButton } from '../MainButton';
import { DescriptionText, GetMaxText, MintCountText, SubText } from '../Typography';
import { InvitationLinkComponent } from '../v3/InvitationLinkComponent';
import { InvitedComponent } from '../v3/InvitedComponent';
import { WalletInfoComponent } from '../WalletInfoComponent';
import { useConnect } from '../../hooks/hooks';
import { IncorrectNetworkComponent } from '../IncorrectNetworkComponent';

type Props = {
  setMintCountOption:any;
  mintCountOption:any;
  maxAvailable:any;
  onMint:any;
  price:any;
}

export const MintingComponent = observer(({setMintCountOption, mintCountOption, maxAvailable, onMint, price}:Props) => {
  const {account: acc} = useWeb3React();
  const location = useLocation();
  const query = parseQuery(location.search);
  const l = i18nStore.dict.mintPage;
  const connect = useConnect(true);
  return (
    <React.Fragment>
      <InvitationLinkComponent />
      <VFlex>
        <Flex
          style={{
            width: 288,
            height: 72,
            backgroudColor: COLORS.grey1,
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <VFlex
            style={{
              height: 72,
              width: 72,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.grey2,
            }}
            onClick={() => {
              setMintCountOption(Math.max(1, mintCountOption - 1));
            }}
            className="simple-btn"
          >
            <div style={{backgroundColor: 'white', height: 4, width: 36}} />
          </VFlex>
          <MintCountText
            style={{
              width: 144,
              height: 72,
              backgroundColor: COLORS.grey3,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {mintCountOption}
          </MintCountText>
          <VFlex
            style={{
              height: 72,
              width: 72,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.purple2,
            }}
            className="simple-btn"
            onClick={() => {
              setMintCountOption(Math.min(maxAvailable, mintCountOption + 1));
            }}
          >
            <div style={{backgroundColor: 'white', height: 4, width: 36, position: 'absolute'}} />
            <div style={{backgroundColor: 'white', height: 36, width: 4}} />
          </VFlex>
        </Flex>
        <VFlex
          style={{
            marginTop: 12,
            alignItems: 'center',
            width: 288,
          }}
        >
          <React.Fragment>
            <SubText>
              {l.max9CatsCanMintNow}
              {' '}
              {maxAvailable}
            </SubText>
            <GetMaxText
              style={{marginLeft: 8}}
              className="simple-btn"
              onClick={() => {
                setMintCountOption(maxAvailable);
              }}
            >
              {l.getMax}
            </GetMaxText>
          </React.Fragment>
          {/* {
            (() => {
              if (!query.code || !INVITATION_CODE_REGEX.exec(query.code)
            || (store.profile && store.profile.details.invitationCode === query.code)) {
                return (
                  <DescriptionText
                    style={{
                      textDecoration: 'underline',
                      marginTop: 6,
                    }}
                    onClick={() => {
                      store.isSkipInvitationCode = false;
                    }}
                    className="simple-btn"
                  >
                    {l.haveInviteCode}
                  </DescriptionText>
                );
              }
              return null;
            })()
          } */}
          {
            store.web3Ctx?.account ? (
              <IconMainButton
                style={{
                  marginTop: 16,
                }}
                text={`${(formatBN(price) * mintCountOption).toFixed(2)} ETH`}
                icon="/images/v2/eth.svg"
                iconStyle={{
                  width: '14px',
                  transform: 'translateX(-10px)',
                }}
                onClick={(evt) => {
                  onMint(evt);
                }}
              />
            ) : (
              <IconMainButton
                style={{
                  marginTop: 16,
                }}
                text="Connect"
                icon="/images/v2/metamask.svg"
                iconStyle={{
                  width: '14px',
                  transform: 'translateX(-10px)',
                }}
                onClick={(evt) => {
                  connect();
                }}
              />
            )
          }
          <WalletInfoComponent style={{marginTop: 32}} acc={acc} />
        </VFlex>
        {/* <InvitedComponent /> */}
      </VFlex>
    </React.Fragment>
  );
});
