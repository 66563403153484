import React, {CSSProperties} from 'react';
import {observer} from 'mobx-react';
import { VFlex } from './Flex';
import { S60Racing } from './about-us/typography';
import { MainButton } from './MainButton';
import { widthStore } from '../mobx/width-store';
import { ALink } from './Alink';
import i18nStore from '../localizations';
import { OS_LINK } from '../utils/constants';
import { OS, getMobileOperatingSystem } from '../utils/utils';

interface PlayNowSectionProps {
style: CSSProperties;
}

const PlayNowSection = observer((props:PlayNowSectionProps) => {
  return (
    <VFlex
      style={{
        width:
        '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{width: '100%', height: 'auto'}}
        alt=""
        src="/images/v4/playnow_bg.png"
      />
      <VFlex
        style={{
          position: widthStore.isPlayNowDesktop && 'absolute',
          gap: 24,
          transform: widthStore.isPlayNowDesktop && 'translate(80%, -50%)',
          padding: '36px',
          alignItems: !widthStore.isPlayNowDesktop && 'center',
          maxWidth: 500,
          textAlign: !widthStore.isPlayNowDesktop && 'center',
        }}
      >
        <S60Racing>
          {i18nStore.dict.mainPageV4.playNowSectionTitle}
        </S60Racing>
        <ALink
          to={OS_LINK[OS]}
        >
          <MainButton>
            {i18nStore.dict.mainPageV4.playNowSectionDescription}
          </MainButton>
        </ALink>
      </VFlex>
    </VFlex>
  );
});

export default PlayNowSection;
