import React, {Component} from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import { Header } from '../Header';
import { Flex, ResponsiveFlex, VFlex } from '../Flex';
import { Body, BodyHeader, Header1, Header2, SubTitle, UnderlineText } from '../Typography';
import i18nStore from '../../localizations';
import { BorderButton, MainButton } from '../MainButton';
import FooterV3 from '../v3/FooterV3';
import { LogoButton } from '../mediakit/LogoButton';
import MKRow from '../mediakit/MKRow';
import WallpaperDimensionLink from '../mediakit/WallpaperDimensionLink';
import InAppStickersComponent from '../mediakit/InAppStickersComponent';
import InAppStickerPlatformsComponent from '../mediakit/InAppStickerPlatformsComponent';
import { store } from '../../mobx/store';

const WallpaperText = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 28px;
/* or 200% */
text-align: center;
letter-spacing: 0.02em;

/* grey#2 */

color: #ABABAB;
`;

const logoBtnConfigs = [
  {
    textKey: 'logos',
    img: '/images/v3/icon-logo.svg',
    downloadURL: 'https://drive.google.com/drive/folders/1qlmx316-Lj_O08g_t5NaoVFv2i-3cPVC?usp=sharing',
  },
  {
    textKey: 'graphicAssets',
    img: '/images/v3/graphic-assets-icon.svg',
    downloadURL: 'https://drive.google.com/drive/folders/1G88nwy9QqbcBZddilkD3h6QeFSWlaRL0?usp=sharing',
  },
  {
    textKey: 'videos',
    img: '/images/v3/icon-video.svg',
    href: 'https://drive.google.com/drive/folders/1Vl8-DynlV7eZOkV_GG93sKCo5josKiFN?usp=sharing',
    downloadURL: 'https://drive.google.com/drive/folders/1Vl8-DynlV7eZOkV_GG93sKCo5josKiFN?usp=sharing',
  },
  {
    textKey: 'wallpaper',
    img: '/images/v3/icon-wallpaper.svg',
    downloadURL: '#wallpaper',
  },
  {
    textKey: 'inAppStickers',
    downloadURL: 'https://linktr.ee/9catsticker',
    textExtraComponent: (
      <img
        style={{width: 20, height: 'auto', marginLeft: 6}}
        src="/images/v3/ri_external-link-line.png"
        alt=""
      />
    ),
    extraComponent: (<InAppStickerPlatformsComponent style={{marginTop: 8}} />),
    img: '/images/v3/icon-sticker.svg',
  },
];

interface MediaKitPageProps {
}

const MediaKitPage = observer((props:MediaKitPageProps) => {
  const l = i18nStore.dict.mediaKitPage;
  return (
    <VFlex
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      <Header />
      <VFlex
        style={{
          position: 'relative',
          alignItems: 'center',
          marginTop: 120,
          alignSelf: 'center',
        }}
        className="full-width"
      >
        <Header1 hasShadow style={{maxWidth: 750}}>
          {l.title}
        </Header1>
        <Body
          style={{marginTop: 40, textAlign: 'center', maxWidth: 700}}
        >
          {l.description}
        </Body>
        <Flex
          style={{
            justifyContent: 'center',
            marginTop: 42,
            alignItems: 'flex-start',
            width: '90vw',
            maxWidth: 800,
            flexWrap: 'wrap',
          }}
        >
          {
            logoBtnConfigs.map((c) => {
              return (
                <LogoButton {...c} />
              );
            })
          }
        </Flex>
        <VFlex style={{gap: 100}}>
          <MKRow
            titleKey="logos"
            descriptionKey="logosDescription"
            size="500"
            imgComponent={(
              <React.Fragment>
                <img style={{height: '150px', width: 'auto'}} src="/images/portal/9CATSaga_1300x900.png" alt="" />
                <img style={{height: '150px', width: 'auto'}} src="/images/v3/logo_9cat_stroke.png" alt="" />
              </React.Fragment>
            )}
            href="https://drive.google.com/drive/folders/1qlmx316-Lj_O08g_t5NaoVFv2i-3cPVC?usp=sharing"
          />
          <MKRow
            titleKey="graphicAssets"
            descriptionKey="graphicAssetsDescription"
            size="500"
            imgComponent={(
              <React.Fragment>
                <VFlex style={{gap: 32, alignItems: 'center'}}>
                  <img style={{height: '150px', width: 'auto'}} src="/images/v3/graphicassets-01-stickers.png" alt="" />
                  <a
                    href="https://drive.google.com/drive/folders/1FQnL5FcQQH2-iqGWR1ATWbt7QkM4z8cZ?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className="simple-btn"
                  >
                    <UnderlineText>
                      {`${l.allStickersTitle}`}
                    </UnderlineText>
                  </a>
                </VFlex>
                <VFlex style={{gap: 32, alignItems: 'center'}}>
                  <img style={{height: '150px', width: 'auto'}} src="/images/v3/graphicassets-03-banners.png" alt="" />
                  <a
                    href="https://drive.google.com/drive/folders/1VWJ4BuXZgx7HqCKNsAaFxHfpU2Tqd2SS?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className="simple-btn"
                  >
                    <UnderlineText>
                      {`${l.allBannersTitle}`}
                    </UnderlineText>
                  </a>
                </VFlex>
              </React.Fragment>
            )}
            href="https://drive.google.com/drive/folders/1G88nwy9QqbcBZddilkD3h6QeFSWlaRL0?usp=sharing"
          />
          <MKRow
            titleKey="videos"
            descriptionKey="videosDescription"
            size="500"
            imgComponent={(
              <img
                style={store.isDesktop ? {height: 300, width: 'auto'} : {height: 'auto', width: '90vw'}}
                src="/images/v3/mk-videos.png"
                alt=""
              />
            )}
            href="https://drive.google.com/drive/folders/1Vl8-DynlV7eZOkV_GG93sKCo5josKiFN?usp=sharing"
          />
          <MKRow
            id="wallpaper"
            titleKey="wallpaper"
            descriptionKey="wallpaperDescription"
            bottomComponent={(
              <ResponsiveFlex style={{gap: 24}}>
                {
                  [
                    {
                      portraits: ['https://drive.google.com/file/d/1U6J8GgBnbCwN2WO1fPX9rqLsbTEG4Kyq/view?usp=share_link', 'https://drive.google.com/file/d/18aqBxAbDg14uMZkN16fkuzzz-ThVOnv0/view?usp=share_link', 'https://drive.google.com/file/d/1_KKprTJt07Y_eGazwkvU9AtfgaLnn6Il/view?usp=share_link'],
                      landscapes: ['https://drive.google.com/file/d/1jnjeh7yBsAJzmHHZuqX41JEjUMQLUEq2/view?usp=share_link', 'https://drive.google.com/file/d/1WPlJHHkK2oJ0-WiTy4daND-QXce4V2dR/view?usp=share_link'],
                    },
                    {
                      portraits: ['https://drive.google.com/file/d/1J6DqfWqK4fQkn69cvseJPSpl_-_hodzi/view?usp=share_link', 'https://drive.google.com/file/d/1DLXoDpmBan6WiDQPTSQcURg0LVa0DX6V/view?usp=share_link', 'https://drive.google.com/file/d/1_CrhGzQUm3z6cpas2OS9X_z0_jfbugW7/view?usp=share_link'],
                      landscapes: ['https://drive.google.com/file/d/1hvD9BwZlRbdSOsxepDoo5H2gjqiHlWJE/view?usp=share_link', 'https://drive.google.com/file/d/1_G3uvsjEtOicwD7nBmx4lCMZxsh0AbXu/view?usp=share_link'],
                    },
                  ].map(({portraits, landscapes}, idx) => {
                    return (
                      <VFlex style={{flex: 1, backgroundColor: '#2E3435', padding: '50px 0px', alignItems: 'center'}}>
                        <img style={{maxWidth: '358px'}} src={`/images/v3/wallpaper-0${idx + 1}.png`} alt={`wallpaper ${idx + 1}`} />
                        <Flex
                          style={{
                            width: '90%',
                            justifyContent: 'space-evenly',
                            maxWidth: '350px',
                            alignItems: 'flex-start',
                            marginTop: 16,
                          }}
                        >
                          <VFlex>
                            <WallpaperText>{l.portrait}</WallpaperText>
                            <VFlex>
                              {
                                ['1080x1920', '1440x2160', '1028x1028'].map((d, dIdx) => (
                                  <WallpaperDimensionLink href={portraits[dIdx]} dimension={d} />
                                ))
                              }
                            </VFlex>
                          </VFlex>
                          <div style={{alignSelf: 'center', height: 75, width: 1, backgroundColor: '#7A7A7A'}} />
                          <VFlex>
                            <WallpaperText>{l.landscape}</WallpaperText>
                            <VFlex style={{gap: 6}}>
                              {
                                ['1920x1089', '1200x630'].map((d, dIdx) => (
                                  <WallpaperDimensionLink href={landscapes[dIdx]} dimension={d} />
                                ))
                              }
                            </VFlex>
                          </VFlex>
                        </Flex>
                      </VFlex>
                    );
                  })
                }
              </ResponsiveFlex>
            )}
          />
        </VFlex>
      </VFlex>
      <InAppStickersComponent />
      <FooterV3 />
    </VFlex>
  );
});

export default MediaKitPage;
