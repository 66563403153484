import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import styled from 'styled-components';
import i18nStore from '../localizations';
import { store } from '../mobx/store';
import { COLORS, OS_LINK } from '../utils/constants';
import { getMobileOperatingSystem, useIsDesktop } from '../utils/utils';
import DropdownArrowImage from './DropdownArrowImage';
import { Flex, VFlex } from './Flex';
import { MainButton } from './MainButton';
import { ALink } from './Alink';

type Props = {
  style: any,
}

const HamburgerLine = styled.div`
  width: 100%;
  border-radius: 3px;
  height: 2px;
`;

const StyledVFlex = styled(VFlex)`
  height: 16px;
  width: 21px;
  justify-content: space-between;
  transform: scale(0.8);
`;

const HamburgerBtn = () => {
  return (
    <StyledVFlex
      className="simple-btn"
      onClick={() => {
        store.isShowOverlay = true;
      }}
    >
      <HamburgerLine style={{ backgroundColor: '#F5F5F5' }} />
      <HamburgerLine style={{ backgroundColor: '#F5F5F5' }} />
      <HamburgerLine style={{ backgroundColor: '#F5F5F5' }} />
    </StyledVFlex>
  );
};

const TextButtonWrapper = styled.div`
align-items: center;
position: relative;
display: flex;
flex-direction: column;

a:hover {
  color: #ABABAB;
  opacity: 0.7;
}
a:active {
  color: #ABABAB;
  opacity: 0.4;
}
`;

const ATextButton = styled(ALink)`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
display: flex;
letter-spacing: 2px;
color: #F5F5F5;
text-align: center;
transition: opacity 0.3s;
@media only screen and (max-width: 800px) {
  font-size: 10px;
}
align-items: center;
`;

const TextButton = styled.div`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
display: flex;
letter-spacing: 2px;
color: #F5F5F5;
text-align: center;
transition: all 0.3s;
@media only screen and (max-width: 800px) {
  font-size: 10px;
}
align-items: center;
`;


export const headers = [
  {
    name: 'home',
    href: '/',
  },
  {
    name: 'team',
    href: '/team',
  },
  {
    name: 'partners',
    href: '/partners',
  },
  {
    name: 'mediaKit',
    href: '/media-kit',
  },
  {
    name: 'merch',
    href: 'https://merch.9cat.io/',
    target: '_blank',
  },
];


const Dot = styled.div`
position: absolute;
top: 24px;
width: 5px;
height: 5px;
border-radius: 12px;
background-color: #F5F5F5;
opacity: 0;
transition: all 0.3s;
&.show {
  opacity: 0.6;  
}
`;

const HeaderContainer = styled(Flex)`
position: fixed;
width: 100%;
justify-content: space-between;
z-index: 99;
transition: all 0.3s;
`;

const DropdownBtn = styled.a`
  padding: 4px 16px;
  color: white;
  &:hover {
    color: #d585ff;
    background-color: #1a1e1f;
  }
  cursor: pointer;
`;

export const Header = observer((props: Props) => {
  const isDesktop = store.isHeaderDesktop;
  const [hoveringIdx, setHoveringIdx] = useState(-1);
  const [dropdownStates, setDropdownStates] = useState([]);
  const history = useHistory();
  const onLinkClick = (evt, obj) => {
  };
  return (
    <HeaderContainer
      style={{
        height: store.headerHeight,
        padding: isDesktop ? '0px 60px' : '0px 21.33px',
        backgroundColor: store.scrollYOffset > 10 ? COLORS.grey2 : 'transparent',
        ...props.style,
      }}
    >
      <Link
        to="/"
      >
        <img
          src="/images/Logo_300x100.png"
          alt=""
          style={{ width: 129, height: 'auto' }}
          className="simple-btn"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      </Link>
      {
        isDesktop ? (
          <Flex
            style={{
              flex: 1,
              justifyContent: 'space-between',
              maxWidth: '560px',
            }}
          >
            {
              headers.map((h, idx) => {
                const BtnComponent = h.href ? ATextButton : TextButton;
                const isShowingDropdown = dropdownStates[idx];
                return (
                  <TextButtonWrapper
                    onMouseOver={() => {
                      setHoveringIdx(idx);
                    }}
                    onMouseOut={() => {
                      setHoveringIdx(-1);
                    }}
                    onClick={() => {
                      if (!h.dropdown) return;
                      const ns = dropdownStates.slice(0);
                      ns[idx] = !ns[idx];
                      setDropdownStates(ns);
                    }}
                  >
                    <BtnComponent
                      onClick={evt => onLinkClick(evt, h)}
                      to={h.href}
                      className="simple-btn"
                    >
                      {i18nStore.dict.headers[h.name]}
                      {
                        h.dropdown && (
                          <ATextButton
                            style={{
                              marginLeft: 6,
                              borderRadius: 50,
                              backgroundColor: isShowingDropdown && COLORS.grey1,
                              width: 25,
                              height: 25,
                              justifyContent: 'center',
                              alignItems: 'center',
                              transform: 'translate(0px, -2px)',
                            }}
                          >
                            <DropdownArrowImage
                              isShowingDropdown={isShowingDropdown}
                            />
                          </ATextButton>
                        )
                      }
                    </BtnComponent>
                    {
                      !h.dropdown && (
                        <Dot className={hoveringIdx === idx ? 'show' : ''} />
                      )
                    }
                    {
                      h.dropdown && (
                        <VFlex
                          style={{
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                            width: 120,
                            backgroundColor: '#2e3435',
                            position: 'absolute',
                            top: '130%',
                            borderRadius: 8,
                            opacity: isShowingDropdown ? 1 : 0,
                            pointerEvents: !isShowingDropdown && 'none',
                            overflow: 'hidden',
                          }}
                        >
                          {
                            h.dropdown.map((d) => {
                              return (
                                <DropdownBtn
                                  onClick={evt => onLinkClick(evt, d)}
                                  href={d.href}
                                  target={d.target}
                                >
                                  {i18nStore.dict.headers[d.name]}
                                </DropdownBtn>
                              );
                            })
                          }
                        </VFlex>
                      )
                    }
                  </TextButtonWrapper>
                );
              })
            }
          </Flex>
        ) : null
      }
      {
        isDesktop ? (
          <Flex
            style={{
              gap: 25,
              alignItems: 'center',
            }}
          >
            {/* <TextButton>
              LOGIN
            </TextButton> */}
            <ALink
              to={OS_LINK[getMobileOperatingSystem()]}
            >
              <MainButton style={{ width: 141 }}>
                PLAY NOW
              </MainButton>
            </ALink>
            {/* <OpenSeaButton size={24} />
            <DiscordButton size={24} />
            <TGButton size={24} />
            <TwitterButton size={24} />
            <MediumButton size={24} />
            <IGButton size={24} /> */}
          </Flex>
        ) : (
          <HamburgerBtn />
        )
      }
    </HeaderContainer>
  );
});
