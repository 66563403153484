import { observer } from 'mobx-react-lite';
import React from 'react';
import { useConnect } from '../hooks/hooks';
import { store } from '../mobx/store';
import { NETWORK_CONFIG } from '../utils/constants';
import { Flex } from './Flex';
import { AlertText, WhitelistText } from './Typography';

export const IncorrectNetworkComponent = observer(() => {
  const connect = useConnect(true);
  if (!store.isIncorrectNetwork) {
    return null;
  }
  return (
    <React.Fragment>
      <Flex
        style={{marginTop: 12}}
      >
        <img
          src="/images/v2/alert.svg"
          alt=""
          style={{
            marginRight: 8,
          }}
        />
        <AlertText>
          Incorrect chain, please switch to
          {' '}
          <span
            style={{textDecoration: 'underline'}}
            className="simple-btn"
            onClick={() => {
              connect();
            }}
          >
            {NETWORK_CONFIG.chainName}
          </span>
        </AlertText>
      </Flex>
    </React.Fragment>
  );
});
