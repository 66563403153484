import { observer } from 'mobx-react-lite';
import React from 'react';
import { store } from '../../mobx/store';

type Props = {

}

export const HeroImageDesktop = observer((props:Props) => {
  return (
    <React.Fragment>
      {/* <video
        src="/videos/header.mp4"
        style={{
          height: 811,
          width: '100%',
          objectFit: 'cover',
          userSelect: 'none',
        }}
        autoPlay
        loop
        muted
        playsInline
      /> */}
      <iframe
        style={{
          height: 811,
          width: '100%',
          objectFit: 'cover',
          userSelect: 'none',
        }}
        src="https://www.youtube.com/embed/npZq5lpxxA0?si=VXa-kTZPcuecw3Ns&start=256&loop=1&iv_load_policy=3&playlist=npZq5lpxxA0&autoplay=1&mute=1&controls=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </React.Fragment>
  );
});
