import React, {CSSProperties, VideoHTMLAttributes} from 'react';
import {observer} from 'mobx-react';

interface VideoProps extends VideoHTMLAttributes {
}

const Video = observer((props:VideoProps) => {
  const {style, ...p} = props;
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      {...p}
    />
  );
});

export default Video;
