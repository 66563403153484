import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { isDesktop } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Container } from './Container';
import { Flex, ResponsiveFlex } from './Flex';
import { headers } from './Header';
import { DiscordButton, IGButton, MediumButton, NFTCalendarButton, OpenSeaButton, ThreadsButton, TwitterButton, YoutubeButton } from './SocialMediaButton';
import { store } from '../mobx/store';
import { COLORS, NETWORK_CONFIG } from '../utils/constants';

interface FooterV2Props {
}

const defaultState = {
};

type _State = typeof defaultState;
interface State extends _State {
}

export const FooterTextButton = styled.a`
font-family: Mulish;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
letter-spacing: 0.2px;

/* primary */

color: #ABABAB;
transition: opacity 0.3s;
&:hover {
  color: #ABABAB;
  opacity: 0.7;
}
&:active {
  color: #ABABAB;
  opacity: 0.4;
}
`;

const CopyrightText = styled.span`
font-family: Mulish;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.2px;

/* Light / Gray */

color: #464848;

`;

const FooterV2 = observer((props: FooterV2Props) => {
  return (
    <Container
      style={{
        backgroundColor: COLORS.darkBlack,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '276px',
        paddingTop: store.isDesktop ? undefined : 30,
      }}
    >
      <ResponsiveFlex>
        {
          headers.map((h) => {
            return (
              <FooterTextButton
                style={{
                  margin: store.isDesktop ? 22 : 7,
                }}
                href={h.href}
                target={h.target}
              >
                {h.name}
              </FooterTextButton>
            );
          })
        }
      </ResponsiveFlex>
      <FooterTextButton
        style={{
          margin: store.isDesktop ? 22 : 7,
        }}
        href={NETWORK_CONFIG.ethscan}
        target="_blank"
      >
        9Cat Verified Contract Address:
        {' '}
        {NETWORK_CONFIG.ncAddress}
      </FooterTextButton>
      <FooterTextButton
        style={{
        }}
        href={`${NETWORK_CONFIG.ethscanRoot}/address/${NETWORK_CONFIG.nMilkAddress}`}
        target="_blank"
      >
        9Milk Verified Contract Address:
        {' '}
        {NETWORK_CONFIG.nMilkAddress}
      </FooterTextButton>
      <img
        src="/images/Logo_300x100.png"
        alt=""
        style={{ width: 196, height: 'auto', marginTop: isDesktop ? 26 : 4 }}
      />
      <Flex
        style={{
          marginTop: 33,
        }}
      >
        <YoutubeButton size={24} />
        <ThreadsButton size={24} />
        <DiscordButton size={24} />
        <div style={{ width: 25 }} />
        <TwitterButton size={24} />
        <div style={{ width: 25 }} />
        <MediumButton size={24} />
        <div style={{ width: 25 }} />
        <IGButton size={24} />
        <div style={{ width: 25 }} />
        <OpenSeaButton
          size={25}
        />
        <div style={{ width: 25 }} />
        <NFTCalendarButton size={24} />
      </Flex>
      <Link
        to="/privacy"
      >
        <CopyrightText
          style={{ marginTop: 25 }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'auto' });
          }}
        >
          Privacy Policy
        </CopyrightText>
      </Link>
      <CopyrightText
        style={{ marginTop: 15 }}
      >
        © Daytech Limited. All rights reserved.
      </CopyrightText>
      <div style={{ height: 100 }} />
    </Container>
  );
});

export default FooterV2;
