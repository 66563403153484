import styled, { keyframes } from 'styled-components';
import {Spinner as RBSpinner} from 'react-bootstrap';

import { VFlex } from './Flex';


export const SpinnerContainer = styled(VFlex)`
margin-top: 45px;
margin-bottom: 20px;
transform: scale(1.5);
`;

type SpinnerProps = {
  borderWidth: any,
  borderColor: any,
}

export const Spinner = (props:SpinnerProps) => {
  const {style, borderWidth, borderColor, ...p} = props;
  return (
    <RBSpinner
      style={{
        ...style,
        borderLeftColor: borderColor,
        borderTopColor: borderColor,
        borderBottomColor: borderColor,
        borderTopWidth: borderWidth,
        borderLeftWidth: borderWidth,
        borderBottomWidth: borderWidth,
        borderRightWith: borderWidth,
      }}
      animation="border"
      variant="light"
      size="sm"
      {...p}
    />
  );
};
