import React, {Component, StyleHTMLAttributes, useState, useMemo } from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import { Flex, VFlex } from '../Flex';


const Container = styled(Flex)`
  border: 1px solid #2E3435;
  height: 52px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Text = styled.div`

font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;
align-self: center;
/* Grey#3 */

color: #7A7A7A;
`;

interface DropdownProps {
  containerStyle: StyleHTMLAttributes,
  options: [
    {value: String, label: String}
  ],
  value: String,
  onSelected: (selection:{value: String, label: String}) => void,
  extraComponents: any;
}

const Dropdown = observer((props:DropdownProps) => {
  const {value, containerStyle, options, extraComponents, onSelected} = props;
  const [isOpen, setIsOpen] = useState();
  const label = useMemo(() => {
    return options.find(o => o.value === value).label;
  }, [value, options]);
  return (
    <div
      style={{position: 'relative'}}
    >
      <Container
        style={containerStyle}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="simple-btn"
      >
        <Text>
          {label}
        </Text>
        <img
          src="/images/v3/right-arrow-white.png"
          alt=""
          style={{
            position: 'absolute',
            right: 16,
            width: 12,
            height: 'auto',
            transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
          }}
        />
        {extraComponents}
      </Container>
      {
        isOpen && (
          <VFlex
            style={{
              position: 'absolute',
              alignSelf: 'stretch',
              width: '100%',
              alignItems: 'stretch',
              zIndex: 90,
            }}
          >
            {
              options.map((opt) => {
                return (
                  <Container
                    className="dropdown-btn"
                    style={{...containerStyle}}
                    onClick={() => {
                      onSelected && onSelected(opt);
                      setIsOpen(!isOpen);
                      localStorage.setItem('language', opt.value);
                    }}
                  >
                    <Text>
                      {opt.label}
                    </Text>
                  </Container>
                );
              })
            }
          </VFlex>
        )
      }
    </div>
  );
});

export default Dropdown;
