import { useConnect } from '../hooks/hooks';
import { Flex } from './Flex';
import { IconMainButton, MainButton } from './MainButton';

export const ConnectWalletBtn = (p) => {
  const connect = useConnect(true);
  return (
    // <MainButton
    //   onClick={() => {
    //     connect();
    //   }}
    // >
    //   <Flex
    //     style={{alignItems: 'center', marginRight: 6}}
    //   >
    //     <img
    //       src="/images/v2/metamask.svg"
    //       alt=""
    //       style={{
    //         width: 24,
    //         height: 'auto',
    //         marginRight: 20,
    //       }}
    //     />
    //     <span
    //       style={{transform: 'translateY(1px)'}}
    //     >
    //       CONNECT WALLET
    //     </span>
    //   </Flex>
    // </MainButton>
    <IconMainButton
      onClick={() => {
        connect();
      }}
      icon="/images/v2/metamask.svg"
      text="CONNECT WALLET"
      {...p}
    />
  );
};
