import { observer } from 'mobx-react-lite';
import React, {CSSProperties} from 'react';
import i18nStore from '../localizations';
import { store } from '../mobx/store';
import { TimeSubText } from './banner/banner-common';
import { ResponsiveFlex, VFlex } from './Flex';
import { getAddressString } from '../utils/utils';

export const WalletInfoComponent = observer(({style}:{style:CSSProperties}) => {
  if (!store.web3Ctx?.account) return null;
  const l = i18nStore.dict.mintPage;
  return (
    <VFlex style={{gap: 12, ...style}}>
      <TimeSubText style={{}}>
        {l.connectedWallet}
        {' '}
        {getAddressString(store.web3Ctx?.account)}
      </TimeSubText>
      <TimeSubText
        className="simple-btn"
        style={{
          textDecoration: 'underline',
          fontWeight: '600',
        }}
        onClick={() => {
          store.logout();
        }}
      >
        {l.notThisWallet}
      </TimeSubText>
    </VFlex>
  );
});
