/* eslint-disable jsx-a11y/anchor-has-content */
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PrivateSLink = styled(Link)`
text-decoration: none !important;
text-decoration-line: none !important;
`;

export const ALink = ({onClick: _onClick, to: _to, pure, ...props}) => {
  const [to, hash] = _to?.split('#');
  const onClick = (evt) => {
    _onClick && _onClick();
    if (to && hash) {
      setTimeout(() => {
        location.hash = hash;
      }, 100);
      // setTimeout(() => {
      //   console.log('hmm');
      //   location.hash = to.split('#')[1];
      // }, 500);
    }
    if (to === '/' && !hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  if (pure || (typeof to === 'string' && to?.startsWith('http'))) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={to}
        onClick={onClick}
        {...props}
      />
    );
  }
  return (
    <PrivateSLink
      to={to}
      onClick={onClick}
      {...props}
    />
  );
};
