import { ethers, FixedNumber } from 'ethers';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatEther } from '@ethersproject/units';
import { Web3Provider } from '@ethersproject/providers';
import useResizeObserver from '@react-hook/resize-observer';
import { NETWORK_CONFIG, NETWORK_CONFIGS, PHASE, WIDTH_THRESHOLD } from './constants';
import { TSize } from '../types';

export const formatBN = bigNumber => parseFloat(formatEther(bigNumber));

export const getPlaceHolderURL = (size) => {
  return `https://via.placeholder.com/${size}`;
};

export const getAddressString = (addr: String) => {
  if (typeof addr !== 'string') return '';
  return `${addr.substring(0, 5)}...${addr.substring(addr.length - 4, addr.length)}`;
};

export const networkSetup = (id = NETWORK_CONFIG.id) => {
  return new Promise((resolve, reject) => {
    const provider = window.ethereum;
    if (provider) {
      // if (networkSettings.hasOwnProperty(chainId)) {
      provider
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: NETWORK_CONFIG.id}],
        })
        .then(resolve)
        .catch(async (error) => {
          if (error.message.includes('wallet_addEthereumChain')) {
            try {
              await provider
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [NETWORK_CONFIGS[parseInt(id, 16)].chainData],
                });
              this.networkSetup(id);
            } catch (error2) {
              reject(error2);
            }
            return;
          }
          reject(error);
        });
      // } else {
      //   reject(new Error(`No network settings configured for chainId: '${chainId}'`));
      // }
    } else {
      reject(new Error(`window.ethereum is '${typeof provider}'`));
    }
  });
};

export const useSize = (target): TSize => {
  const [size, setSize] = React.useState();
  React.useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target, target?.current]);

  // Where the magic happens
  useResizeObserver(target, entry => setSize(entry.contentRect));
  return size;
};

export const useIsDesktop = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${WIDTH_THRESHOLD}px)`,
  });
  return isDesktopOrLaptop;
};

export function numberInGroups(n) {
  return n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 0;
}

export const getDisplayPriceString = (price, commas = true) => {
  if (!price) return '0';
  const n = FixedNumber.fromValue(price, 18, 'fixed').toString();
  if (!commas) return n;
  const [dec, point] = n.split('.');
  if (point === '0') return numberInGroups(dec);
  return [numberInGroups(dec), point.substr(0, 5)].join('.');
};

export const getTimeLeftObj = (timeLeftMS) => {
  if (timeLeftMS < 0) return null;
  const seconds = timeLeftMS / 1000;
  const units = ['y', 'd', 'h', 'm'];
  const values = [
    Math.floor(seconds / 31536000),
    Math.floor((seconds % 31536000) / 86400),
    Math.floor(((seconds % 31536000) % 86400) / 3600),
    Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
    Math.floor((((seconds % 31536000) % 86400) % 3600) % 60),
  ];
  return {
    y: Math.floor(seconds / 31536000),
    d: Math.floor((seconds % 31536000) / 86400),
    h: Math.floor(((seconds % 31536000) % 86400) / 3600),
    m: Math.floor((((seconds % 31536000) % 86400) % 3600) / 60),
    s: Math.floor((((seconds % 31536000) % 86400) % 3600) % 60),
  };
};

export const getPhase = (ts) => {
  if (ts < NETWORK_CONFIG.PRESALE_TS) {
    return PHASE.BEFORE_ANYTHING;
  }
  if (ts < NETWORK_CONFIG.PUBLIC_SALE_TS) {
    return PHASE.PRESALE;
  }
  return PHASE.PUBLIC;
};

export const getIsSelling = (phase) => {
  return phase === PHASE.PRESALE || phase === PHASE.PUBLIC;
};

export const getIsBeforeInter = (phase) => {
  return phase === PHASE.PRESALE || phase === PHASE.BEFORE_ANYTHING;
};


export function hexEncode(s: string) {
  let hex;
  let i;

  let result = '';
  for (i = 0; i < s.length; i++) {
    hex = s.charCodeAt(i).toString(16);
    if (hex.length === 1) {
      result += `0${hex}`;
    } else {
      result += `${hex}`;
    }
  }
  return result;
}

export function copyToClipboard(string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

export function parseQuery(queryString) {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const signMessage = async (library: Web3Provider, message: string, address:string) => {
  // const t = await library.getSigner(address)
  //   .signMessage(msg);
  // // console.log(t);

  let t = '';
  if (library.provider.wc) {
    const data = [];
    for (let i = 0; i < message.length; i++) {
      data.push(message.charCodeAt(i));
    }
    const encoded = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
    const params = [encoded, address.toLowerCase()];
    try {
      t = await library.provider.connector.signPersonalMessage(params);
    } catch (error) {
      console.log(error);
      throw error;
    }
  } else {
    try {
      t = await library.getSigner(address)
        .signMessage(message);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  return t;
};

export const getLoopNumbers = (from, to) => {
  const arr = [];
  for (let i = from; i <= to; i++) {
    arr.push(i);
  }
  return arr;
};

export const processNumber = (d) => {
  return `${d}`.padStart(2, '0');
};

export const useScrollbarWidth = () => {
  const didCompute = React.useRef(false);
  const widthRef = React.useRef(0);

  if (didCompute.current) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export function getMobileOperatingSystem(): 'android' | 'ios' | 'desktop' {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;


  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'desktop';
}

export const OS = getMobileOperatingSystem();
